import React from 'react';
import heroImage from '../assets/images/booking/booking-hero.jpg';
import BookingForm from '../component/Forms/MultiStepForm/BookingForm';
import PageWrapperImageHead from '../component/UI/Wrappers/PageWrapper/PageWrapperImageHead';
const BookingPage = () => {
  return (
    <PageWrapperImageHead photo_brand={heroImage} pageTitle='Wynajem kamperów'>
      <BookingForm></BookingForm>
    </PageWrapperImageHead>
  );
};

export default BookingPage;
