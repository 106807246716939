export const faqsList = [
  {
    id: '0',
    label: 'Kamper - Uprawnienia',
    icon: 'mdi:drivers-license-outline',
    list: [
      {
        id: 1,
        typeAccordion: 'description',
        header: 'Czy do wynajmu kampera potrzebne są jakieś uprawnienia?',
        text: 'Nasze kampery posiadają masę do 3,5 tony. Oznacza to, że do ich prowadzenia wystarczające jest prawo jazdy kategorii B - tak jak w przypadku aut osobowych.',
      },

      {
        id: 23,
        typeAccordion: 'description',
        header: 'Czy każdy, kto posiada prawo jazdy może wypożyczyć kamper?',
        text: 'Ze względu na specyfikę kampera jako pojazdu (wysokość powyżej 3 m; długość powyżej 7 m; zabudowa), prowadzi się go inaczej, niż samochód osobowy.\nZ tego powodu przyjęliśmy minimalny wiek kierowcy - 26 lat i minimum 3 lata posiadania prawa jazdy kat. B.\nJeśli się martwisz o jego gabaryty zrobimy Ci wprowadzenie z jazdy i manewrów.',
      },
    ],
  },
  {
    id: '1',
    label: 'Rezerwacje i opłaty',
    icon: 'mdi:payment-clock',
    list: [
      {
        id: 0,
        typeAccordion: 'list',
        header: 'Jak można zarezerwować kampera?',
        list: [
          {
            listItem:
              'Wypełniasz i wysyłasz formularz, piszesz e-mai lub dzwonisz w celu sprawdzenia dostępności wybranego kampera',
          },
          {
            listItem:
              'Niezwłocznie (do 24 godz.) potwierdzamy dostępność w wybranym przez Ciebie terminie – mailem lub telefonicznie. Jeśli auta będą niedostępne – zaproponujemy Ci inny termin z przyjemnym dla portfela rabatem',
          },
          {
            listItem:
              'Przesyłasz nam swoje dane oraz numer prawa jazdy i dowodu osobistego - od tego momentu liczy się Twoja rezerwacja wstępna, a my blokujemy termin na wybrany przez Ciebie kamper.',
          },
          {
            listItem:
              'Odsyłamy do Ciebie umowę do podpisania z Twoimi danymi oraz fakturę zaliczkową na 30% wartości wynajmu.',
          },
          {
            listItem:
              'Na wykonanie przelewu za opłatę rezerwacyjną masz na 3 dni robocze. Po tym terminie Twoja rezerwacja wstępna wygasa. Prześlij nam również skan podpisanej umowy.',
          },
        ],
      },
      {
        id: 1,
        typeAccordion: 'descriptionWithList',
        header:
          'Czy po wpłacie opłaty rezerwacyjnej można wycofać się z wynajmu?',
        text: 'Rozumiemy, że życie bywa nieprzewidywalne, dlatego dopuszczamy taką możliwość. Niemniej, ponieważ rezerwacja powoduje, że wynajem wybranego kampera w ustalonym terminie jest niemożliwy dla innych klientów, to wiąże się to z pewnymi konsekwencjami. W związku z tym, opłata zależna jest od czasu rezygnacji i wynosi:',
        list: [
          {
            listItem:
              'do 60 dni przed ustalonym terminem rozpoczęcia wynajmu - bez opłat, ',
          },
          {
            listItem:
              'między 60 a 30 dniem przed terminem rozpoczęcia wynajmu - 25% wartości umowy,',
          },
          {
            listItem:
              'między 30 a 7 dniem przed terminem rozpoczęcia wynajmu - 50% wartości umowy,',
          },
          {
            listItem:
              'na mniej, niż 7 dni przed terminem rozpoczęcia wynajmu - 75% wartości umowy. ',
          },
        ],
      },
      {
        id: 2,
        typeAccordion: 'descriptionWithList',
        header: 'Jaki jest minimalny okres wynajmu kampera?',
        text: 'Minimalny okres wynajmu zależy od sezonu i wynosi:',
        list: [
          {
            listItem: 'sezon niski: 3 dni',
          },
          {
            listItem: 'sezon średni: 5 dni',
          },
          {
            listItem: 'sezon wysoki: 5 dni',
          },
        ],
      },
      {
        id: 3,
        typeAccordion: 'descriptionWithList',
        header: 'Jakie są terminy płatności za wynajem kampera? ',
        text: 'Płatności dokonywane są wg poniższych terminów:',
        list: [
          {
            listItem:
              '30% wartości brutto umowy - do 3 dni roboczych po przesłaniu przez nas mailem faktury zaliczkowej',
          },
          {
            listItem:
              '70% wartości brutto umowy - do 30 dni przed terminem rozpoczęcia wynajmu',
          },
          {
            listItem:
              'kaucja w wysokości 5.000 zł - do 7 dni przed terminem rozpoczęcia wynajmu',
          },
        ],
      },
      {
        id: 4,
        typeAccordion: 'description',
        header: 'Czy płacę za dodatkowego kierowcę?',
        text: 'Tak, wynika to z warunków ubezpieczenia - do prowadzenia wynajętego pojazdu uprawniony jest bowiem kierowca, który musi być wymieniony w umowie najmu. Każdy kolejny jest płatny dodatkowo. Aktualna lista stawek dostępna jest ',
        link: 'https://ikifriki.com/static/media/pricing.08bf8a17a1638578047f.pdf',
      },
      {
        id: 5,
        typeAccordion: 'description',
        header: 'Jakie opłaty ponoszę podczas wynajmu auta?',
        text: 'Podczas podróży po Twojej stronie będzie zatankowanie pojazdu i uzupełnienie płynu AdBlue. Zwracając pojazd zadbaj o to, aby te media zostały uzupełnione do stanu jak przy wydaniu (czyli do pełna) oraz pamiętaj o opróżnieniu kasety na fekalia i zbiornika szarej wody. Warto pamiętać, że wszelkie mandaty wystawione na auto w okresie Twojego wynajmu należą do Ciebie. Dlatego staraj się przestrzegać wszystkich przepisów drogowych.',
      },
      {
        id: 6,
        typeAccordion: 'description',
        header: 'Kiedy następuje zwrot kaucji?',
        text: 'Kaucję zwracamy do 5 dni. Termin może się wydłużyć, jeśli Twoim pasażerem był słoń albo kangur, a auto wygląda jak po kręceniu Madagaskaru 6. Wtedy Twoją kaucją będziemy musieli podzielić się z autoryzowanym serwisem, a to nigdy nie jest tanie. W takiej sytuacji zwrot kaucji nastąpi po wycenie szkód dokonanej w serwisie, jednak nie później niż 14 dni po zdaniu kampera. Pamiętaj więc – bądź delikatny nie tylko dla ludzi i zwierząt. Traktuj kampera troskliwie, jak przyjaciela.',
      },
      {
        id: 7,
        typeAccordion: 'descriptionWithList',
        header: 'Czy można uzyskać rabat od ceny wynajmu?',
        text: 'Oczywiście! Udzielamy 10% rabatu przy dłuższym okresie wynajmu, w zależności od sezonu:',
        list: [
          {
            listItem: 'sezon niski - przy minimalnym wynajmie na 15 dni',
          },
          {
            listItem: 'sezon średni - przy minimalnym wynajmie na 15 dni',
          },
        ],
      },
    ],
  },
  {
    id: '2',
    label: 'Pakowanie i podróż',
    icon: 'fa6-solid:boxes-packing',
    list: [
      {
        id: 0,
        typeAccordion: 'description',
        header: 'Jak planować dystans podróży na wakacyjny wyjazd np. 14 dni?',
        text: 'Doświadczenia naszych klientów podpowiadają, iż w przypadku wakacyjnego wyjazdu na 2 tygodnie, można sobie założyć dystans średnio ok. 300km/dziennie. Przy takim założeniu możemy zaplanować wycieczkę do Chorwacji, Danii, Szwecji, Północnych Włoch lub Francji. Tak zaplanowana podróż zagwarantuje nam możliwość zobaczenia wielu zakątków Europy oraz wypoczynku. ',
      },
      {
        id: 1,
        typeAccordion: 'description',
        header:
          'Ile mogę spakować do kampera o dopuszczalnej masie do 3.5 tony?',
        text: 'Im mniej tym lepiej. Zawsze lepiej mieć miejsce na przywiezienie wina i serów z dalekich podróży, zamiast wozić do Włoch lub Francji pięć swetrów i 10 par butów. 😊 Sporo zależy od charakteru planowanej podróży. Jeśli chcemy aktywnie wypocząć - sporo przestrzeni poświęcimy na rowery, deski, narty, itd. W kamperze jest na tyle ciepło, że ubrania szybką schną - nie jest to kłopot, gdy Ci zmokną. W Europie dostępnych jest też sporo publicznych pralni. Kamper waży ok. 3100kg',
      },
      {
        id: 2,
        typeAccordion: 'description',
        header: 'Z jaką prędkością podróżuje się kamperem na autostradzie?',
        text: 'Nie ma co ukrywać - kamper to jeżdżący dom, więc szczerze przyznamy, że najbardziej dogodna prędkość, żeby można było swobodnie porozmawiać z pasażerami i delektować się widokami wynosi maksymalnie 100 km/h.',
      },
      {
        id: 3,
        typeAccordion: 'description',
        header: 'Jakie są opłaty za podróżowanie kamperem po autostradach?',
        text: 'Nasze kampery posiadają DMC 3,5 tony oraz nie mają kół bliźniaczych z tyłu, co powoduje, że opłaty autostradowe w Polsce są takie same jak dla „zwykłego” auta osobowego. W przypadku przepraw promowych, tuneli i mostów ceny dla kampera mogą być wyższe niż dla „osobówki”. Aby dokładnie sprawdzić opłaty w innych krajach europejskich, warto zapoznać się z informacjami dotyczącymi opłat na stronach PZMOT. Uwaga: na przykład winieta na Węgrzech dla kamperów o DMC do 5 ton jest inna niż dla aut osobowych.',
      },
      {
        id: 4,
        typeAccordion: 'description',
        header: 'Jakie jest zużycie paliwa kampera na autostradzie?',
        text: 'Podczas jazdy po równym terenie i rozsądnej jeździe z prędkością ok. 100-110 km/h, zużycie oleju napędowego oscylować będzie w okoliach 10-11 l/100km.',
      },
      {
        id: 5,
        typeAccordion: 'description',
        header: 'Czy urządzenia gazowe w kamperze są bezpieczne?',
        text: 'Dbamy priorytetowo o to, aby nasze pojazdy były w 100% bezpieczne dla użytkowników. Dlatego też każdy nasz kamper przechodzi nieobowiązkowy w Polsce, okresowy przegląd instalacji gazowej zgodnie z normą G607 (obowiązkowy w Niemczech). Podczas takiego przeglądu jest sprawdzany stan urządzeń gazowych w kamperze, ich funkcjonalność, zabezpieczenia oraz terminy przydatności do użycia. Całość przeglądu jest zwieńczona testem szczelności układu gazowego i naklejonym certyfikatem umieszczonym w widocznym miejscu, który gwarantuje bezproblemowy wjazd na promy i do kempingów w Europie.',
      },
    ],
  },
];

const faqsRecent = [
  faqsList[0].list[0],
  faqsList[1].list[2],
  faqsList[2].list[3],
  faqsList[1].list[1],
];

export const faqRecentList = faqsRecent.map((item, idx) => ({
  ...item,
  id: idx, // Changing the ID by adding "_recent" suffix
}));

export const faqsListCamperLicence = faqsList[0];
export const faqsListBookingFees = faqsList[1];
export const faqsListTravelPacking = faqsList[2];
// najczesciej zadawane pytania landing
