import React, { useState } from 'react';
import FormWrapper from '../../UI/Wrappers/FormWrapper/FormWrapper';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import {
  selectVehicleIndex,
  setVehicleIndex,
  setVehicleName,
  setVehiclePrice,
  selectFormStep2Data,
} from '../../../ducks/formSlice';
import './StepFormCarSelect.scss';
import { campersCardsBooking } from '../../../constants/constantsCampers';
import SelectedCamperCard from '../../CamperCard/SelectedCarCard/SelectedCamperCard';
import ScrollToTop from '../../../hoc/ScrollToTop';
const StepFormCarSelect = () => {
  const [formError, setFormError] = useState('');

  const dispatch = useAppDispatch();
  const step2 = useAppSelector(selectFormStep2Data);
  const selectedVehicleIndex = useAppSelector(selectVehicleIndex);
  return (
    <FormWrapper title='Wybór Auta'>
      <ScrollToTop />
      <h3 className='subtitle'>Wybierz kampera</h3>
      <div className='select-car-container'>
        <div className='sliding-tabs'>
          {campersCardsBooking.map((c, idx) => {
            return (
              <React.Fragment>
                <input
                  required
                  key={idx}
                  type='radio'
                  id={`radio-${idx}`}
                  name='tabs'
                  value={idx}
                  checked={selectedVehicleIndex === idx}
                  onChange={() => {
                    dispatch(setVehicleIndex(idx));
                    dispatch(setVehicleName(c.camperModel));
                    dispatch(setVehiclePrice(c.camperHirePrice));
                  }}
                />
                <label className='sliding-tabs__tab' htmlFor={`radio-${idx}`}>
                  <span>{c.camperModel}</span>
                </label>
              </React.Fragment>
            );
          })}

          {selectedVehicleIndex !== null ? (
            <span className='glider'></span>
          ) : null}
        </div>
        <section>
          <div className='split stretch'>
            <div>
              {selectedVehicleIndex !== null &&
              campersCardsBooking[selectedVehicleIndex].camperModel !==
                'Wybierz Campera' ? (
                <SelectedCamperCard
                  selectedCamper={campersCardsBooking[selectedVehicleIndex]}
                />
              ) : (
                <div className='select-car__placeholder split stretch'>
                  <div className='flex-center'>
                    <div className='select-car__placeholder-icon'></div>
                  </div>
                  <div className='flex-center'>
                    <h3>
                      Tutaj wyświetlą się informacje
                      <br /> po wybraniu pojazdu
                      <br /> z listy powyżej
                    </h3>
                  </div>
                </div>
              )}
            </div>
            {/* {selectedVehicleIndex !== null ? <CamperCardReservation props={camperDetailsFordRoller266TL} /> : null} */}
          </div>
        </section>
      </div>
    </FormWrapper>
  );
};

export default StepFormCarSelect;
