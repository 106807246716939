import { useState } from 'react';
import { Icon } from '@iconify/react';
import './StarRating.scss';
const StarRating = () => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  return (
    <div className='flex-center star-rating'>
      {[...Array(5)].map((star, idx) => {
        const currentRating = idx + 1;
        return (
          <label htmlFor='rating' key={currentRating}>
            <input
              type='radio'
              name='rating'
              id=''
              value={currentRating}
              onClick={() => setRating(currentRating)}
              onMouseEnter={() => setHover(currentRating)}
              onMouseLeave={() => setHover(0)}
            />
            {rating >= currentRating || hover >= currentRating ? (
              <Icon icon='mdi:star' color='#e07000' width='32' height='32' />
            ) : (
              <Icon
                icon='mdi:star-outline'
                color='#e07000'
                width='32'
                height='32'
              />
            )}
          </label>
        );
      })}
    </div>
  );
};

export default StarRating;
