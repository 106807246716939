import React from 'react';
import { useAppSelector } from '../../../hooks/useRedux';
import { selectFormData } from '../../../ducks/formSlice';
import './BookingFormSummary.scss';
import {
  DateFormatter,
  NumberWithThousands,
} from '../../../helpers/helpersReturns';
const BookingFormSummary = () => {
  const formData = useAppSelector(selectFormData);
  const renderAccessoryRow = (x: any) => {
    if (x.accesoriesAmount === 0) return null;
    return (
      <div className='table-row'>
        <div className='table-cell table-cell-1'>{x.accesoriesName}</div>
        <div className='table-cell table-cell-3'>{x.accesoriesAmount}</div>
        <div className='table-cell table-cell-4'>
          <NumberWithThousands value={x.finalPrice} />
        </div>
      </div>
    );
  };

  const renderAccessoryGrid = (x: any) => {
    if (x.accesoriesAmount === 0) return null;
    return (
      <div key={x.accesoriesName} className='row-grid-acc'>
        <div>{x.accesoriesName}</div>
        <div>{x.accesoriesAmount}</div>
        <div>
          <NumberWithThousands value={x.finalPrice} />
        </div>
      </div>
    );
  };

  return (
    <div className='booking-summary'>
      <div className='booking-summary__card'>
        <h3 className='padding-block-300'>Podsumowanie rezerwacji:</h3>
        <div className='table-container'>
          <div>
            <div className='grid-row-summary-info'>
              <span>Pojazd:</span>
              <span>{formData.step2.name}</span>
            </div>
            <div className='grid-row-summary-info'>
              <span>Liczba osób:</span>
              <span> {formData.step1.personAmount}</span>
            </div>
            <div className='grid-row-summary-info'>
              <span>Miejsce wydania:</span>
              <span>{formData.step1.pickupPlaceAdress}</span>
            </div>
            <div className='grid-row-summary-info'>
              <span>Data wydania:</span>
              <DateFormatter date={formData.step1.pickupDate} />
            </div>
            <div className='grid-row-summary-info'>
              <span>Godzina wydania:</span>
              <span> {formData.step1.pickupTime}</span>
            </div>
            <div className='grid-row-summary-info'>
              <span>Miejsce zdania:</span>
              <span>{formData.step1.returnPlaceAdress}</span>
            </div>
            <div className='grid-row-summary-info'>
              <span>Data zdania:</span>
              <DateFormatter date={formData.step1.returnDate} />
            </div>
            <div className='grid-row-summary-info'>
              <span>Godzina zdania:</span>
              {formData.step1.returnTime}
            </div>
            <div className='grid-row-summary-info'>
              <span>Łącznie dni:</span>
              {formData.seasonDates.totalDays}
            </div>
          </div>
          <div className='padding-block-200'>
            <h4 className='padding-block-100'>Zakres wynajmu:</h4>
            <div className='grid-row-seasons'>
              <strong>Sezon</strong>
              <strong>Dni</strong>
              <strong>Stawka</strong>
              <strong>Łącznie</strong>
            </div>
            <div className='grid-row-seasons'>
              <span>Niski</span>
              <span>{formData.seasonDates.lowSeason}</span>
              <span>{formData.step2.price[0]}</span>
              <NumberWithThousands
                value={formData.finalPrice.carLowSeasonPrice}
              />
            </div>
            <div className='grid-row-seasons'>
              <span>Średni</span>
              <span>{formData.seasonDates.mediumSeason}</span>
              <span>{formData.step2.price[1]}</span>
              <NumberWithThousands
                value={formData.finalPrice.carMediumSeasonPrice}
              />
            </div>
            <div className='grid-row-seasons'>
              <span>Wysoki</span>
              <span>{formData.seasonDates.highSeason}</span>
              <span>{formData.step2.price[2]}</span>

              <NumberWithThousands
                value={formData.finalPrice.carHighSeasonPrice}
              />
            </div>
          </div>
          <div className='padding-block-200'>
            <h4 className='padding-block-100'>Wybrane akcesoria:</h4>
            <div className='row-grid-acc'>
              <strong>Nazwa:</strong>
              <strong>Ilość:</strong>
              <strong>Łącznie:</strong>
            </div>
            {!formData.step3.addedAccesories.length ? (
              <div>
                <div>
                  <p>Narazie nie wybrałeś żadnych akcesoriów</p>
                </div>
              </div>
            ) : (
              formData.step3.addedAccesories.map(renderAccessoryGrid)
            )}
          </div>
          <div className='padding-block-200'>
            <h4 className='padding-block-100'>Inne opłaty:</h4>
            <div className='grid-row-last'>
              <strong>Nazwa</strong>
              <strong>Opłata</strong>
            </div>
            <div className='grid-row-last'>
              <span>Kaucja</span>
              <NumberWithThousands value={formData.constData.depositPrice} />
            </div>
            <div className='grid-row-last'>
              <span>Opłata serwisowa</span>
              <span>{formData.constData.bookingFee}</span>
            </div>
            <div className='grid-row-73'>
              <strong>
                <sup>*</sup> Szacowana finalna kwota:
              </strong>
              <strong>
                <NumberWithThousands value={formData.finalPrice.totalPrice} />
                &nbsp; zł.
              </strong>
            </div>
            <div>
              <p className='small-text'>
                <sup>*</sup> Kwota uzależniona jest od wyboru miejsca odbioru i
                zwrotu pojazdu oraz godzin wydania lub zdania. W przypadku
                zmiany podstawowego adresu lub zakresu godzin odbioru/zdania,
                zostanie doliczona opłata zgodnie z cennikiem.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingFormSummary;
