import React from 'react';
import './BookingFormCounterStepIcons.scss';
import { iconsBookingFormSteps } from '../../../constants/constants';

interface Props {
  currentStepIndex: number;
  goTo?: (idx: number) => void;
}

const BookingFormCounterStepIcons: React.FC<Props> = ({
  currentStepIndex,
  goTo,
}) => {
  return (
    <div className='booking-form__counter-steps-icons'>
      {iconsBookingFormSteps.map((c, idx) => {
        const isActive = idx === currentStepIndex;
        const isClickable = !isActive && currentStepIndex > idx;
        const containerClassName = `booking-form__counter-steps__container ${
          isActive ? 'booking-form__counter-steps__container-active' : ''
        }`;
        const iconStyle = {
          backgroundImage: `url(${isActive ? c.activeIcon : c.icon})`,
          cursor: isClickable ? 'pointer' : 'default',
        };

        return (
          <div className={containerClassName} key={idx}>
            <div
              className='booking-form__counter-steps-icon'
              style={iconStyle}
              onClick={isClickable ? () => goTo?.(idx) : undefined}
            ></div>
            <div className='booking-form__counter-steps__content'>
              <p className='booking-form__counter-steps__title'>{c.title}</p>
              <p className='booking-form__counter-steps__subtitle'>
                {c.subTitle}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BookingFormCounterStepIcons;
