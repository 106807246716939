import React from 'react';

interface AccesoriesRadioInputOption {
  id: number;
  amount: number;
}

interface AccesoriesRadioInputProps {
  options: AccesoriesRadioInputOption[];
  selectedOptionId: number | null;
  nameAccesoriesInput: string;
  onOptionChange: (id: number) => void;
}

const AccesoriesRadioInput: React.FC<AccesoriesRadioInputProps> = ({
  options,
  nameAccesoriesInput,
  selectedOptionId,
  onOptionChange,
}) => {
  return (
    <div className='accessories-radio__body-slider'>
      {options.map((option) => (
        <div key={option.id} className='accessories-radio__body-slider__item'>
          <input
            className='accessories-radio__body-slider__item-radio'
            type='radio'
            name={nameAccesoriesInput}
            value={option.id}
            checked={selectedOptionId === option.id}
            onChange={() => onOptionChange(option.id)}
          />
          <div
            className={
              option.id === 0
                ? 'radio-tile radio-tile__default'
                : 'radio-tile'
            }
          >
            <label className='radio-tile-label'>
              <h3>{option.amount}</h3>
            </label>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AccesoriesRadioInput;
