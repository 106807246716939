import React, { useState, useEffect } from 'react';
import CamperSeasonPrice from './CamperSeasonPrice';
import iconSeasonLow from '../../assets/icons/season-pricing-low.png';
import iconSeasonMedium from '../../assets/icons/season-pricing-medium.png';
import iconSeasonHigh from '../../assets/icons/season-pricing-high.png';
import imagePricingBrand from '../../assets/images/our-cars/image-pricing-branding.jpg';
import WrapperSectionStyledHeader from '../UI/Wrappers/WrapperSection/WrapperSectionStyledHeader';
const CamperPricingSection = (props) => {
  const camperPricingPerSeasonList = [
    {
      seasonName: 'niski',
      seasonRange: ['01.11 - 31.03'],
      priceForCamperPerSeason: props.priceForCamperPerSeason[0],
      seasonIcon: iconSeasonLow,
      minimalRentRange: '3',
    },
    {
      seasonName: 'średni',
      seasonRange: [
        '01.04 - 31.05, z wyłączeniem 28.04 - 07.05',
        'oraz 01.09 - 31.10',
      ],
      priceForCamperPerSeason: props.priceForCamperPerSeason[1],
      seasonIcon: iconSeasonMedium,
      minimalRentRange: '5',
    },
    {
      seasonName: 'wysoki',
      seasonRange: ['01.06 - 31.08', 'oraz 28.04 - 07.05'],
      priceForCamperPerSeason: props.priceForCamperPerSeason[2],
      seasonIcon: iconSeasonHigh,
      minimalRentRange: '5',
    },
  ];

  const [seasonID, setSeasonID] = useState(0);

  const actualDate = new Date();

  useEffect(() => {
    const checkActualDateSeason = () => {
      const startingDate = new Date(actualDate);

      const month = startingDate.getMonth() + 1;
      const day = startingDate.getDate();

      if (
        (month >= 11 && month <= 12) ||
        month === 1 ||
        (month >= 2 && month <= 3)
      ) {
        setSeasonID(0);
      } else if (
        month === 6 ||
        month === 7 ||
        month === 8 ||
        (month === 4 && day >= 28) ||
        (month === 5 && day <= 7)
      ) {
        setSeasonID(2);
      } else {
        setSeasonID(1);
      }
    };
    checkActualDateSeason();
  }, [actualDate]);

  return (
    <WrapperSectionStyledHeader sectionName='Cennik'>
      <h3 className='subtitle'>
        Podane ceny są cenami brutto (zawierają już podatek VAT) za 1 dobę
        wynajmu.
      </h3>
      <div className='even-columns gap-600'>
        <div>
          {camperPricingPerSeasonList.map((c, index) => {
            return (
              <CamperSeasonPrice
                key={index}
                seasonName={c.seasonName}
                seasonRange={c.seasonRange}
                seasonIcon={c.seasonIcon}
                priceForCamperPerSeason={c.priceForCamperPerSeason}
                minimalRentRange={c.minimalRentRange}
                actualSeason={index === seasonID}
                oldPrice={c.oldPrice}
              />
            );
          })}
        </div>
        <div className='pricing-brand'>
          <img src={imagePricingBrand} alt='couple at bike' />
        </div>
      </div>
      <div className='pricing__info'>
        <h3>INNE INFORMACJE DOTYCZĄCE OPŁAT:</h3>
        <ul>
          <li>Opłata rezerwacyjna: 30% (płatne przy składaniu rezerwacji)</li>
          <li>Opłata całościowa: najpóźniej 3 dni przed terminem odbioru</li>
          <li>Opłata serwisowa (jednorazowa): 350 zł</li>
          <li>
            Kaucja 5000 zł, płatna na miejscu w dniu odbioru. Rozliczana do 7
            dni po zdaniu auta
          </li>
          <li>Wystawiamy faktury VAT</li>
        </ul>
      </div>
    </WrapperSectionStyledHeader>
  );
};

export default CamperPricingSection;
