import React from 'react';
import IconIifyIcon from '../../../../helpers/IconIifyIcon';
import './BottomNavigation.scss';
const BottomNavigation = () => {
  return (
    <nav className='bottom-nav flex-center'>
      <ul className='bottom-nav__list flex-center'>
        <li>
          <IconIifyIcon name='ion:caret-back' clr='#e07000' h='32' w='32' />
        </li>
        <li>
          <IconIifyIcon name='ci:hamburger-lg' clr='#e07000' h='32' w='32' />
        </li>
        <li>
          <IconIifyIcon
            name='ion:caret-back'
            clr='#e07000'
            h='32'
            w='32'
            rot='2'
          />
        </li>
        <li></li>
        <li></li>
      </ul>
    </nav>
  );
};

export default BottomNavigation;
