import React from 'react';
import PageWrapperImageHead from '../../component/UI/Wrappers/PageWrapper/PageWrapperImageHead';
import AdviceList from '../../component/Advices/AdviceList';
import heroImage from '../../assets/images/guides/guide-safety-hero.jpg';
import imgAdvice00 from '../../assets/images/guides/advices-safety/img-advice-safety-00.jpg';
import imgAdvice01 from '../../assets/images/guides/advices-safety/img-advice-safety-01.jpg';
import imgAdvice02 from '../../assets/images/guides/advices-safety/img-advice-safety-02.jpg';
import imgAdvice03 from '../../assets/images/guides/advices-safety/img-advice-safety-03.jpg';
import imgAdvice04 from '../../assets/images/guides/advices-safety/img-advice-safety-04.jpg';
import imgAdvice05 from '../../assets/images/guides/advices-safety/img-advice-safety-05.jpg';
import guideChildrenPhoto from '../../assets/images/guides/guide_children_photo.jpg';
import guideFirstTimePhoto from '../../assets/images/guides/guide_first_time_photo.jpg';
import guidePackPhoto from '../../assets/images/guides/guide_pack_guide_photo.jpg';
import OtherAdvicesBoxLinks from '../../component/Advices/OtherAdvicesBoxLinks';
const GuideSafety = () => {
  const adviceList = [
    {
      adviceNumber: 0,
      adviceName: 'Prędkość jazdy kamperem',
      adviceInfo:
        'W Polsce ograniczenia prędkości dla kamperów są identyczne jak w przypadku samochodów osobowych.\n\n Niemniej, ze względu na wielkość i kształt kampera, lepiej jest zachować prędkość na autostradzie na poziomie 80-100 km/h, w obszarze zabudowanym ograniczyć ją do 50 km/h, a na pozostałych drogach utrzymać maksymalnie 70 km/h. Jeśli wybierasz się za granicę, warto sprawdzić zasady obowiązujące w danym państwie jeszcze przed wyjazdem, aby uniknąć większych kosztów podróży.',
      adviceImage: imgAdvice00,
    },
    {
      adviceNumber: 1,
      adviceName: 'Niepogoda na drodze',
      adviceInfo:
        'Poruszaj się w sposób dostosowany do warunków na drodze. Jeśli pojawią się ulewy bądź śnieżyce - zwolnij lub zrób przystanek. Jeśli jedziesz we mgle - pamiętaj o włączeniu odpowiednich świateł.\n\nNie ma się co śpieszyć - poczuj wolość podróży kamperem! Twój samolot nie odleci - podróżujesz dużo wygodniej! Jeśli zaplanowałeś wcześniej lot szybowcem - wtedy rzeczywiście może być problem.',
      adviceImage: imgAdvice01,
    },
    {
      adviceNumber: 2,
      adviceName: 'Postój poza kampingami',
      adviceInfo:
        'Parkowanie kampera w Polsce jest legalne na każdym parkingu, o ile nie ma specjalnego zakazu. Podobnie jest w innych krajach europejskich, ale warto samodzielnie zrobić reaserch. Zwracaj uwagę na znaki drogowe zakazujące parkowania kamperów. Pamiętaj też, że kamper zajmie najprawdopodobniej 2-3 standardowe miejsca parkingowe.\n\nNie w każdym państwie można nocować w kamperze na parkingach państwowych. O ile w Chorwacji, Słowenii czy Szwajcarii jest to niedozwolone, tak w Norwegii czy Rumunii możesz zrobić postój „na dziko” praktycznie na każdym parkingu. Sprawdź przed wyjazdem zasady postoju kamperem w krajach, do których zmierzasz, aby uniknąć mandatów.',
      adviceImage: imgAdvice02,
    },
    {
      adviceNumber: 3,
      adviceName: 'Pasy bezpieczeństwa i foteliki dla dzieci',
      adviceInfo:
        'Podczas jazdy trzeba zapiąć pasy - sprawa jest oczywista. Jeśli podróżujesz z dzieckiem, zwracaj szczególną uwagę, czy fotelik został prawidłowo zamocowany. Chodzenie po kamperze w trakcie jazdy jest niebezpieczne i zabronione. Jeśli potrzebujesz sięgnąć z szafki po butelkę wody - zrób krótki postój. Nie ryzykuj.',
      adviceImage: imgAdvice03,
    },
    {
      adviceNumber: 4,
      adviceName: 'Uważaj na złodziei',
      adviceInfo:
        'Pamiętaj, aby zawsze zamykać kamper, gdy się od niego oddalasz. Nie zapominaj o zamknięciu i zasłonięciu okien. Na noc, zamknij wszystkie drzwi. Dbaj o to, aby alarmy były włączone.\n\nNie stosując się do tych zasad, może się okazać, że pewnej nocy obudzisz się goły i (nie)wesoły.',
      adviceImage: imgAdvice04,
    },
    {
      adviceNumber: 5,
      adviceName: 'Gotowanie w kamperze',
      adviceInfo:
        'Ponieważ w naszych kamperach są kuchenki gazowe, należy zachować szczególną ostrożność podczas przygotowywania posiłków. Upewnij się, że gdy kuchenka nie jest używana, gaz został dokładnie zakręcony. Nie stawiaj blisko kuchenki rzeczy, które są łatwopalne. W kamperze zabronione jest też flambirowanie - otwartym ogniem „baw się” w domowej kuchni.',
      adviceImage: imgAdvice05,
    },
  ];
  const otherAdvicePagesList = [
    {
      pageName: 'Z dziećmi',
      urlToPage: 'poradniki/z-dziecmi',
      pagePhoto: guideChildrenPhoto,
    },
    {
      pageName: 'Pierwszy raz',
      urlToPage: 'poradniki/pierwszy-raz',
      pagePhoto: guideFirstTimePhoto,
    },
    {
      pageName: 'Co spakować?',
      urlToPage: 'poradniki/co-spakowac',
      pagePhoto: guidePackPhoto,
    },
  ];
  return (
    <PageWrapperImageHead pageTitle='Bezpieczeństwo' photo_brand={heroImage}>
      <AdviceList
        adviceList={adviceList}
        adviceSectionDescription={`Bezpieczeństwo to podstawa.\nPodczas podróży kamperem trzeba zachować szczególną ostrożność, gdyż pojazd jest duży i porusza się dość niezgrabnie. \n Stosując się do poniższych wskazówek unikniesz nieprzyjemnych i niebezpiecznych sytuacji.`}
      />
      <OtherAdvicesBoxLinks otherAdvicePages={otherAdvicePagesList} />
    </PageWrapperImageHead>
  );
};

export default GuideSafety;
