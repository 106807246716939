import React from 'react';
import { AdviceItem } from './AdviceItem';
const AdviceList = (props) => {
  return (
    <section>
      {props.adviceSectionDescription && (
        <div className='advice-section__description'>
          <h4 className='text-center'>{props.adviceSectionDescription}</h4>
        </div>
      )}
      {props.adviceList.map((a, idx) => {
        return (
          <AdviceItem
            key={idx}
            adviceName={a.adviceName}
            adviceInfo={a.adviceInfo}
            adviceImage={a.adviceImage}
            adviceNumber={a.adviceNumber}
            adviceInfoList={a.adviceInfoList}
          />
        );
      })}
    </section>
  );
};

export default AdviceList;
