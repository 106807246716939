import React, { useRef } from 'react';

interface InsideAccordionList {
  listItem: string;
  link?: string;
}

interface AccordionItem {
  header: any;
  id: any;
  typeAccordion: string;
  text: string;
  list: InsideAccordionList[];
  link?: string;
}

interface Props {
  active: any;
  handleToggle: any;
  accordionItem: AccordionItem;
}

const AccordionSwitchTypeItem: React.FC<Props> = ({
  active,
  accordionItem,
  handleToggle,
}: Props) => {
  const contentEl = useRef<HTMLDivElement>(null);

  return (
    <div
      className={
        active === accordionItem.id
          ? 'accordion-card is-open'
          : 'accordion-card'
      }
    >
      <div
        className={
          active === accordionItem.id
            ? 'accordion-card__head is-open'
            : 'accordion-card__head'
        }
        onClick={() => handleToggle(accordionItem.id)}
        title={
          active === accordionItem.id ? 'Ukryj szczegóły' : 'Pokaż szczegóły'
        }
      >
        <h3>{accordionItem.header}</h3>

        <span
          className={
            active === accordionItem.id
              ? 'dropdown_arrow dropdown_arrow_active'
              : 'dropdown_arrow'
          }
        ></span>
      </div>
      <div
        ref={contentEl}
        className={`accordion-card__content ${
          active === accordionItem.id ? 'show' : ''
        }`}
        style={
          active === accordionItem.id
            ? { height: contentEl?.current?.scrollHeight! }
            : { height: '0px' }
        }
      >
        <div className='accordion-card__content-container'>
          {accordionItem.typeAccordion === 'description' ? (
            <p>
              {accordionItem.text}
              {accordionItem.link && (
                <a href={accordionItem.link} target='_blank' rel='noreferrer'>
                  tutaj.
                </a>
              )}
            </p>
          ) : accordionItem.typeAccordion === 'descriptionWithList' ? (
            <div>
              <p>{accordionItem.text}</p>
              <ul>
                {accordionItem.list.map((item, index) => (
                  <li key={index}>{item.listItem}</li>
                ))}
              </ul>
            </div>
          ) : accordionItem.typeAccordion === 'list' ? (
            <div>
              <ul>
                {accordionItem.list.map((item, index) => (
                  <li key={index}>
                    {item.listItem}
                    {item.link && (
                      <a href={item.link} target='_blank'>
                        tutaj
                      </a>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AccordionSwitchTypeItem;
