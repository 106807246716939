import { IForm } from '../types/types';

export const prepareCard = (formData: IForm) => {
  return {
    context: 'https://schema.org/extensions',
    sections: [
      {
        activityTitle: 'Dane rezerwacji:',
        activitySubtitle: 'test',
        facts: [
          {
            name: 'Imię i nazwisko:',
            value: `${formData.step4.firstName} ${formData.step4.lastName}`,
          },

          {
            name: 'Numer telefonu:',
            value: formData.step4.phoneNumber,
          },
          {
            name: 'E-mail:',
            value: formData.step4.email,
          },
          {
            name: 'Adres odbioru:',
            value: formData.step1.pickupPlaceAdress,
          },
          {
            name: 'Termin odbioru:',
            value: `${formData.step1.pickupDate}, o godzinie: ${formData.step1.pickupTime}`,
          },
          {
            name: 'Adres zwrotu:',
            value: formData.step1.returnPlaceAdress,
          },
          {
            name: 'Termin zwrotu:',
            value: ` ${formData.step1.returnDate}, o godzinie: ${formData.step1.returnTime}`,
          },
          {
            name: 'Liczba osób:',
            value: ` ${formData.step1.personAmount}`,
          },
          {
            name: 'Wybrany pojazd',
            value: ` ${formData.step2.name},`,
          },
          {
            name: 'Sezon Niski',
            value: `${formData.seasonDates.lowSeason} dni * ${formData.step2.price[0]} = ${formData.finalPrice.carLowSeasonPrice} zł `,
          },
          {
            name: 'Sezon Średni',
            value: `${formData.seasonDates.mediumSeason} dni * ${formData.step2.price[1]} = ${formData.finalPrice.carMediumSeasonPrice} zł `,
          },
          {
            name: 'Sezon Wysoki',
            value: `${formData.seasonDates.highSeason} dni * ${formData.step2.price[2]} = ${formData.finalPrice.carHighSeasonPrice} zł `,
          },

          ...formData.step3.addedAccesories.map((x) => ({
            name: x.accesoriesName,
            value: `Ilość: ${x.accesoriesAmount}, Cena: ${x.accesoriesPrice} * ${formData.seasonDates.totalDays} = ${x.finalPrice}`,
          })),
          {
            name: 'Łącznie',
            value: `${formData.finalPrice.totalPrice}zł `,
          },
        ],
      },
    ],
    summary: 'Issue 176715375',
    themeColor: '0078D7',
    title: 'Formularz rezerwacji',
    type: 'MessageCard',
  };
};

export const calculateSeasonDays = (startDate: string, endDate: string) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const millisecondsPerDay = 1000 * 60 * 60 * 24;
  const daysDifference =
    Math.ceil((end.getTime() - start.getTime()) / millisecondsPerDay) + 1;

  let lowSeason = 0;
  let mediumSeason = 0;
  let highSeason = 0;

  for (let i = 0; i < daysDifference - 1; i++) {
    const currentDate = new Date(start);
    currentDate.setDate(start.getDate() + i);

    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();

    if (
      (month >= 11 && month <= 12) ||
      month === 1 ||
      (month >= 2 && month <= 3)
    ) {
      lowSeason++;
    } else if (
      month === 6 ||
      month === 7 ||
      month === 8 ||
      (month === 4 && day >= 28) ||
      (month === 5 && day <= 7)
    ) {
      highSeason++;
    } else {
      mediumSeason++;
    }
  }

  return {
    lowSeason,
    mediumSeason,
    highSeason,
    totalDays: lowSeason + mediumSeason + highSeason,
  };
};

// zaokrąglannie cyfr zmiennoprzecinkowych
export default function roundNumber(
  number: number,
  decimalPlaces: number
): number {
  return +(
    Math.round(Number(number + 'e' + decimalPlaces)) +
    'e-' +
    decimalPlaces
  );
}
