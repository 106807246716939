import React, { useState } from 'react';
import './Modal.scss';
import { useNavigate } from 'react-router-dom';

interface ModalProps {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const Modal: React.FC<ModalProps> = ({ setOpenModal }) => {
  const navigate = useNavigate();

  const closeModal = () => {
    setOpenModal(false);
    navigate('/');
    window.location.reload();
  };

  return (
    <div className='modal-background' onClick={closeModal}>
      <div className='modal-container'>
        <div className='modal-header'>
          <button className='modal-header__close' onClick={closeModal}></button>
        </div>
        <div className='modal-body'>
          <div className='modal-content'>
            <div className='modal-content-icon'></div>
            <div className='modal-content-message'>
              <h3>Dziękujemy za dokonanie rezerwacji!</h3>
              <p>
                Twoja prośba została przesłana pomyślnie.
                <br />
                Postaramy się odpowiedzieć na nią tak szybko, jak to możliwe.
              </p>
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          <button className='btn btn-primary btn-small' onClick={closeModal}>
            Przejdź na stronę główną
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
