import React from 'react';
import { Link } from 'react-router-dom';
import Container from '../component/UI/Wrappers/Container/Container';
import BenefitsOurCampers from '../component/BeneftisOurCampers/BenefitsOurCampers';
import AccordionSwitchTypeList from '../component/Accordions/AccorditionSwitchType/AccordionSwitchTypeList';
import LinkBoxPhoto from '../component/LinkBoxPhoto/LinkBoxPhoto';
import './Landing.scss';
import EntryLandingForm from '../component/Forms/EntryLandingForm';
import { faqRecentList } from '../constants/constantsFaqs';
import { pagesListAdvices } from '../constants/constantsAdvices';
import WrapperSectionStyledHeader from '../component/UI/Wrappers/WrapperSection/WrapperSectionStyledHeader';
const Landing = () => {
  return (
    <React.Fragment>
      <div className='hero_landing'>
        <EntryLandingForm />
      </div>
      <div className='hero_landing_second'>
        <BenefitsOurCampers />
      </div>

      <WrapperSectionStyledHeader sectionName='Porady i wskazówki'>
        <div className='grid-advices'>
          {pagesListAdvices.map((p, idx) => {
            return (
              <LinkBoxPhoto
                key={idx}
                pageUrl={p.pageUrl}
                pageName={p.pageName}
                pageDescription={p.pageDescription}
                pagePhoto={p.pagePhoto}
              />
            );
          })}
        </div>
      </WrapperSectionStyledHeader>
      <WrapperSectionStyledHeader sectionName='Najczęściej zadawane pytania'>
        <AccordionSwitchTypeList faqs={faqRecentList} />
        <div className='center padding-block-400'>
          <Link
            to='/faq'
            className='btn btn-big btn-primary btn__with-icon btn__more-info'
          >
            Pokaż więcej pytań
          </Link>
        </div>
      </WrapperSectionStyledHeader>
    </React.Fragment>
  );
};

export default Landing;
