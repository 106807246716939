import React from 'react';

export const Terms = () => {
  return (
    <>
      <div>
        <h4 className='text-center'>
          §1.
          <br />
          Postanowienia ogólne
        </h4>
        <ol>
          <li>
            <h4>Definicje</h4>
            <ul>
              <li>Regulamin – niniejszy dokument;</li>
              <li>
                Najemca – osoba dokonująca rezerwacji lub osoba, która następnie
                zawarła umowę najmu Pojazdu;
              </li>
              <li>
                Wynajmujący – Grupa Dobra Sprawa sp. z o.o. ul. Prosta 36 53-508
                Wrocław NIP: 8943151001, , adres email: kontakt@ikifriki.com ,
                tel. Kom. 519742598, KRS 0000827342;
              </li>
              <li>
                Przedmiot Najmu lub Pojazd – samochód kempingowy – camper będący
                w posiadaniu Wynajmującego;
              </li>
              <li>
                Najem – oddanie przez Wynajmującego Przedmiotu Najmu do
                czasowego używania przez Najemcę w zamian za umówioną zapłatę;
              </li>
              <li>
                Umowa – dokument potwierdzający najem podpisany przez obie
                strony: Najemcę oraz Wynajmującego;
              </li>
              <li>Kwoty występujące w Regulaminie są kwotami brutto.</li>
            </ul>
          </li>
          <li>
            <h4>
              Ogólne warunki najmu Pojazdu oferowanego przez firmę Grupa Dobra
              Sprawa sp. z o.o :
            </h4>
            <p>
              Wynajmujący informuje Najemcę o fakcie, iż przedmiotem umowy jest
              najem Pojazdu, a nie umowa o świadczenie usług turystycznych, w
              rozumieniu przepisów ustawy z dnia 29.08.1997r o usługach
              hotelarskich oraz usługach pilotów wycieczek i przewodników
              turystycznych ( tj. Dz. U. z 2020 r. poz. 2211 z późn. zm.).
              Oznacza to, że Najemca zobowiązuje się do samodzielnej organizacji
              i planowania podróży oraz ma świadomość faktu, iż bierze na siebie
              pełną odpowiedzialność za siebie, współpasażerów, oraz za
              Przedmiot Najmu oraz jest przygotowany na ewentualne sytuacje
              nieprzewidziane, np. choroba, wypadek, awaria Pojazdu itp.,
              ponieważ pomoc Wynajmującego może być ograniczona z powodu np.
              godzin nocnych bądź braku zasięgu w miejscu pobytu Najemcy.
              Wynajmujący zapewnia wymagane prawem umowy ubezpieczenia
              Przedmiotu Najmu. Dodatkowe ubezpieczenie (poza wymienionymi w §
              2) Najemca zawiera we własnym imieniu i na własny rachunek.
            </p>
          </li>
          <li>
            <h4>Przedmiot najmu</h4>
            <p>
              Przedmiotem najmu jest wybrany Pojazd (samochód kempingowy) będący
              w posiadaniu firmy Grupa Dobra Sprawa sp. z o.o. wraz z wybranym
              standardem wyposażenia określonym w protokole zdawczo-odbiorczym.
              W protokole zdawczo-odbiorczym strony określą stan faktyczny
              Pojazdu. Protokół zdawczo-odbiorczy stanowi podstawę do porównania
              stanu Pojazdu w chwili jego zwrotu, do stanu Pojazdu w chwili jego
              wydania.
            </p>
          </li>
        </ol>
      </div>
      <div>
        <h4 className='text-center'>
          §2.
          <br />
          Oświadczenie wynajmującego
        </h4>
        <p>
          <strong>Wynajmujący oświadcza</strong>, iż jest w posiadaniu Pojazdu.
          Pojazd posiada: aktualną polisę OC, AC, Assistance stanowiące
          integralną całość z Ogólnymi Warunkami Ubezpieczenia. Pojazd jest w
          dobrym stanie technicznym, posiada aktualne badania techniczne.
        </p>
      </div>
      <div>
        <h4 className='text-center'>
          §3.
          <br />
          Najemca
        </h4>
        <ol>
          <li>
            <h4>Zobowiązanie najemcy:</h4>
            <p>
              Najemca zobowiązuje się do okazania dokumentu potwierdzającego
              jego tożsamość podczas odbierania przedmiotu Najmu (dowód Osobisty
              lub Paszport). Najemca musi być obywatelem Unii Europejskiej.
            </p>
          </li>
          <li>
            <h4>Koszty eksploatacji:</h4>
            <p>
              W czasie trwania Najmu wszelkie koszty eksploatacyjne, m.in.
              paliwo, przejazd płatnymi drogami, oraz płyny eksploatacyjne,
              pokrywa w całości Najemca.
            </p>
          </li>
          <li>
            <h4>Trasa podróży:</h4>
            <ul>
              <li>
                Trasa podróży po terytorium Unii Europejskiej nie wymaga
                uzgodnień z Wynajmującym;
              </li>
              <li>
                Najemca zobowiązuje się uzgodnić wcześniej z Wynajmującym każdą
                podróż odbywająca się poza terytorium Unii Europejskiej, i
                uzyskać akceptację Wynajmującego (zgodę);
              </li>
              <li>
                W przypadku braku zgody Wynajmującego na podróż do krajów spoza
                Unii Europejskiej, gdy mimo braku tej zgody Najemca zdecyduje
                się na taką trasę - ponosi nieograniczoną odpowiedzialność za
                szkody w Pojeździe, spowodowane naruszeniem niniejszych ustaleń
                oraz traci prawo do zwrotu w całości wpłaconej kaucji.
              </li>
            </ul>
          </li>
          <li>
            <h4>Bezpieczeństwo</h4>
            <ol type='a'>
              <li>
                Pojazd powinien być zamykany na czas nieobecności. Najemca
                zobowiązuje się do regularnej kontroli Przedmiotu Najmu i
                upewnienia się, iż spełnia on wszelkie warunki niezbędne do
                bezpiecznej jazdy. Usterki spowodowane użytkowaniem Pojazdu
                należy niezwłocznie usunąć
              </li>
              <li>
                Najemca zobowiązuje się do posiadania przy sobie kopii dowodu
                rejestracyjnego oraz innych dokumentów Przedmiotu Najmu oraz
                kompletu kluczyków wraz z pilotem, przez cały czas trwania
                Umowy. Najemca zobowiązuje się dopilnować, by dokumenty oraz
                kluczyki nie zostały skradzione, zgubione bądź zniszczone.
                Ewentualna kradzież, zgubienie bądź zniszczenie skutkuje zapłatą
                przez Najemcę, kary umownej w wysokości 1 000 zł.
              </li>
              <li>
                Pozostawienie wyżej wymienionych dokumentów lub kluczyków w
                Pojeździe jest surowo zabronione, pełną odpowiedzialność za
                szkodę z tego wynikającą ponosi Najemca.
              </li>
              <li>
                Najemca zobowiązuje się do nieprzekraczania prędkości 110 km/h
                (zaleca się prędkość do 100 km/h) oraz do zachowania szczególnej
                ostrożności podczas jazdy, zwłaszcza przy tunelach, mostach
                itp., ze względu na wysokość Pojazdu, która jest zależna od
                modelu kampera od 290 do 330cm.
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <div>
        <h4 className='text-center'>
          §4.
          <br />
          Rezerwacja i zapłata za wynajem Pojazdu
        </h4>
        <ol>
          <li>
            <h4>Wstępna rezerwacja</h4>
            <ol type='a'>
              <li>
                Najemca może dokonać wstępnej rezerwacji poprzez: o kontakt
                telefoniczny, o kontakt e-mailowy, wypełniając formularz
                zamówienia znajdujący się na stronie www www.ikifriki.pl;
              </li>
              <li>
                Najemca otrzymuje potwierdzenie wstępnej rezerwacji drogą
                elektroniczną, na wskazany przez siebie adres e-mail. Rezerwacja
                wstępna będzie utrzyma przez okres 3 dni;
              </li>
            </ol>
          </li>
          <li>
            <h4>Rezerwacja</h4>
            <ol type='a'>
              <li>
                Właściwa rezerwacja zostanie dokonana po podpisaniu Umowy Najmu
                oraz wpłacenia w ciągu trzech dni od daty jej zawarcia
                częściowej (30%) kwoty ustalonej za Najem. Płatności można
                dokonać przelewem na wskazane przez Wynajmującego konto bankowe.
              </li>
              <li>
                Wpłata częściowej kwoty Najmu jest rozumiana jako zawarcie Umowy
                Najmu oraz zaakceptowanie niniejszego Regulaminu.
              </li>
              <li>
                3 dni przed dniem odbioru Przedmiotu Najmu, Najemca wypełnia i
                dostarcza wypełnione niezbędne dokumenty wskazane w Regulaminie
                - osobiście lub przesyła i opłaca 100% kwoty wynagrodzenia za
                najem. W przypadku braku wpłaty pełnego wynagrodzenia strony
                zgodnie przyjmują, że Najemca zrezygnował z wynajmu Pojazdu, a
                wpłacona już kwota nie podlega zwrotowi.
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <div>
        <h4 className='text-center'>
          §5.
          <br />
          Koszty Najmu
        </h4>
        <p>
          <strong>Całkowity koszt najmu za Przedmiot Najmu</strong> ustalany
          jest na podstawie aktualnego cennika Wynajmującego, który jest
          umieszczony na stronie internetowej www.ikifriki.pl W skład kosztu
          wchodzi w szczególności: wynagrodzenie za Najem liczone według liczby
          dni, których ilość została określona w Umowie Najmu, kaucja, która
          jest zwracana w ciągu siedmiu dni od dnia zwrotu Pojazdu, jeżeli
          Przedmiot Najmu był prawidłowo użytkowany oraz nie zostały naruszone
          punkty znajdujące się w niniejszym Regulaminie, uprawniające do
          zatrzymania kaucji przez Wynajmującego.
        </p>
      </div>
      <div>
        <h4 className='text-center'>
          §6.
          <br />
          Kaucja
        </h4>
        <ol>
          <li>
            <h4>Zobowiązanie do wpłacenia kaucji:</h4>
            <p>
              Najemca zobowiązuje się wpłacić kaucję zwrotną, na zabezpieczenie
              roszczeń i należności przysługujących z tytułu niewykonania lub
              nienależytego wykonania umowy, jak również opłat i kar należnych
              od Najemcy, w wysokości 5000,00 zł, najpóźniej na 7 dni przed
              odbiorem Przedmiotu Najmu. W wyjątkowych okolicznościach, za zgodą
              Wynajmującego jest możliwość wpłaty kaucji bezpośrednio przed
              odbiorem Przedmiotu Najmu. Kaucja przeznaczona jest na pokrycie
              ewentualnych kosztów napraw bądź ubytków w wyposażeniu Przedmiotu
              Najmu, jak również wszelkich innych roszczeń wobec Najemcy
              wynikających z umowy najmu.
            </p>
          </li>
          <li>
            <h4>Warunki zwrotu kaucji:</h4>
            <p>
              W przypadku braku dodatkowych kosztów, o których mowa w § 6.1,
              całkowita kwota kaucji zostanie zwrócona w ciągu 7 dni od daty
              oddania Pojazdu oraz podpisania protokołu zdawczo - odbiorczego.
              Kaucja zostanie zwrócona na wskazane przez Najemcę konto bankowe.
            </p>
          </li>
          <li>
            <h4>Usterki wykryte w ciągu 7 od zdania Przedmiotu Najmu :</h4>
            <p>
              Jeżeli w ciągu tych 7 dni zostaną wykryte usterki spowodowane
              przez Najemcę, a nie uwzględnione w protokole zdawczo-odbiorczym z
              powodu niedopatrzenia bądź zatajenia, Wynajmujący po
              udokumentowaniu usterek i przekazaniu dokumentacji Najemcy ma
              prawo do pomniejszenia wypłaconej kaucji o koszt naprawy
              ustalonych usterek.
            </p>
          </li>
          <li>
            <h4>Utrata całości kaucji bądź jej części:</h4>
            <p>
              Utrata całości kaucji bądź jej części wynika z nieprzestrzegania
              przez Najemcę warunków przedstawionych w niniejszym Regulaminie,
              szczególnie w przypadkach:
            </p>
            <ul>
              <li>
                kradzieży lub szkody całkowitej Przedmiotu Najmu – utrata
                całości kaucji;
              </li>
              <li>
                spowodowanie kolizji drogowej z winy Najemcy – utrata całości
                kaucji;
              </li>
              <li>
                niekontrolowanie poziomu oleju silnikowego, co może skutkować
                uszkodzeniem silnika – utrata całości kaucji;
              </li>
              <li>
                uszkodzenie Przedmiotu Najmu – wypłacona kaucja zostanie
                pomniejszona o połowę lub w przypadku poważniejszego uszkodzenia
                o kwotę kosztów naprawy pojazdu;
              </li>
              <li>
                kradzież, zgubienie bądź zniszczenie kluczyków, pilota bądź
                dokumentów Przedmiotu Najmu – wypłacona kaucja zostanie
                pomniejszona o kwotę 1000 zł;
              </li>
              <li>
                złamanie zakazu palenia w Przedmiocie Najmu – wypłacona kaucja
                zostanie pomniejszona o kwotę 1500 zł;
              </li>
              <li>
                zwrot brudnego wewnątrz pojazdu – wypłacona kaucja zostanie
                pomniejszona o kwotę 250 zł;
              </li>
              <li>
                zabrudzenie tapicerki wymagające prania – wypłacona kaucja
                zostanie pomniejszona o kwotę 300 zł;
              </li>
              <li>
                nie wyczyszczenie toalety chemicznej – wypłacona kaucja zostanie
                pomniejszona o kwotę 150 zł;
              </li>
              <li>
                zwrot brudnego na zewnątrz pojazdu – wypłacona kaucja zostanie
                pomniejszona o kwotę 100 zł;
              </li>
              <li>
                nieuzupełnienie zbiornika paliwa po okresie najmu – wypłacona
                kaucja zostanie pomniejszona o kwotę 100 zł. plus koszty paliwa.
              </li>
            </ul>
          </li>
        </ol>
      </div>
      <div>
        <h4 className='text-center'>
          §7.
          <br />
          Ograniczenia w użytkowaniu
        </h4>
        <ul>
          <li>
            W Przedmiocie Najmu obowiązuje bezwzględny zakaz palenia papierosów
            oraz spożywania innych używek w tym wszelkich środków
            psychoaktywnych;
          </li>
          <li>
            W Przedmiocie Najmu obowiązuje zakaz przetrzymywania bądź
            przewożenia zwierząt bez zgody Wynajmującego;
          </li>
          <li>
            Przedmiot Najmu nie może być wykorzystywany do podróży po bezdrożach
            bądź terenach górskich lub leśnych, do jazdy, po których Pojazd nie
            jest technicznie przystosowany;
          </li>
          <li>
            Przedmiot Najmu może być wykorzystywany jedynie do celów
            rekreacyjno-turystycznych;
          </li>
          <li>
            Zabrania się wykorzystywania Pojazdu do transportu towarów,
            wszelkich zwierząt dzikich i domowych, zwłok, materiałów
            niebezpiecznych chemicznie, biologicznie, radioaktywnych,
            łatwopalnych bądź nielegalnych oraz brania udziału w imprezach
            samochodowych;
          </li>
          <li>
            Przedmiot Najmu nie może być udostępniany osobom trzecim, za
            wyjątkiem ustalonego drugiego kierowcy. W przypadku przekazania
            Pojazdu osobie trzeciej, Najemca ponosi pełną odpowiedzialność za
            Przedmiot Najmu oraz zobowiązany jest do zapłaty kary umownej w
            wysokości 2.000 zł.
          </li>
        </ul>
      </div>
      <div>
        <h4 className='text-center'>
          §8.
          <br />
          Odpowiedzialność Najemcy
        </h4>
        <ol>
          <li>
            <h4>Szkody z winy Najemcy:</h4>
            <p>
              Najemca ponosi pełną odpowiedzialność za szkody, które powstały z
              Jego winy, w sytuacji gdy ubezpieczyciel odstąpi od wypłaty
              odszkodowania lub wypłacone odszkodowanie nie pokryje całości
              szkody. Odpowiedzialność Najemcy (za wyjątkiem obowiązku zapłaty
              kar umownych) jest pomniejszona o kwoty wypłacone przez
              Ubezpieczyciela, z zastrzeżeniem postanowień poniższych.
            </p>
          </li>
          <li>
            <h4>Pokrycie kosztów przypadku zdarzenia z winy Najemcy:</h4>
            <p>
              W przypadku, gdy usterka, awaria tudzież kolizja bądź wypadek
              drogowy, w którym udział brał Przedmiot Najmu nastąpiły z winy
              Najemcy, Najemca pokrywa wszelkie koszty, które są związane z
              usunięciem skutków zaistniałego zdarzenia. Najemca ponosi pełną
              odpowiedzialność za szkody wyrządzone osobom trzecim w związku z
              ruchem pojazdu, chyba że właściwe przepisy wyłączają
              odpowiedzialność Najemcy.
            </p>
          </li>
          <li>
            <h4>
              Brak możliwości kontynuowania podróży spowodowanej zdarzeniem z
              winy Najemcy:
            </h4>
            <p>
              Jeżeli Najemca nie będzie mógł kontynuować podróży wskutek kolizji
              bądź innych uszkodzeń, które nastąpiły z wyłącznej winy Najemcy,
              Najemcy nie przysługuje zwrot wynagrodzenia za najem za okres do
              zakończenia umowy.
            </p>
          </li>
          <li>
            <h4>Odpowiedzialność Najemcy za powstałe szkody:</h4>
            <ul>
              <li>
                Najemca ponosi pełną odpowiedzialność za Przedmiot Najmu,
                zwłaszcza w przypadku kradzieży bądź zaginięcia kluczy, pilota
                lub dokumentów Przedmiotu Najmu.
              </li>
              <li>
                Najemca ponosi pełną odpowiedzialność za wszelkie uszkodzenia
                Przedmiotu najmu powstałe w czasie trwania Umowy, nawet jeżeli
                nie powstały bezpośrednio z winy Najemcy. Przykładowymi
                uszkodzeniami mogą być: uszkodzenie opony, pęknięcie szyby
                powstałe od uderzenia czy uszkodzenia zawieszenia.{' '}
              </li>
            </ul>
          </li>
          <li>
            <h4>
              Odpowiedzialność Najemcy w przypadku naruszenia przepisów
              drogowych lub lekkomyślności, niedbalstwa bądź dewastacji:
            </h4>
            <ul>
              <li>
                Najemca ponosi pełną odpowiedzialność za szkody, które nastąpiły
                wskutek naruszenia przepisów drogowych lub też powstały w wyniku
                lekkomyślności, niedbalstwa bądź dewastacji. Najemca
                odpowiedzialny jest za własne działanie lub zaniechanie
                działania oraz za działania lub zaniechania osób przy pomocy
                których wykonuje umowę najmu (w tym osoby korzystające z
                Przedmiotu Najmu). Najemca ponosi pełną odpowiedzialność za
                szkody wyrządzone wskutek kierowania pojazdem pod wpływem
                alkoholu, narkotyków i innych środków odurzających. Najemca
                ponosi pełną odpowiedzialność za wszelkie uszkodzenia Pojazdu
                powstałe w czasie trwania Umowy, nawet jeżeli nie powstały
                bezpośrednio z winy Najemcy. Przykładowymi uszkodzeniami mogą
                być: uszkodzenie opony, pęknięcie szyby powstałe od uderzenia
                czy uszkodzenia zawieszenia spowodowane niedostosowaniem
                prędkości do warunków drogowych. W razie wystąpienia powyższych
                uszkodzeń Najemca zapłaci Wynajmującemu karę umowną w wysokości
                połowy kaucji, za każde z uszkodzeń pojazdu.
              </li>
              <li>
                Odpowiedzialność Najemcy obejmuje również szkody wyrządzone w
                wyposażeniu dodatkowym pojazdu takim jak np. markiza dachowa,
                będące następstwem nieprawidłowego zachowania. Zabrania się
                grillowania lub umieszczania innego źródła ognia bezpośrednio
                pod markizą lub w jej pobliżu. Zabrania się użytkowania markizy,
                gdy wieje wiatr lub intensywnych opadów deszczu.
              </li>
            </ul>
          </li>
          <li>
            <h4>
              Wyłączenie odpowiedzialności Najemcy w wyniku zaniedbań
              Wynajmującego:
            </h4>
            <p>
              Najemca nie ponosi odpowiedzialności za szkody lub awarie pojazdu,
              które powstały w wyniku zaniedbań Wynajmującego. Przykładowymi
              zaniedbaniami mogą być: niedokonanie terminowej wymiany oleju czy
              zbyt duże zużycie opon.
            </p>
          </li>
          <li>
            <h4>
              Wyłączenie odpowiedzialności Najemcy w wyniku zużycia Pojazdu
              spowodowanego normalna eksploatacją:
            </h4>
            <p>
              Najemca nie ponosi odpowiedzialności za zużycie Przedmiotu Najmu,
              które wynika z jego normalnej eksploatacji. Przykładowymi
              sytuacjami mogą być: zużycie klocków hamulcowych czy zużycie opon.
            </p>
          </li>
          <li>
            <h4>Bieżąca kontrola płynów eksploatacyjnych przez Najemcę:</h4>
            <p>
              Najemca zobowiązuje się do systematycznej kontroli płynów
              eksploatacyjnych w Przedmiocie Najmu, w szczególności kontroli
              poziomu oleju silnikowego oraz płynu chłodniczego według wskazań
              urządzeń pomiarowych.
            </p>
          </li>
          <li>
            <h4>
              Zobowiązanie do dysponowania środkami finansowymi na usuwanie
              awarii powstałych podczas Najmu Pojazdu:
            </h4>
            <p>
              Najemca zobowiązuje się do dysponowania środkami finansowymi,
              pozwalającymi na usunięcie wszelkich awarii, które powstały w
              czasie użytkowania Przedmiotu Najmu, w szczególności awarii, w
              wyniku których dalsze kontynuowanie podróży jest niebezpieczne
              bądź niemożliwe. Najemca zobowiązany jest do odpowiedniego
              zabezpieczenia Przedmiotu Najmu.
            </p>
          </li>
          <li>
            <h4>Warunki zwrotu kosztów za naprawę pojazdu:</h4>
            <p>
              W przypadku napraw Przedmiotu Najmu, a wynikających z normalnej
              eksploatacji Wynajmujący zobowiązuje się zwrócić Najemcy koszty
              naprawy tylko za okazaniem faktury bądź rachunku wystawionego na
              Wynajmującego, pod warunkiem uprzedniego poinformowania
              Wynajmującego o konieczności dokonania naprawy i akceptacji przez
              Wynajmującego kosztów naprawy. W przypadku braku dokumentów
              rozliczeniowych oraz akceptacji przez Wynajmującego, koszty napraw
              pokrywa w całości Najemca.
            </p>
          </li>
          <li>
            <h4>
              Brak możliwości kontynuowania podróży Pojazdem, w wyniku awarii
              lub uszkodzenia spowodowanej normalną eksploatacją
            </h4>
            <p>
              Jeżeli awarii bądź uszkodzenia Przedmiotu Najmu spowodowanej
              normalną eksploatacją nie da się usunąć w krótkim czasie, wobec
              czego dalsze kontynuowanie jazdy nie będzie możliwe, Wynajmujący
              zobowiązuje się zwrócić Najemcy niewykorzystaną część opłaty za
              wynajęcie Przedmiotu Najmu za pełne doby pozostałe do końca
              trwania umowy. Najemca zobowiązuje się pokryć koszty powrotu na
              własny koszt. Z tego tytułu Najemcy nie przysługuje prawo
              otrzymania odszkodowania, na zasadach ogólnych.
            </p>
          </li>
          <li>
            <h4>
              Wyłączenie odpowiedzialności Wynajmującego za rzeczy pozostawione
              w Przedmiocie Najmu:
            </h4>
            <p>
              Wynajmujący nie ponosi odpowiedzialności za rzeczy Najemcy i osób
              trzecich, które Najemca i osoby trzecie, pozostawiły w Przedmiocie
              Najmu, np. w przypadku kradzieży pojazdu bądź włamania.
            </p>
          </li>
        </ol>
      </div>
      <div>
        <h4 className='text-center'>
          §9.
          <br />
          Obowiązki Najemcy w sytuacji uszkodzenia, kolizji lub wypadku
          drogowego z udziałem Przedmiotu Najmu, także wyrządzenia szkody osobom
          trzecim w związku z ruchem drogowym
        </h4>
        <ol>
          <li>
            <h4>Obowiązek niezwłocznego poinformowania Wynajmującego:</h4>
            <p>
              W przypadku każdego uszkodzenia Przedmiotu Najmu, Najemca
              zobowiązuje się do poinformowania Wynajmującego w trybie pilnym o
              zaistniałej sytuacji oraz do postępowania zgodnie z warunkami
              przewidzianymi w Ogólnych Warunkach Ubezpieczenia oraz wytycznymi
              Wynajmującego.
            </p>
          </li>
          <li>
            <h4>
              Obowiązek niezwłocznego poinformowania Policji, w przypadku innego
              kraju adekwatnych służb.
            </h4>
            <p>
              W przypadku każdego uszkodzenia Przedmiotu Najmu, Najemca
              zobowiązuje się do niezwłocznego powiadomienia Policji o zdarzeniu
              oraz uzyskania notatki służbowej sporządzonej przez
              funkcjonariusza Policji na miejscu zdarzenia. Gdy zdarzenie
              zajdzie w innym kraju niż Polska to należy się zwrócić do
              adekwatnych służb właściwych dla karu, w którym zdarzyło się
              zdarzenie.
            </p>
          </li>
          <li>
            <h4>Obowiązek zabezpieczenia Przedmiotu Najmu:</h4>
            <p>
              W przypadku każdego uszkodzenia Przedmiotu Najmu, Najemca
              zobowiązuje się zabezpieczyć Pojazd w sposób, który uniemożliwia
              powiększenie rozmiaru szkody.
            </p>
          </li>
          <li>
            <h4>
              Uzyskanie zgody na przeprowadzenie napraw lub/i modyfikacji
              Przedmiotu Najmu:
            </h4>
            <p>
              Najemca nie może dokonywać napraw ani modyfikacji Przedmiotu Najmu
              bez wyraźnej zgody Wynajmującego, uzyskanej przed podjęciem
              naprawy bądź modyfikacji.
            </p>
          </li>
          <li>
            <h4>Możliwość kontynuowania podroży:</h4>
            <p>
              W przypadku, gdy uszkodzenie Przedmiotu Najmu nie wpływa na
              bezpieczeństwo jazdy oraz nie skutkuje dalszym pogorszeniem stanu
              technicznego Przedmiotu Najmu, Najemca za wyraźną zgodą
              Wynajmującego może kontynuować podróż.
            </p>
          </li>
          <li>
            <h4>
              Postępowanie w przypadku nie uregulowania przez Najemcę mandatów i
              opłat zaparkowanie:
            </h4>
            <p>
              W celu uregulowania mandatów lub opłat za parkowanie, które
              Najemca jest zobowiązany zapłacić zgodnie z Umową Najmu (a ich nie
              uregulował), Wynajmujący ma prawo przekazać dane Najemcy do
              wszelkich organów Europejskich uprawnionym do pobierania tych
              mandatów.
            </p>
          </li>
        </ol>
      </div>
      <div>
        <h4 className='text-center'>
          §10.
          <br />
          Wydanie oraz zdanie Pojazdu
        </h4>
        <ol>
          <li>
            <h4>Instruktaż:</h4>
            <p>
              Najemca zobowiązuje się do odbycia instruktażu, który związany
              jest z eksploatacją Pojazdu przed jego odbiorem oraz potwierdzenia
              odbycia instruktażu w formie pisemnej, udokumentowanej podpisami
              Najemcy oraz Wynajmującego.
            </p>
          </li>
          <li>
            <h4>Brak Instruktażu:</h4>
            <p>
              W sytuacji, gdy z winy Najemcy nie doszło do odbycia się
              instruktażu, Wynajmujący ma prawo odmowy wydania Przedmiotu Najmu.
              Koszty ewentualnego opóźnienia związanego z wydaniem Przedmiotu
              Najmu ponosi w całości Najemca.
            </p>
          </li>
          <li>
            <h4>Zwrot Przedmiotu Najmu:</h4>
            <p>
              Najemca zobowiązuje się zwrócić Pojazd w określonym w Umowie
              terminie i nie ma możliwości przedłużenia Umowy bez zgody
              Wynajmującego. Zgoda Wynajmującego może być wyrażona również
              telefonicznie potwierdzona sms-em. Przekroczenie terminu skutkuje
              naliczeniem kary umownej w wysokości 100 zł za każdą rozpoczętą
              godzinę opóźnienia od ustalonego terminu. Pojazd ma zostać
              zwrócony wraz z pełnym zbiornikiem paliwa. W przypadku
              niespełnienia tego warunku Wynajmujący obciąży Najemcę kosztami
              uzupełnienia paliwa oraz karą umowną wskazaną w regulaminie.
            </p>
          </li>
          <li>
            <h4>
              Konsekwencje nie zwrócenia Przedmiotu Najmu w umówionym terminie:
            </h4>
            <p>
              W przypadku braku kontaktu z Wynajmującym ze strony Najemcy w
              ciągu 24 godzin od terminu ustalenia zdania Pojazdu, Wynajmujący
              poinformuje organa ścigania o podejrzeniu kradzieży, co oprócz
              całkowitej utraty kaucji skutkuje ewentualnymi dalszymi
              konsekwencjami finansowymi lub/i prawnymi.
            </p>
          </li>
        </ol>
      </div>
      <div>
        <h4 className='text-center'>
          §11.
          <br />
          IKIFRIKI FRIENDS
        </h4>
        <ol>
          <li>
            <h4>Przyznanie kodu:</h4>
            <p>
              Po zrealizowanej rezerwacji otrzymujesz unikalny kod przypisany do
              Twoich danych osobowych.
            </p>
          </li>
          <li>
            <h4>Okres ważności kodu:</h4>
            <p>
              Ważność kodu to 3 miesiące, natomiast każda rezerwacja dokonana z
              użyciem Twojego kodu przedłuża jego ważność o kolejne 3 miesiące.
            </p>
          </li>
          <li>
            <h4>Przekazanie kodu innej osobie:</h4>
            <p>
              Kod może być przekazywany dowolnej ilości osób. Każda z nich
              otrzyma 5% zniżki na wynajęcie pojazdu.
            </p>
          </li>
          <li>
            <h4>
              Stan Twojej skarbonki w przypadku skorzystania z kodu przez inne
              osoby
            </h4>
            <p>
              Z każdej zrealizowanej rezerwacji z wykorzystaniem Twojego kodu
              10% kwoty najmu odkłada się do twojej skarbonki Aktywnego kodu.
              Środki ze skarbonki możesz wykorzystać do pól roku od ostatniego
              wpływu, z tym że liczy się data rezerwacji Pojazdu.
            </p>
          </li>
          <li>
            <h4>Wyłączenie zniżek:</h4>
            <p>
              Zniżka nie obejmuje opcji dodatkowych, opłaty serwisowej oraz
              kaucji.
            </p>
          </li>
          <li>
            <h4>Wyłączenie łączenia się rabatów:</h4>
            <p>
              Rabat procentowy i rabat pieniężny ze skarbonki nie łączą się.
            </p>
          </li>
        </ol>
      </div>
      <div>
        <h4 className='text-center'>
          §12.
          <br />
          PRZETWARZANIE DANYCH OSOBOWYCH
          <br />W ZWIĄZKU Z REZERWACJĄ I UMOWĄ NAJMU POJAZDU
        </h4>
        <ul>
          <li>
            <strong>Grupa Dobra Sprawa Sp. z o.o.</strong> adres: ul. Prosta 36
            53-508 Wrocław NIP: 8943151001, KRS: 0000827342, adres email:
            kontakt@ikifriki.com, tel. kontaktowy: 519742598 -
            <strong>jako administrator danych osobowych</strong> (dalej
            administrator) przetwarzanych w związku z rezerwacją Pojazdu,
            zawarciem i realizacją umowy najmu POJAZDU, informuje, że zebrane
            dane osobowe przetwarza zgodnie z Rozporządzeniem Parlamentu
            Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w
            sprawie ochrony osób fizycznych w związku z przetwarzaniem danych
            osobowych i w sprawie swobodnego przepływu takich danych oraz
            uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie
            danych) (Dz. Urz. UE L 119/1) (dalej RODO).
          </li>
          <li>
            W celu skontaktowania się z administratorem w sprawach dotyczących
            ochrony danych osobowych oraz realizacji swoich praw w zakresie
            wynikającym z RODO - prosimy wysłać wiadomość na adres poczty
            elektronicznej kontakt@ikifriki.com lub listownie na adres
            administratora.
          </li>
          <li>
            Administrator w związku zawieraną umowa Najmu przetwarza dane
            osobowe w następujących celach:
            <ul>
              <li>
                w celu podjęcia działań niezbędnych do zawarcia umowy najmu
                POJAZDU i jej realizacji (podstawa prawna z art. 6 ust. 1 lit. b
                RODO);
              </li>
              <li>
                w celu wypełnienia obowiązków prawnych w związku z wykonaniem
                odpłatnej usługi na podstawie przepisów prawa podatkowego,
                rachunkowego (z art. 6 ust. 1 lit. c RODO);
              </li>
              <li>
                w celach archiwalnych (dowodowych) jako prawnie uzasadniony
                interes realizowany przez administratora dotyczącego
                zabezpieczenia informacji na wypadek prawnej potrzeby wykazania
                faktów, dochodzenia lub obrony przed roszczeniami, rozpatrywanie
                reklamacji, rękojmi- jeżeli się pojawią, (art. 6 ust. 1 lit. f
                RODO);
              </li>
              <li>
                w celach archiwalnych (dowodowych) jako prawnie uzasadniony
                interes realizowany przez administratora dotyczącego
                zabezpieczenia informacji na wypadek prawnej potrzeby wykazania
                faktów, dochodzenia lub obrony przed roszczeniami jeżeli się
                pojawią, (art. 6 ust. 1 lit. f RODO);
              </li>
              <li>
                na podstawie Twojej zgody (art. 6 ust. 1 lit a RODO) gdy
                wyrazisz dobrowolną zgodę na przesyłanie Ci drogą elektroniczną
                informacji o naszych promocjach i nowych ofertach. Prowadzenie
                akcji promocyjnych i marketingowych jest również naszym prawnie
                usprawiedliwionym interesem (art. 6 ust. 1 lit. f RODO).
              </li>
            </ul>
          </li>
          <li>
            Odbiorcami Twoich danych osobowych będą:
            <ul>
              <li>podmioty uprawnione na podstawie przepisów prawa;</li>
              <li>
                dostawcy usług płatniczych, a także banki, w przypadku
                konieczności prowadzenia rozliczeń;
              </li>
              <li>operatorzy pocztowi (w zakresie danych adresowych);</li>
              <li>
                podmioty świadczące usługi na nasze zlecenie, na postawie
                zwartych stosownych umów, w szczególności hosting,
                dostarczyciele narzędzi informatycznych, ubezpieczyciele,
                obsługa prawna;
              </li>
              <li>
                W celu uregulowania mandatów lub opłat za parkowanie, które
                Najemca jest zobowiązany zapłacić zgodnie z Umową Najmu (a ich
                nie uregulował). Wynajmujący ma prawo przekazać dane Najemcy do
                wszelkich organów Europejskich uprawnionych do pobierania tych
                mandatów.
              </li>
            </ul>
          </li>
          <li>
            Co do zasady administrator nie zamierza przekazywać Twoich danych do
            tzw. „państwa trzeciego”, tj. państwa, które znajduje się poza
            Europejskim Obszarem Gospodarczym lub organizacji międzynarodowej. Z
            uwagi jednak na korzystanie z narzędzi informatycznych dostarczanych
            przez naszych kontrahentów, dane mogą być przekazywane poza
            Europejski Obszar Gospodarczy, w wypadku braku stwierdzenia przez
            Komisje Europejską odpowiedniego stopnia ochrony przez dane państwo,
            transfer danych może odbywać się z wykorzystaniem typowych
            standardowych klauzul umownych zatwierdzonych przez Komisje
            Europejską lub innych właściwych mechanizmów prawnych w celu
            osiągnięcia zgodności z RODO. Przy czym administrator informuje o
            możliwości uzyskania kopii tych zabezpieczeń.
          </li>
          <li>
            Twoje dane osobowe przetwarzane w związku i na podstawie umowy najmu
            POJAZDU będziemy przetwarzać przez czas realizacji umowy, a po jej
            zakończeniu – do celów dokumentacyjnych, niezbędnych dla wykazania
            faktów w celach podatkowych - przez okres 5-ciu lat licząc od końca
            roku kalendarzowego, w którym powstał obowiązek podatkowy, z tym
            zastrzeżeniem, iż okres przechowywania Twoich danych osobowych może
            zostać każdorazowo przedłużony o okres przedawnienia roszczeń,
            jeżeli przetwarzanie danych osobowych będzie niezbędne dla
            dochodzenia roszczeń lub obrony przed roszczeniami. Po tym okresie
            Twoje dane osobowe będą usuwane. Jeżeli dokonałeś jedynie rezerwacji
            wstępnej i nie doszło do zawarcia umowy Twoje zostaną usunięte po 3
            dniach.
          </li>
          <li>
            Prawa jakie Tobie przysługują w związku z przetwarzaniem przez nas
            danych osobowych:
            <ul>
              <li>prawo dostępu do swoich danych oraz otrzymania ich kopii;</li>
              <li>prawo do sprostowania (poprawiania) swoich danych;</li>
              <li>
                prawo do usunięcia danych osobowych przetwarzanych
                bezpodstawnie,
              </li>
              <li>prawo do ograniczenia przetwarzania danych;</li>
              <li>prawo do przenoszenia danych;</li>
              <li>
                prawo do wniesienia sprzeciwu wobec przetwarzania danych - w
                sytuacji, gdy przetwarzany Twoje dane na podstawie prawnie
                uzasadnionego interesu realizowanego przez Administratora lub
                stronę trzecią - w takim przypadku przestaniemy przetwarzać
                Twoje dane w tych celach, chyba że będziemy w stanie wykazać, że
                w stosunku do tych danych istnieją dla nas ważne prawnie
                uzasadnione podstawy, które są nadrzędne wobec Twoich interesów,
                praw i wolności, lub dane będą nam niezbędne do ewentualnego
                ustalenia, dochodzenia lub obrony roszczeń. W przypadku gdy
                sprzeciw będzie dotyczył marketingu bezpośredniego, po jego
                wniesieniu nie będziemy już przetwarzać Twoich danych osobowych
                w tym celu.
              </li>
              <li>
                w przypadku, gdy podstawą przetwarzania danych osobowych będzie
                Twoja zgoda, przysługuje Ci prawo do jej wycofania w dowolnym
                momencie, bez wpływu na zgodność z prawem przetwarzania przed
                jej cofnięciem.
              </li>
            </ul>
          </li>
          <li>
            Gdy uznasz, iż przetwarzamy Twoje dane niezgodnie z prawem możesz
            skorzystać z prawa wniesienia skargi do Prezesa Urzędu Ochrony
            Danych Osobowych (adres: Urząd Ochrony Danych Osobowych, ul. Stawki
            2, 00 - 193 Warszawa).
          </li>
          <li>
            Przekazanie nam Twoich danych osobowych jest niezbędne do zawarcia
            umowy najmu POJAZDU. Niepodanie przez Ciebie danych osobowych
            spowoduje niemożność zawarcia i realizacji umowy najmu. Dane podane
            na podstawie zgody są podawane przez Ciebie dobrowolnie i nie wpłyną
            na zawarcie umowy. Możemy otrzymać od Ciebie dane osób, które mogą
            również kierować POJAZDEM podczas obowiązującej nas umowy. Podając
            ich dane, zakładamy, że osoby te wiedzą o tym i masz ich zgodę na
            podanie ich danych, a także zapoznały się z naszą Polityką
            Prywatności. Takie zobowiązanie wynika z zawartej przez nas umowy.
          </li>
          <li>
            Twoje dane osobowe nie będą wykorzystywane w celu zautomatyzowanego
            podejmowania decyzji (w tym w formie profilowania).
          </li>
        </ul>
      </div>
      <div>
        <h4 className='text-center'>
          §13.
          <br />
          Postanowienia końcowe
        </h4>
        <ol>
          <li>
            <h4>Stosowanie przepisów prawa:</h4>
            <p>
              W sprawach nieuregulowanych w niniejszym Regulaminie bądź Umowie
              stosuje się przepisy Kodeksu Cywilnego.
            </p>
          </li>
          <li>
            <h4>Rozpoznawanie sporów:</h4>
            <p>
              W celu rozpoznawania sporów, które mogą wynikać w związku z
              zawarciem i wykonaniem Umowy bądź przestrzeganiem niniejszego
              Regulaminu pomiędzy Wynajmującym a Najemcą niebędącym konsumentem,
              odpowiedni jest wyłącznie sąd właściwy dla siedziby Wynajmującego.
              Oświadczam, iż zapoznałem się z całością Regulaminu
              przedstawionego przez Wynajmującego oraz że akceptuję jego warunki
              bez zastrzeżeń.
            </p>
          </li>
          <li>
            <h4>Zmiany Regulaminu:</h4>
            <p>
              Regulamin obowiązuje od dnia 1.05.2023 Wynajmujący zastrzega sobie
              możliwość zmiany Regulaminu. Każda nowelizacja Regulaminu będzie
              publikowana co najmniej na 7 dni przed datą obowiązywania nowej
              wersji. Wszystkie Rezerwacje dokonane przed datą wejścia w życie
              nowej wersji Regulaminu będą dokonywane w oparciu o Regulamin
              obowiązujący w dniu dokonania rezerwacji.
            </p>
          </li>
        </ol>
      </div>
    </>
  );
};

export const TermsWebsite = () => {
  return (
    <div>
      <h3 className='text-center'>Regulamin serwisu</h3>
      <h4 className='text-center'>
        (Regulamin świadczenia usług drogą elektroniczną)
      </h4>
      <div>
        <ol>
          <li>
            <strong>Serwis</strong>: serwis internetowy www.ikifriki.pl
            prowadzony przez Grupa Dobra Sprawa sp. z o.o. świadczy usługi
            wymienione w Regulaminie
          </li>
          <li>
            <strong>Klient</strong>: osoba, która zarezerwowała Pojazd (camper),
            następnie zawarła umowę najmu z godnie z Regulaminem najmu Pojazdu
            (campera) lub osoba korzystająca z usług świadczonych drogą
            elektroniczną w ramach Serwisu;
          </li>
          <li>
            <strong>Konsument</strong>: konsument w rozumieniu przepisów Kodeksu
            Cywilnego;
          </li>
          <li>
            <strong>Umowa o świadczenie usługi drogą elektroniczną</strong>:
            umowa zawierana pomiędzy Tobą, a Grupa Dobra Sprawa sp. z o.o.,
            dotycząca wykonania usługi świadczonej bez jednoczesnej obecności
            stron (na odległość), poprzez przekaz danych na indywidualne żądanie
            Klienta, przesyłanej i otrzymywanej za pomocą urządzeń do
            elektronicznego przetwarzania, włącznie z kompresją cyfrową, i
            przechowywania danych, która jest w całości nadawana, odbierana lub
            transmitowana za pomocą sieci telekomunikacyjnej;
          </li>
          <li>
            <strong>Ustawa o prawach konsumenta</strong>: ustawa z dnia 30 maja
            2014 r. o prawach konsumenta;
          </li>
          <li>
            <strong>Kodeks Cywilny</strong>: ustawa z dnia 23 kwietnia 1964r.
            Kodeks Cywilny;
          </li>
          <li>
            <strong>Produkty</strong> : usługi i towary, które można zamawiać
            zgodnie z niniejszym Regulaminem; 
          </li>
          <li>
            <strong>Regulamin</strong> : niniejszy regulamin określający zasady
            rezerwacji Pojazdów (camperów) przy wykorzystaniu środków
            porozumiewania się na odległość oraz świadczenia usług drogą
            elektroniczną w ramach Serwisu; 
          </li>
          <li>
            Na potrzeby lepszego odbioru Regulaminu termin “Użytkownik” lub
            „Klient”, lub „Konsument” zastąpiony został określeniem “Ty”, “Grupa
            Dobra Sprawa sp. z o.o.” – “My”.
          </li>
          <li>
            Usługi oferowane przez nas w ramach usług świadczonych drogą
            elektroniczną w ramach serwisu:
            <ol type='a'>
              <li>
                możliwość rezerwacji Pojazdu (kampera) poprzez wypełnienie
                formularza,
              </li>
              <li>
                możliwość zamówienia rozmowy telefonicznej z konsultantem,
              </li>
              <li>
                możliwość połączenia się z konsultantem online w formie
                videorozmowy
              </li>
              <li>możliwość kontaktu za pomocą chatu,</li>
              <li>
                wyszukiwanie Przedmiotu Najmu przy wykorzystaniu wbudowanej w
                Serwisie wyszukiwarki,
              </li>
              <li>wyświetlanie zawartości Serwisu</li>
            </ol>
          </li>
          <li>
            Kanał kontaktu z nami pozostaje Twoim wyborem. Jeżeli wybierzesz
            opcje chatu, wówczas nasza korespondencja będzie zapisywana w formie
            w tej formie (jako wymiana informacji w raz z treścią takiej
            rozmowy), gdy wybierzesz formę kontaktu jako rozmowę telefoniczną i
            przekażesz nam w tym celu swój numer telefonu - oddzwonimy do
            Ciebie. Rozmowy telefoniczne z konsultantem są nagrywane, o czym
            dowiesz się także kiedy do Ciebie zadzwonimy, jeżeli nie zgodzisz
            się na nagrywanie poprosimy o zmianę kanału kontaktu. Videorozmowa
            będzie odbywała się online z możliwością jej nagrywania. Jeżeli
            rozmowa będzie dotyczyła przekazania istotnych informacji
            dotyczących Przedmiotu Najmu rozmowa będzie nagrywana, ale wcześniej
            poinformujemy Ciebie o wyłączeniu nagrywania, jeżeli nie wyrazisz
            zgody poprosimy o inna formę kontaktu, jeżeli podczas rozmowy
            włączysz kamerę będziemy również przetwarzać Twój wizerunek (ta
            decyzja należy wyłącznie do Ciebie, z naszej strony nie będziemy
            prosić o włączenie kamery). Rejestrowane chatu, nagrania zarówno te
            podczas rozmowy telefonicznej, jak i z videorozmowy będą służyły w
            celach dokumentowych, w związku z rękojmią jaką udzielamy na
            wynajmowane Pojazdy. Wprawdzie umowę Najmu Pojazdu zawieramy
            bezpośrednio (nie drogą elektroniczną), ale w celu uzyskania
            informacji o Przedmiocie Najmu i wstępnej rezerwacji możesz
            skorzystać z tej formy. Jeżeli przekazane przez nas informacje
            dotyczące Pojazdu okażą się niezgodne ze stanem faktycznym, możesz
            skorzystać z przysługujących Ci praw, dlatego nagrywania
            przechowujemy przez okres na jaki udzielamy rękojmi.
          </li>
          <li>
            Jeżeli chcesz zgłosić nam, że Pojazd, który Ci wynajęliśmy jest
            niezgodny z tym, co oferowaliśmy w naszym Serwisie, lub masz inne
            zastrzeżenia w stosunku do naszych usług, w szczególności tych
            świadczonych drogą elektroniczną możesz skorzystać z prawa
            reklamacji, w szczególności do rękojmi, także reklamowania w
            zakresie braku zgodności towaru z umową w stosunku do wynajętego
            Pojazdu wysyłając swoje zgłoszenie na adres reklamacje@ikifriki.com,
            tym kanałem możesz też zgłosić inne zastrzeżenia do oferowanych
            przez nas usług, w szczególności świadczonych drogą elektroniczną.
            Twoją reklamację rozpatrzymy w ciągu 14 dni.
          </li>
          <li>
            Usługi świadczone drogą elektroniczną udostępniane są w Serwisie
            nieodpłatnie.
          </li>
          <li>
            Umowa o świadczenie usług drogą elektroniczną zostaje zawarta w
            momencie rozpoczęcia świadczenia przez Grupę Dobra Sprawa sp. z o.o.
            usługi udostępnionej w Serwisie:
            <ol>
              <li>
                w przypadku usługi polegającej na wyświetlaniu zawartości
                Serwisu – z momentem wejścia na stronę internetową Serwisu,
              </li>
              <li>
                w przypadku usług świadczonych na wyraźne życzenie Klienta – z
                momentem wypełnienia i wysłania polecenia (np. usługa
                oddzwonienia, chatu lub videorozmowy)
              </li>
              <li>
                w przypadku usług, do świadczenia których wymagana jest
                rejestracja danych koniecznych dla rezerwacji Pojazdu – z
                momentem wypełnienia i wysłania odpowiedniego formularza. 
              </li>
            </ol>
          </li>
          <li>
            Umowa o świadczenie usług drogą elektroniczną ulega rozwiązaniu, w
            zależności od rodzaju danej usługi, z chwilą:
            <ol>
              <li>opuszczenia przez Klienta Serwisu, lub</li>
              <li>rezygnacji z danej usługi.</li>
            </ol>
          </li>
          <li>
            Serwis wraz z udostępnionymi w nim usługami świadczonymi drogą
            elektroniczną dostępny jest 7 dni w tygodniu, 24 godziny na dobę. Z
            uwagi na konieczność aktualizacji i niezbędnych przerw technicznych
            Funkcjonowanie Serwisu możliwe są krótkotrwałe przerwy, o których
            będziemy informować bezpośrednio w serwisie.
          </li>
          <li>
            Podczas korzystania z Serwisu, Zobowiązany jesteś jako Użytkownik do
            przestrzegania przepisów prawa oraz postanowień Regulaminu, w
            szczególności do przestrzegania zakazu dostarczania z użyciem
            środków komunikacji elektronicznej treści powodujących zakłócenie
            pracy lub przeciążenie systemów teleinformatycznych oraz
            naruszających dobra osób trzecich, także ogólnie przyjęte normy
            społeczne lub niezgodnych z powszechnie obowiązującymi przepisami
            prawa. Obowiązuje zakaz dostarczania przez usługobiorcę treści o
            charakterze bezprawnym.
          </li>
          <li>
            Wymagania techniczne niezbędne do współpracy z systemem
            teleinformatycznym, którym posługuje się Usługodawca:
            <table style={{ width: '350px' }}>
              <tbody>
                <tr>
                  <td>
                    <h2 style={{ textAlign: 'center' }}>
                      Lista wspieranych przeglądarek
                    </h2>
                  </td>
                </tr>
                <tr>
                  <td>
                    <table
                      align='center'
                      border='1'
                      style={{ maxWidth: '376px' }}
                    >
                      <tbody>
                        <tr>
                          <td></td>
                          <td bgcolor='#FFF'>
                            <strong>Windows</strong>
                          </td>
                          <td bgcolor='#FFF'>
                            <strong>Android</strong>
                          </td>
                          <td bgcolor='#FFF'>
                            <strong>APPLE MacOS</strong>
                          </td>
                          <td bgcolor='#FFF'>
                            <strong>APPLE iOS</strong>
                          </td>
                        </tr>
                        <tr>
                          <td bgcolor='#FFF'>
                            <strong>Chrome</strong>
                          </td>
                          <td bgcolor='#99ccff'>
                            <strong>TAK 85.0+</strong>
                          </td>
                          <td bgcolor='#99ccff'>
                            <strong>TAK 85.0+</strong>
                          </td>
                          <td bgcolor='#99ccff'>
                            <strong>TAK 85.0+</strong>
                          </td>
                          <td>
                            <strong>NIE</strong>
                          </td>
                        </tr>
                        <tr>
                          <td bgcolor='#FFF'>
                            <strong>Opera</strong>
                          </td>
                          <td bgcolor='#99ccff'>
                            <strong>TAK 67+</strong>
                          </td>
                          <td bgcolor='#99ccff'>
                            <strong>TAK 67+</strong>
                          </td>
                          <td bgcolor='#c4ecfd'>
                            <strong>NIE*</strong>
                          </td>
                          <td bgcolor='#FFF'>
                            <strong>NIE</strong>
                          </td>
                        </tr>
                        <tr>
                          <td bgcolor='#FFF'>
                            <strong>Firefox</strong>
                          </td>
                          <td bgcolor='#99ccff'>
                            <strong>TAK 81.0+</strong>
                          </td>
                          <td bgcolor='#c4ecfd'>
                            <strong>NIE*</strong>
                          </td>
                          <td bgcolor='#99ccff'>
                            <strong>TAK 81.0+</strong>
                          </td>
                          <td bgcolor='#FFF'>
                            <strong>NIE</strong>
                          </td>
                        </tr>
                        <tr>
                          <td bgcolor='#FFF'>
                            <strong>Edge</strong>
                          </td>
                          <td bgcolor='#99ccff'>
                            <strong>TAK 85.0+</strong>
                          </td>
                          <td bgcolor='#99ccff'>
                            <strong>TAK 85.0+</strong>
                          </td>
                          <td bgcolor='#c4ecfd'>
                            <strong>NIE*</strong>
                          </td>
                          <td bgcolor='#FFF'>
                            <strong>NIE</strong>
                          </td>
                        </tr>
                        <tr>
                          <td bgcolor='#FFF'>
                            <strong>Safari</strong>
                          </td>
                          <td bgcolor='#FFF'>
                            <strong>NIE</strong>
                          </td>
                          <td bgcolor='#FFF'>
                            <strong>NIE</strong>
                          </td>
                          <td bgcolor='#99ccff'>
                            <strong>TAK 13.1.2+</strong>
                          </td>
                          <td bgcolor='#99ccff'>
                            <strong>TAK 13.1.2+</strong>
                          </td>
                        </tr>
                        <tr>
                          <td bgcolor='#FFF' colSpan='5'>
                            *w zależności od wersji, połączenie może nie być
                            stabilne.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </li>
          <li>
            <strong>
              W celu prawidłowego korzystania z Serwisu niezbędne jest:
            </strong>
            <ol>
              <li>dostęp do komputera lub urządzenia mobilnego </li>
              <li>
                System operacyjny Windows 7 (lub nowszy) / macOS / iOS lub
                Android w aktualnej dystrybucji;
              </li>
              <li>
                Przeglądarka Google Chrome, Firefox, Safari lub Opera w
                ostatnich wydaniach na dzień podpisania umowy, o ile środowisko,
                na którym zainstalowano przeglądarkę dopuszcza taką możliwość;
              </li>
              <li>
                Łącze internetowe przynajmniej 1 mbps (stacjonarne lub mobilne);
              </li>
              <li>Dostęp urządzenia do mikrofonu</li>
              <li>Dostęp urządzenia do kamery (dla połączeń wideo).</li>
            </ol>
          </li>
          <li>
            Informacje dotyczące przetwarzania Twoich danych osobowych zawarte
            są w Polityce prywatności.
          </li>
          <li>
            Grupa Dobra Sprawa sp. z o.o. zastrzega sobie prawo wprowadzenia
            zmian do niniejszego Regulaminu. Informacja o zmianie Regulaminu
            zostanie przekazana Klientom w terminie 7 dni przed dniem wejścia w
            życie zmian poprzez zamieszczenie informacji w Serwisie.
          </li>
          <li>
            W zakresie nieuregulowanym mają zastosowanie przepisy prawa
            obowiązujące na terytorium Rzeczypospolitej Polskiej, w tym Kodeksu
            Cywilnego oraz Ustawy o prawach konsumenta.
          </li>
        </ol>
      </div>
    </div>
  );
};

export const TermsPrivacePolicy = () => {
  const handleRenewClick = () => {
    // Call the Cookiebot.renew() function or perform any other necessary action
    window.Cookiebot.renew();
  };
  return (
    <div>
      <h3 className='text-center'>POLITYKA PRYWATNOŚCI</h3>
      <h4 className='text-center'>
        § 1.
        <br />
        Postanowienia ogólne
      </h4>
      <ol>
        <li>
          Wypożyczalnia Pojazdów (camperów) IKIFRIKI jest administrowana przez
          firmę Grupa Dobra Sprawa sp. z o.o. Ochrona danych odbywa się zgodnie
          z wymogami powszechnie obowiązujących przepisów prawa, a ich
          przechowywanie ma miejsce na zabezpieczonych serwerach lub w siedzibie
          firmy, w zależności od sposobu zawarcia umowy
        </li>
        <li>
          Na potrzeby lepszego odbioru Polityki Prywatności termin “Użytkownik”
          lub „Klient” , lub „Konsument” zastąpiony został określeniem “Ty”,
          “Administrator” – “My”. Termin “RODO” oznacza Rozporządzenie
          Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016
          r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych
          osobowych i w sprawie swobodnego przepływu takich danych oraz
          uchylenia dyrektywy 95/46/WE.
        </li>
      </ol>
      <h4 className='text-center'>
        § 2.
        <br />
        Administrator Danych
      </h4>
      <ol>
        <li>
          Administratorem Twoich danych jest Grupa Dobra Sprawa Sp. z o.o.
          adres: ul. Prosta 36 53-508 Wrocław NIP: 8943151001, KRS: 0000827342,
          adres email: kontakt@ikifriki.com, tel. kontaktowy: 519742598. Oznacza
          to, że jeśli kontaktujesz się z nami za pomocą tej strony, to
          przetwarzamy Twoje dane takie jak: imię, nazwisko, adres e-mail, numer
          telefonu, (wpisane przez Ciebie do formularza rezerwacji), także dane
          gromadzone przez cookies np. adres IP, jak często bywasz na stronie,
          jakie podstrony odwiedzasz, (więcej informacji w Polityce cookies tj.
          § 7.). Jeżeli tak zdecydujesz, bo preferujesz bezpośredni kontakt z
          nami - połączymy się z Tobą przy pomocy chatu, który zainicjujesz lub
          telefonicznie - oddzwonimy na podany przez Ciebie numer telefonu lub
          połączymy się z Tobą za pomocą videorozmowy. Chat będziemy zapisywać,
          a rozmowę telefoniczną będziemy nagrywać, a gdy podczas videorozmowy
          będziesz chciał uzyskać istotne informacje dotyczące oferowanego przez
          nas Pojazdu również ją nagramy, uprzedzając ciebie, że będziemy
          nagrywać, nie będziemy jednak prosić o udostępnienie Twojego
          wizerunku, chyba że tak zdecydujesz i sam włączysz swoją kamerkę.
        </li>
        <li>
          W celu skontaktowania się z administratorem w sprawach dotyczących
          ochrony danych osobowych oraz realizacji swoich praw w zakresie
          wynikającym z RODO - prosimy wysłać wiadomość na adres poczty
          elektronicznej kontakt@ikifriki.com lub listownie na adres
          korespondencyjny administratora.
        </li>
        <li>
          Jeżeli chcesz zgłosić nam, że Pojazd, który Ci wynajęliśmy jest
          niezgody z tym co oferowaliśmy w naszym Serwisie, lub masz inne
          zastrzeżenia w stosunku do naszych usług, w szczególności tych
          świadczonych drogą elektroniczną możesz skorzystać z prawa reklamacji,
          w szczególności do rękojmi, także reklamowania w zakresie braku
          zgodności towaru z umową w stosunku do wynajętego Pojazdu wysyłając
          twoje zgłoszenie na adres reklamacje@ ikifriki.com, tym kanałem możesz
          też zgłosić inne zastrzeżenia do oferowanych przez nas usług, w
          szczególności świadczonych drogą elektroniczną. Twoją reklamację
          rozpatrzymy w ciągu 14 dni.
        </li>
        <li>
          Kontakt użytkownika z wypożyczalnią IKIFRIKI następuje za pomocą
          kanałów komunikacji – adres e-mail, numer telefonu wymienione na
          stronach serwisów ofertowych.
        </li>
      </ol>
      <h4 className='text-center'>
        § 3.
        <br />
        Pozyskanie danych, podstawa prawna i cel ich przetwarzania
      </h4>
      <ol>
        <li>
          Dane pozyskujemy bezpośrednio od Ciebie (kiedy korzystasz z naszego
          serwisu, wypełniasz formularz, rezerwujesz Pojazd (kampera) zawierasz
          umowę, lub wyrażasz zgodę. Podanie danych osobowych jest niezbędne do
          zawarcia umowy, ich niepodanie spowoduje niemożność zawarcia i
          realizacji umowy. Dane podane na podstawie zgody są podawane przez
          Ciebie dobrowolnie i nie wpłyną na zawarcie umowy. Możemy otrzymać od
          Ciebie dane osób, które mogą również kierować Pojazdem, zgodnie z
          Regulaminem Najmu Pojazdu podczas obowiązującej nas umowy. Podając ich
          dane, zakładamy, że osoby te wiedzą o tym i masz ich zgodę na podanie
          ich danych, a także zapoznały się z naszą Polityką Prywatności. Takie
          zobowiązanie wynika z zawartej przez nas umowy.
        </li>
        <li>
          W zakresie i celu niezbędnym do nawiązania, ukształtowania treści
          Umowy, zmiany bądź jej rozwiązania oraz prawidłowej realizacji Usług
          świadczonych drogą elektroniczną (podstawa prawna z art. 6 ust. 1 lit.
          b RODO).
        </li>
        <li>
          W zakresie i celu wypełnienia obowiązków prawnych w związku z
          wykonaniem odpłatnej usługi na podstawie przepisów prawa podatkowego,
          rachunkowego (podstawa prawna z art. 6 ust. 1 lit. c RODO).
        </li>
        <li>
          Podane dane osobowe w formularzu kontaktowym oraz przekazywane w
          ramach przesyłanej korespondencji przetwarzane będą w celu udzielenia
          odpowiedzi na zadane pytanie na podstawie art. 6 ust. 1 lit. f) RODO
          tj. w naszym prawnie uzasadnionym interesie;
        </li>
        <li>
          W celach archiwalnych (dowodowych) jako prawnie uzasadnionego interesu
          realizowanego przez Administratora dotyczącego zabezpieczenia
          informacji na wypadek prawnej potrzeby wykazania faktów, dochodzenia
          lub obrony przed roszczeniami, jeżeli się pojawią, (art. 6 ust. 1 lit.
          f RODO).
        </li>
        <li>
          Prowadzenie akcji promocyjnych i marketingowych jest również naszym
          prawnie usprawiedliwionym interesem (art. 6 ust. 1 lit. f RODO).
        </li>
        <li>
          W zakresie i celu zgodnym z wyrażoną przez Ciebie zgodą (podstawa
          prawna art. 6 ust. 1 lit a RODO) gdy wyrazisz dobrowolną zgodę na
          przesyłanie Ci drogą elektroniczną informacji o naszych promocjach i
          nowych ofertach (cel marketingowy), także na cookies zewnętrzne
          (zgodnie z Polityka cookies)
        </li>
        <li>
          Wszystkie zastrzeżone znaki towarowe i inne znaki usług należą do ich
          właścicieli i zostały użyte wyłącznie w celach informacyjnych dla
          zaprezentowania ofert.
        </li>
      </ol>
      <h4 className='text-center'>
        § 4.
        <br />
        Prawa osób
      </h4>
      <ol>
        <li>
          Każda osoba, której dane przetwarzamy ma określone prawa, które
          realizujemy w zakresie wynikającym z RODO, są to:
          <ol type='a'>
            <li>prawo dostępu do swoich danych oraz otrzymania ich kopii;</li>
            <li>prawo do sprostowania (poprawiania) swoich danych;</li>
            <li>
              prawo do usunięcia danych osobowych przetwarzanych bezpodstawnie,
            </li>
            <li>prawo do ograniczenia przetwarzania danych;</li>
            <li>
              prawo do przenoszenia danych - przysługuje, gdy podstawą
              przetwarzania jest Twoja zgoda lub umowa oraz przetwarzanie odbywa
              się w sposób zautomatyzowany;
            </li>
            <li>
              prawo do wniesienia sprzeciwu wobec przetwarzania danych - w
              sytuacji, gdy przetwarzany Twoje dane na podstawie prawnie
              uzasadnionego interesu realizowanego przez Administratora lub
              stronę trzecią – a Ty wniesiesz sprzeciw w takim przypadku
              przestaniemy przetwarzać Twoje dane w tych celach, chyba że
              będziemy w stanie wykazać, że w stosunku do tych danych istnieją
              dla nas ważne prawnie uzasadnione podstawy, które są nadrzędne
              wobec Twoich interesów, praw i wolności, lub dane będą nam
              niezbędne do ewentualnego ustalenia, dochodzenia lub obrony
              roszczeń. W przypadku gdy sprzeciw będzie dotyczył marketingu
              bezpośredniego, po jego wniesieniu nie będziemy już przetwarzać
              Twoich danych osobowych w tym celu.
            </li>
            <li>
              w przypadku, gdy podstawą przetwarzania danych osobowych będzie
              Twoja zgoda, przysługuje Ci prawo do jej wycofania w dowolnym
              momencie, bez wpływu na zgodność z prawem przetwarzania przed jej
              cofnięciem.
            </li>
          </ol>
        </li>
        <li>
          W celu wykonania wyżej wymienionych praw, lub uzyskania więcej
          informacji na temat Twoich praw skontaktuj się z nami mailowo pod
          adresem e-mail kontakt@ikifriki.com
        </li>
        <li>
          Gdy uznasz, iż przetwarzamy Twoje dane niezgodnie z prawem możesz
          wnieść skargę do Prezesa Urzędu Ochrony Danych Osobowych (adres: Urząd
          Ochrony Danych Osobowych, ul. Stawki 2, 00 - 193 Warszawa).
        </li>
        <li>
          Twoje dane osobowe nie będą wykorzystywane w celu zautomatyzowanego
          podejmowania decyzji (w tym w formie profilowania).
        </li>
      </ol>
      <h4 className='text-center'>
        § 5.
        <br />
        Odbiorcy danych
      </h4>
      <ol>
        <li>
          Podmioty, którym możemy udostępnić Twoje dane:
          <ol type='a'>
            <li>uprawnione na podstawie obowiązującego prawa;</li>
            <li>
              dostawcy usług płatniczych, a także banki, w przypadku
              konieczności prowadzenia rozliczeń;
            </li>
            <li>operatorzy pocztowi (w zakresie danych adresowych);</li>
            <li>
              podmioty świadczące usługi na nasze zlecenie, na postawie
              zawartych stosownych umów, w szczególności hosting, dostarczyciele
              narzędzi informatycznych, ubezpieczyciele, obsługa prawna;
            </li>
          </ol>
        </li>
        <li>
          Co do zasady Administrator nie zamierza przekazywać Twoich danych do
          tzw. „państwa trzeciego”, tj. państwa, które znajduje się poza
          Europejskim Obszarem Gospodarczym lub organizacji międzynarodowej. Z
          uwagi jednak na korzystanie z narzędzi informatycznych dostarczanych
          przez naszych kontrahentów, dane mogą być́ przekazywane poza teren
          Europejskiego Obszaru Gospodarczego- w przypadku braku stwierdzenia
          przez Komisję Europejską odpowiedniego stopnia ochrony przez dane
          państwo, transfer danych może odbywać się z wykorzystywaniem typowych
          standardowych klauzul umownych zatwierdzonych przez Komisję Europejską
          lub innych właściwych mechanizmów prawnych w celu osiągnięcia
          zgodności z RODO. Przy czym administrator informuje o możliwości
          uzyskania kopii tych zabezpieczeń. dostępu do danych, sprostowania,
          usunięcia lub ograniczenia przetwarzania, prawo sprzeciwu, prawo
          wniesienia skargi do organu nadzorczego.
        </li>
      </ol>
      <h4 className='text-center'>
        § 6.
        <br />
        Okres przechowywania danych osobowych
      </h4>
      <p>
        Okres przetwarzania danych zależy od celu, w jakim zostały zebrane i są
        przetwarzane, od przepisów prawa, metod lub modeli postępowania, a także
        zgód i innych oświadczeń. Dane przetwarzamy przez okres trwania celu
        przetwarzania. Każdorazowo osoba, której dane dotyczą jest informowana o
        czasie ich przechowywania (np. poprzez stosowne zapisy w umowach, na
        wnioskach, innej dokumentacji). Archiwizacja odbywa się zgodnie z
        wymaganiami przepisów prawa. Dane uzyskane za wyraźną zgodą osoby,
        której dotyczą, są użytkowane przez Grupa Dobra Sprawa sp. z o.o. przez
        czas wskazany w oświadczeniu o zgodzie, w każdym przypadku do czasu
        odwołania zgody lub ustania celu przetwarzania, jeżeli nastąpi
        wcześniej, bez wpływu na zgodność z prawem przetwarzania sprzed
        odwołania zgody. Twoje dane osobowe przetwarzane w związku z umową najmu
        Pojazdu będziemy przetwarzać przez czas realizacji umowy, a po jej
        zakończeniu – do celów prawno-podatkowych - przez okres 5-ciu lat licząc
        od końca roku kalendarzowego, w którym powstał obowiązek podatkowy. Z
        tym zastrzeżeniem, iż okres przechowywania Twoich danych osobowych może
        zostać każdorazowo przedłużony o okres przedawnienia roszczeń, jeżeli
        się pojawią (dochodzenie roszczeń lub obrona przed roszczeniami) do
        czasu zakończenia postępowań. Po tym okresie Twoje dane osobowe będą
        usuwane. Jeżeli dokonałeś jedynie rezerwacji wstępnej i nie doszło do
        zawarcia umowy Twoje dane będą usuwane po 3 dniach. Jeżeli skorzystałeś
        z kontaktu drogą elektroniczną za pomocą chatu, rozmowy telefonicznej
        lub videorozmowy (w przypadku jej nagrania) w celu uzyskania informacji
        o oferowanym przez nas Pojeździe wówczas dane będziemy przetwarzać przez
        okres możliwy na skorzystania z prawa do rękojmi, także stwierdzenia, że
        Przedmiot Najmu jest niezgodny z umową tj.
        <strong>
          2 lata od dnia wydania Pojazdu, chyba że ustalimy z Tobą dłuższy
          termin do prawa wynikającego z rękojmi, wówczas przez ten ustalony
          czas.
        </strong>
      </p>
      <h4 className='text-center'>
        § 7.
        <br />
        Pliki cookies
      </h4>
      <ol>
        <li>
          Strona www.ikifriki.pl używa cookies. Pliki cookies (tzw.
          „ciasteczka”) stanowią dane informatyczne, w szczególności pliki
          tekstowe, które przechowywane są w urządzeniu końcowym użytkownika
          Serwisu i przeznaczone są do korzystania ze stron internetowych
          Serwisu.
        </li>
        <li>
          Gromadzone informacje dotyczą adresu IP, typu wykorzystywanej
          przeglądarki, języka, rodzaju systemu operacyjnego, dostawcy usług
          internetowych, informacji o czasie i dacie, lokalizacji oraz
          informacji przesyłanych do witryny za pośrednictwem formularza
          kontaktowego.
        </li>
        <li>
          Zebrane dane służą do monitorowania i sprawdzenia, w jaki sposób
          użytkownicy korzystają z naszej witryny, aby usprawniać funkcjonowanie
          serwisu zapewniając bardziej efektywną i bezproblemową nawigację.
          Obecnie wykorzystujemy cookies zewnętrzne w celu zbierania ogólnych i
          anonimowych danych statycznych za pośrednictwem narzędzi analitycznych
          Google Analytics (administrator cookies zewnętrznego: Google Inc z
          siedzibą w USA). Informacje te są wykorzystywane przez nas do badania
          ilości odwiedzin witryny internetowej, ilości Użytkowników
          odwiedzających Serwis w określonych przedziałach czasu, a także na
          potrzeby dokonania oceny, które treści prezentowane w ramach Serwisu
          są najbardziej interesujące dla Użytkowników. Mogą pojawić się również
          marketingowe pliki cookie stosowane w celu śledzenia użytkowników na
          stronach internetowych. Celem jest wyświetlanie reklam, które są
          istotne i interesujące dla poszczególnych użytkowników i tym samym
          bardziej cenne dla wydawców i reklamodawców strony trzeciej.
        </li>
        <li>
          Stosujemy pliki cookies, aby zagwarantować najwyższy standard wygody
          naszego serwisu, a zebrane dane są wykorzystywane jedynie wewnątrz
          firmy Grupa Dobra Sprawa sp. z o.o. w celu optymalizacji działań.
        </li>
        <li>
          Do prawidłowego używania stron tworzone są i wykorzystywane pliki
          sesyjne oraz pliki stałe. Pliki sesyjne są plikami tymczasowymi,
          przechowywanymi na urządzeniu końcowym Użytkownika do czasu
          wylogowania się ze strony internetowej lub zamknięcia przeglądarki
          internetowej. Pliki stałe pozostają na urządzeniu końcowym Użytkownika
          przez czas zgodny z ustawieniami w pliku. Plik stały może być usunięty
          wcześniej z przeglądarki internetowej przez Użytkownika,
        </li>
        <li>
          Na stosowanie cookies potrzebujemy Twojej zgody, którą wyrazisz
          poprzez wybranie na banerze pojawiającym się na naszej stronie www.
          jedną z opcji: „zgoda na wszystko” (na wszystkie cookies) lub
          „spersonalizuj” czyli jest to opcja, w której wyrażasz zgodę na
          wybrane cookies zgodnie z Twoimi preferencjami lub możesz odmówić
          wyrażenia zgody na cookies wybierając opcję „odmawiam”. W każdej
          chwili możesz cofnąć wyrażoną zgodę lub zmienić swoje preferencje
          korzystając z zakładki: Zarządzaj cookies. Wycofanie zgody nie ma
          wpływu na zgodność z prawem przetwarzania sprzed wyrażenia zgody.
        </li>
        <li>
          Aktualnie używane cookies znajdziesz przechodząc do zakładki{' '}
          <a href='#' rel='noreferrer' onClick={handleRenewClick}>
            Zarządzaj plikami Cookies
          </a>
        </li>
      </ol>
    </div>
  );
};
