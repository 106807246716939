import React, { useState } from 'react';
import PageWrapperImageHead from '../component/UI/Wrappers/PageWrapper/PageWrapperImageHead';
import imgHero from '../assets/images/faqs/img_hero_faq.jpg';
import WrapperSectionStyledHeader from '../component/UI/Wrappers/WrapperSection/WrapperSectionStyledHeader';

const PageContactUs = () => {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const phoneNumberChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value);
  };
  const emailChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  return (
    <PageWrapperImageHead photo_brand={imgHero} pageTitle='Zadaj pytanie'>
      <div className='booking-form'>
        <WrapperSectionStyledHeader sectionName='Kontakt'>
          <div className='even-columns'>
            <div className='input-group__container'>
              <div className='input-group input-field input-field__large-width'>
                <input
                  type='tel'
                  id='phone'
                  className='input-group__input'
                  required
                  value={phoneNumber}
                  onChange={phoneNumberChangeHandler}
                />
                <label htmlFor='phone' className='input-group__label'>
                  Numer telefonu
                </label>
              </div>
              <div className='input-group input-field input-field__large-width'>
                <input
                  type='email'
                  id='email'
                  className='input-group__input'
                  required
                  value={email}
                  onChange={emailChangeHandler}
                />
                <label htmlFor='email' className='input-group__label'>
                  Email
                </label>
              </div>
              <div className='input-group input-field input-field__large-width'>
                <textarea
                  id='message'
                  className=' input-group__input textarea__group'
                ></textarea>
                <label htmlFor='message' className='input-group__label'>
                  Wiadomość
                </label>
              </div>
            </div>
            <div></div>
          </div>
        </WrapperSectionStyledHeader>
      </div>
    </PageWrapperImageHead>
  );
};

export default PageContactUs;
