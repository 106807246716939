import { ReactNode } from 'react';

type FormWrapperProps = {
  title: string;
  children: ReactNode;
};

const FormWrapper = ({ title, children }: FormWrapperProps) => {
  return (
    <>
      <h2 className='underline-header'>{title}</h2>
      <div className='padding-block-200'>{children}</div>
    </>
  );
};

export default FormWrapper;
