import React, { FormEvent, useState, useEffect } from 'react';
import { useMultiStepForm } from '../../../hooks/useMultiStepForm';
import UserForm from './StepFormContact';
import StepFormCarSelect from './StepFormCarSelect';
import './BookingForm.scss';
import StepFormAccesories from './StepFormAccesories';
import axios from 'axios';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import { selectFormData, setSeasonDays } from '../../../ducks/formSlice';
import { prepareCard } from '../../../helpers/helpers';
import StepFormDate from './StepFormDate';

import BookingFormCounterStepIcons from './BookingFormCounterStepIcons';
import Modal from '../../UI/Modal/Modal';
const BookingForm = () => {
  const dispatch = useAppDispatch();

  const {
    steps,
    currentStepIndex,
    step,
    isFirstStep,
    isLastStep,
    back,
    next,
    goTo,
  } = useMultiStepForm([
    <StepFormDate />,
    <StepFormCarSelect />,
    <StepFormAccesories />,
    <UserForm />,
  ]);

  const formData = useAppSelector(selectFormData);
  const [modalOpen, setModalOpen] = useState(false);
  const handleSetModalOpen = () => {
    setModalOpen(true);
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!isLastStep) {
      return next();
    }

    const card = prepareCard(formData);
    console.log(card);
    try {
      const response = await axios.post(
        'https://ikifriki.sigmacem-dev.stronazen.pl/webhook.php',
        card
      );
      if (response.status === 200) {
        handleSetModalOpen();
      } else {
        // Handle other status codes if needed
        console.log('Received status code:', response.status);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCalculateSeasonDays = () => {
    if (currentStepIndex === 1) {
      dispatch(setSeasonDays());
    }
  };
  return (
    <div className='booking-form'>
      {modalOpen && <Modal setOpenModal={setModalOpen} />}
      <form onSubmit={onSubmit}>
        <div className='booking-form__counter-steps'>
          {currentStepIndex + 1} / {steps.length}
        </div>
        <BookingFormCounterStepIcons
          currentStepIndex={currentStepIndex}
          goTo={goTo}
        />
        {step}
        <div
          style={{
            marginTop: '1rem',
            display: 'flex',
            gap: '.5rem',
            justifyContent: 'flex-end',
          }}
        >
          {isFirstStep && (
            <button
              type='button'
              className='btn btn-medium btn-outlined'
              onClick={back}
            >
              Wstecz
            </button>
          )}

          <button
            type='submit'
            className='btn btn-medium btn-primary'
            onClick={handleCalculateSeasonDays}
          >
            {isLastStep ? 'Wyślij' : 'Dalej'}
          </button>
          {/* <button type='button' onClick={handleSetModalOpen}>
            Pokaż modal
          </button> */}
        </div>
      </form>
    </div>
  );
};

export default BookingForm;
