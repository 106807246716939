import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import {
  selectFormStep1Data,
  setPickupAdress,
  setPickupData,
  setPickupTime,
  setReturnAdress,
  setReturnData,
  setReturnTime,
  setPersonAmount,
} from '../../../ducks/formSlice';
import FormWrapper from '../../UI/Wrappers/FormWrapper/FormWrapper';
import ScrollToTop from '../../../hoc/ScrollToTop';
import './StepFormDate.scss';

const StepFormDate: React.FC = () => {
  const dispatch = useAppDispatch();
  const step1 = useAppSelector(selectFormStep1Data);

  // Step 4 getter
  const [minimalRentRange, setMinimalRentRange] = useState<number>(3);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  // Validate next day as min
  const [minDate, setMinDate] = useState<string>('');
  const [minDateReturn, setMinDateReturn] = useState<string>('');

  useEffect(() => {
    const currentDate = new Date();
    const nextDay = new Date(currentDate);
    nextDay.setDate(currentDate.getDate() + 1);
    const nextDayFormatted = nextDay.toISOString().split('T')[0];
    setMinDate(nextDayFormatted);
  }, []);

  const checkPickupDateSeason = () => {
    const startingDate = new Date(step1.pickupDate);

    const month = startingDate.getMonth() + 1;
    const day = startingDate.getDate();

    if (
      (month >= 11 && month <= 12) ||
      month === 1 ||
      (month >= 2 && month <= 3)
    ) {
      setMinimalRentRange(3);
    } else if (
      month === 6 ||
      month === 7 ||
      month === 8 ||
      (month === 4 && day >= 28) ||
      (month === 5 && day <= 7)
    ) {
      setMinimalRentRange(5);
    } else {
      setMinimalRentRange(5);
    }
  };

  // Use useEffect to react to changes in minimalRentRange
  useEffect(() => {
    const minDate = step1.pickupDate ? new Date(step1.pickupDate) : new Date();
    minDate.setDate(minDate.getDate() + minimalRentRange);
    setMinDateReturn(minDate.toISOString().split('T')[0]);
  }, [minimalRentRange, step1.pickupDate]);

  const pickupDateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setPickupData(e.target.value));
    setStartDate(e.target.value);
    checkPickupDateSeason();
    dispatch(setReturnData(''));
  };

  const returnDateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setReturnData(e.target.value));
    setEndDate(e.target.value);
  };

  return (
    <FormWrapper title='Gdzie i kiedy'>
      <ScrollToTop />
      <div className='even-columns'>
        <div className='input-group__container'>
          <div className='input-group__container'>
            <div className='input-group input-field input-field__large-width'>
              <input
                type='text'
                id='pickup-place'
                className='input-group__input'
                required
                value={step1.pickupPlaceAdress}
                // Step 5
                onChange={(e) => dispatch(setPickupAdress(e.target.value))}
              />
              <label htmlFor='pickup-place' className='input-group__label'>
                Miejsce odbioru
              </label>
            </div>
            <div className='input-group__date input-field input-field__small-width'>
              <input
                type='date'
                min={minDate}
                value={step1.pickupDate}
                required
                onChange={(e) => pickupDateHandler(e)}
              />
              <label> Data odbioru</label>
            </div>
            <div className='input-group__time input-field input-field__small-width'>
              <input
                type='time'
                value={step1.pickupTime}
                required
                onChange={(e) => {
                  dispatch(setPickupTime(e.target.value));
                }}
              />
              <label>Godzina odbioru</label>
            </div>
          </div>
          <div className='input-group__container'>
            <div className='input-group input-field input-field__large-width'>
              <input
                type='text'
                id='return-place'
                className='input-group__input'
                required
                value={step1.returnPlaceAdress}
                onChange={(e) => dispatch(setReturnAdress(e.target.value))}
              />
              <label htmlFor='return-place' className='input-group__label'>
                Miejsce zwrotu
              </label>
            </div>
            <div className='input-group__date input-field input-field__small-width'>
              <input
                type='date'
                value={step1.returnDate}
                required
                min={minDateReturn}
                onChange={(e) => {
                  returnDateHandler(e);
                }}
              />
              <label>Data zwrotu</label>
            </div>
            <div className='input-group__time input-field input-field__small-width'>
              <input
                type='time'
                value={step1.returnTime}
                required
                onChange={(e) => dispatch(setReturnTime(e.target.value))}
              />
              <label>Godzina zwrotu</label>
            </div>
          </div>
          <div className='input-group__container'>
            <div className='input-group__select input-field input-field__large-width'>
              <select
                onChange={(e) => dispatch(setPersonAmount(e.target.value))}
                id='select-person-amount'
                value={step1.personAmount}
                required
              >
                <option value='1'>1</option>
                <option value='2'>2</option>
                <option value='3'>3</option>
                <option value='4'>4</option>
                <option value='5'>5</option>
                <option value='6'>6</option>
                <option value='7'>7</option>
                <option value='8'>8</option>
              </select>
              <label className='label' htmlFor='select-person-amount'>
                Liczba osób
              </label>
            </div>
          </div>
        </div>
        <div className='booking-info'>
          <h3>Warunki wynajmu:</h3>
          <ol>
            <li>
              Wydanie auta odbywa się na terenie Wrocławia, na ulicy Prostej 36,
              w godzinach 15-17, a zwrot auta do godziny 11. Jeśli chcesz
              odebrać auto w innym miejscu lub o innych godzinach, wybierz
              odpowiednią lokalizację i godzinę, która Cię interesuje. Postaramy
              się spełnić Twoje potrzeby (dodatkowo płatne 200 zł + 2 zł/km).
            </li>
            <li>
              Wypożyczenie dla osób powyżej 26 roku życia, z minimum 3 letnim
              prawem jazdy na kategorię B.
            </li>
          </ol>
        </div>
      </div>
    </FormWrapper>
  );
};

export default StepFormDate;
