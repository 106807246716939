import { Link } from 'react-router-dom';
import { DateFormatterProps, NumberWithThousandsProps } from '../types/types';
import React from 'react';

// format date yyyy/mm/dd => dd/mm/year
export const DateFormatter: React.FC<DateFormatterProps> = ({ date }) => {
  const formattedDate = typeof date === 'string' ? new Date(date) : date;

  const day = formattedDate.getDate().toString().padStart(2, '0');
  const month = (formattedDate.getMonth() + 1).toString().padStart(2, '0');
  const year = formattedDate.getFullYear();

  const formattedValue = `${day}/${month}/${year}`;

  return <span>{formattedValue}</span>;
};

const formatNumberWithThousands = (value: number): string => {
  return value
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    .replace('.', ',');
};

export const NumberWithThousands: React.FC<NumberWithThousandsProps> = ({
  value,
}) => {
  const formattedValue = formatNumberWithThousands(value);

  return <span>{formattedValue}</span>;
};

export const RenderDescriptionWithLinks = ({
  description,
}: {
  description: string;
}) => {
  const parts = description.split(
    /(\[url-poradnik-bezpieczenstwo\]|\[url-poradnik-co-spakowac\]|<Link to="\/poradniki" > Porady i wskazówki<\/Link>)/
  );
  console.log(parts);
  return (
    <React.Fragment>
      {parts.map((part, index) => {
        if (part === '[url-poradnik-bezpieczenstwo]') {
          return (
            <Link
              className='text-orange'
              key={index}
              to='/poradniki/bezpieczenstwo'
            >
              TUTAJ
            </Link>
          );
        } else if (part === '[url-poradnik-co-spakowac]') {
          return (
            <Link
              className='text-orange'
              key={index}
              to='/poradniki/co-spakowac'
            >
              tutaj
            </Link>
          );
        } else if (
          part === '<Link to="/poradniki" > Porady i wskazówki</Link>'
        ) {
          return (
            <Link className='text-orange' key={index} to='/poradniki'>
              Porady i wskazówki
            </Link>
          );
        } else {
          return <span key={index}>{part}</span>;
        }
      })}
    </React.Fragment>
  );
};
