import imgGuideChildrenPage from '../assets/images/guides/guide_children_photo.jpg';
import imgGuideFirstTimePage from '../assets/images/guides/guide_first_time_photo.jpg';
import imgGuideSafetyPage from '../assets/images/guides//guide_safety_photo.jpg';
import imgGuidePackPage from '../assets/images/guides/guide_pack_guide_photo.jpg';

import imgAdviceFirstTimeBefore00 from '../assets/images/guides/advices-first-time/img-advice-first-time-before-00.jpg';
import imgAdviceFirstTimeBefore01 from '../assets/images/guides/advices-first-time/img-advice-first-time-before-01.jpg';
import imgAdviceFirstTimeBefore02 from '../assets/images/guides/advices-first-time/img-advice-first-time-before-02.jpg';
import imgAdviceFirstTimeBefore03 from '../assets/images/guides/advices-first-time/img-advice-first-time-before-03.jpg';
//
import imgAdviceFirstTimeTrip00 from '../assets/images/guides/advices-first-time/img-advice-first-time-trip-00.jpg';
import imgAdviceFirstTimeTrip01 from '../assets/images/guides/advices-first-time/img-advice-first-time-trip-01.jpg';
import imgAdviceFirstTimeTrip02 from '../assets/images/guides/advices-first-time/img-advice-first-time-trip-02.jpg';
import imgAdviceFirstTimeTrip03 from '../assets/images/guides/advices-first-time/img-advice-first-time-trip-03.jpg';
const pagesList = [
  {
    pageName: 'Z dziećmi',
    pageUrl: 'poradniki/z-dziecmi',
    pageDescription:
      'Jeśli wybierasz się w podróż kamperem z dziećmi, warto zapoznać się z kilkoma poradami. Przygotowaliśmy wskazówki, które pomogą Ci zaplanować wyjazd. Dobra organizacja sprawi, że kempingowanie zostanie radosnym wspomnieniem na wiele lat.',
    pagePhoto: imgGuideChildrenPage,
  },
  {
    pageName: 'Pierwszy raz',
    pageUrl: 'poradniki/pierwszy-raz',
    pageDescription:
      'Wybierasz się na swoją pierwszą wycieczkę kamperem? Musisz wiedzieć kilka ważnych rzeczy. Zebraliśmy wszystkie w jednym miejscu, abyś mógł odkrywać świat wokół siebie, a niekoniecznie studiować zasady jazdy kamperem.',
    pagePhoto: imgGuideFirstTimePage,
  },
  {
    pageName: 'Bezpieczeństwo',
    pageUrl: 'poradniki/bezpieczenstwo',
    pageDescription:
      'Bezpieczeństwo w podróży jest kwestią oczywistą. Jednak, gdy wybieramy się na kempingowanie pojawiają się nowe aspekty, na które należy zwrócić uwagę. W tym poradniku podpowiadamy jak bezpiecznie podróżować kamperem. ',
    pagePhoto: imgGuideSafetyPage,
  },
  {
    pageName: 'Co spakować?',
    pageUrl: 'poradniki/co-spakowac',
    pageDescription:
      'Co należy ze sobą zabrać na podróż kamperem? Podpowiadamy jakie rzeczy mogą się przydać, a co może okazać się zbędnym bagażem. Dobra organizacja na pewno przełoży się na komfortowy i bezstresowy wypoczynek!  ',
    pagePhoto: imgGuidePackPage,
  },
];

export const pagesListAdvices = pagesList;
export const advicesFirstTime = [
  {
    adviceListID: '0',
    label: 'Przed podróżą',
    description:
      'Swoją wyprawę możesz zaplanować mniej lub bardziej szczegółowo - jak wolisz, nie ma tu sztywnej zasady. Przed wyjazdem należy jednak zrobić reaserch ogólnych zasad związanych z prowadzeniem i parkowaniem kampera w krajach, do których planujesz się wybrać. Sprawdź ograniczenia prędkości, zasady postoju kamperem i obecność pól kampingowych (są niezbędne do uzupełnienia zapasów wody i naładowania akumulatora w pochmurne dni, gdy panele solarne nie są efektywne). Dla własnego komfortu zainstaluj w smartfonie lub tablecie aplikacje, w których można znaleźć miejsca postojowe, na przykład Park4Night.\nPamiętaj też, że kamperem poruszasz się z mniejszą prędkością niż samochodem osobowym. Planując swoją trasę, dodaj do każdej godziny pokazywanej przez nawigację ok. 15-20 min, aby założone cele podroży były możliwe do zrealizowania.\nPrzed wyjazdem nie można zapomnieć o uzupełnieniu mediów takich jak prąd, woda i paliwo. Odbierając od nas kamper – media są w 100% uzupełnione, ale w trasie i drodze powrotnej pamiętaj, by je uzupełniać.',
    content: [
      {
        adviceNumber: 0,
        contentType: 'text',
        adviceName: 'Prowadzenie kampera',
        adviceInfo:
          'Aby móc prowadzić kamper wystarczy prawo jazdy kategorii B. Ze względu na jego gabaryty musisz zwracać szczególną uwagę na ograniczenia wysokości pojazdów w tunelach, na mostach i parkingach. Wąskie drogi też bywają zdradliwe, więc staraj się ich unikać.\n\nJadąc kamperem nie zapominaj o jego dużej masie – ma ona znaczący wpływ na hamowanie i przyśpieszanie. Zachowuj bezpieczną odległość od innych aut i staraj się unikać wyprzedzania, gdyż kamper to nie rakieta. Nie stresuj się - po kilku godzinach na pewno poczujesz odpowiedni „rytm” jazdy.',
        adviceImage: imgAdviceFirstTimeBefore00,
      },
      {
        adviceNumber: 1,
        contentType: 'text',
        adviceName: 'Prąd, woda, gaz...',
        adviceInfo:
          'Nasze kampery są wyposażone w panele solarne, które zasilają akumulator. Nie możemy jednak gwarantować, że energia z nich spełni Twoje zapotrzebowanie. Gdy zużycie prądu jest większe lub mamy pochmurną pogodę, należy wspomóc się ładowaniem akumulatora przez kabel. Możesz to zrobić na każdym kempingu - polecamy to robić przy każdej możliwej okazji.\n\nWodę w zbiornikach kampera również uzupełnisz na polach kempingowych. Możesz jej używać do prysznica, mycia rąk, zębów czy też naczyń. Mimo że dbamy o nasze auta bardziej niż o nas samych, to do gotowania zalecamy korzystanie z osobno kupowanej wody.\nOprócz zbiornika czystej wody w kamperze jest też osobny na wodę już zużytą - możesz go opróżnić tylko w miejscach specjalnie do tego przeznaczonych (np. na polach kempingowych).\n\nDostajesz od nas dwie pełne butle z gazem. Powinny wystarczyć na cały Twój wyjazd. Jeśli jednak okaże się, że nie - znajdź na trasie stację LPG, która umożliwia jej uzupełnienie. Poproś wykwalifikowaną osobę o pomoc, dla własnego bezpieczeństwa.',
        adviceImage: imgAdviceFirstTimeBefore01,
      },
      {
        adviceNumber: 2,
        contentType: 'text',
        adviceName: 'Pakowanie',
        adviceInfo:
          'Rzeczy niezbędne w kamperze mamy dla Ciebie na wyposażeniu. Są to między innymi przedłużacz, przejściówka do podłączenia prądu czy zastawa kuchenna. O to nie musisz się martwić.\n\nPakuj się mądrze – nie za dużo, nie za mało. Uważnie dobierz niezbędny bagaż, aby nie przekroczyć maksymalnej dozwolonej masy całkowitej pojazdu. Listę rzeczy, które warto ze sobą zabrać, przygotowaliśmy w osobnym poradniku, który znajdziesz [url-poradnik-co-spakowac] .',
        adviceImage: imgAdviceFirstTimeBefore02,
      },
    ],
  },
  {
    adviceListID: '1',
    label: 'Podróż',
    description:
      'Zanim ruszysz, przejdź się po aucie i sprawdź, czy wszystkie szafki są pozamykane, oraz czy nic nie stoi na kuchence albo na blatach. Dopilnuj, by wszyscy siedzieli na swoich miejscach zapięci w pasy. Pamiętaj o zakręceniu kranów i butli z gazem. Więcej o bezpiecznej podróży dowiesz się [url-poradnik-bezpieczenstwo] .\n\nOstrożnie wyjeżdżaj z parkingów, a jak masz taką możliwość - poproś pasażera o pomoc i kontrolowanie tego manewru. Nie śpiesz się i zwracaj uwagę na zdradliwe elementy infrastruktury takie jak betonowe słupki parkingowe czy nienaturalnie wysokie krawężniki.\n\nW trakcie jazdy ładuj swoje urządzenia elektroniczne, aby zawsze były w gotowości.\n\nNie zapominaj też o regularnym tankowaniu – lepiej mieć pełny bak niż stres wynikający z braku stacji benzynowej po drodze.',
    content: [
      {
        adviceNumber: 0,
        contentType: 'text',
        adviceName: 'Postój i nocleg',
        adviceInfo:
          'W pierwszej kolejności należy sprawdzić, czy możesz w danym miejscu zrobić legalny postój lub nocleg.\nJeśli zatrzymujesz się tylko na kempingach – nie musisz o tym myśleć.\nPostaw auto tak, żeby w miarę możliwości cieszyć się pięknym widokiem z okien – na plażę, morze, góry czy Bazylikę w Licheniu. Tak jak lubisz.\nZwróć uwagę, aby kamper stał poziomo. Zatrzymywanie się nawet na niewielkim przechyle będzie niekomfortowe i potencjalnie niebezpieczne.\nNie zapominaj o zamknięciu okien i drzwi na noc lub gdy opuszczasz kamper. ',
        adviceImage: imgAdviceFirstTimeTrip00,
      },
      {
        adviceNumber: 1,
        contentType: 'text',
        adviceName: 'Posiłki w kamperze',
        adviceInfo:
          'Gotuj i jedz śmiało w aucie. Nic tak nie smakuje, jak jajecznica z widokiem na górski horyzont lub szumiące morze. Uchyl okna, aby uniknąć zaparowania szyb. Nie należy przygotowywać w kamperze potraw mających intensywne zapachy, których ciężko się pozbyć. Zakazane jest też flambirowanie, ze względu na bezpieczeństwo.\n\nPo przygotowywaniu posiłków naczynia na pewno będą brudne. Możesz pozmywać je w kamperze, ale pamiętaj, że nie wolno wylewać do zlewu fusów od kawy lub innych stałych fragmentów. Grozi to niestety drogą wizytą u hydraulika. Zalecamy korzystanie z ogólnodostępnej kuchni/zmywalni - będzie Ci wygodniej i oszczędzisz wodę w swoim zbiorniku.',
        adviceImage: imgAdviceFirstTimeTrip01,
      },
      {
        adviceNumber: 2,
        contentType: 'text',
        adviceName: 'Komfort podróży',
        adviceInfo:
          'Po całym dniu na łonie natury – opłucz się przed wejściem do Campera. Będziesz mieć mniej piachu na podłodze, w łóżku, w zębach – generalnie wszędzie.\n\nPrzed wejściem do Campera trzep buty lub je zdejmuj – powody takie same, jak wyżej.\n\nW dni chłodne, na postoju zostawiaj włączone ogrzewanie – możesz je ustawić lub wybrać minimalną moc. Dzięki temu szybciej ogrzejesz się po pieszej wycieczce w deszczu lub zjeździe z narciarskiego stoku.\n\nLodówkę zostaw zawsze włączoną w trybie automatycznym, żeby to, co ma być zimne, zostało zimne.\n\nSprzęt sportowy, leżaki i „meble ogrodowe” zamykaj zawsze na noc w swoim tymczasowym garażu (to ten największy schowek).',
        adviceImage: imgAdviceFirstTimeTrip02,
      },
    ],
  },
];
