import React from 'react';
import PageWrapperImageHead from '../../component/UI/Wrappers/PageWrapper/PageWrapperImageHead';
import AdviceList from '../../component/Advices/AdviceList';
import heroImage from '../../assets/images/guides/guide-packing-hero.jpg';
// import adviceImage01 from '../../assets/images/guides/advices-packing/advice-packing-01.jpg';
import adviceImage02 from '../../assets/images/guides/advices-packing/advice-packing-02.jpg';
// import adviceImage03 from '../../assets/images/guides/advices-packing/advice-packing-03.jpg';
import guideChildrenPhoto from '../../assets/images/guides/guide_children_photo.jpg';
import guideFirstTimePhoto from '../../assets/images/guides/guide_first_time_photo.jpg';
import guideSafetyPhoto from '../../assets/images/guides//guide_safety_photo.jpg';
import OtherAdvicesBoxLinks from '../../component/Advices/OtherAdvicesBoxLinks';
const GuidePacking = () => {
  const adviceList = [
    {
      adviceNumber: 0,
      adviceName: 'Pamiętaj, aby zabrać ze sobą:',
      adviceInfoList: [
        'Dobry humor! Jasna sprawa, przejdźmy do konkretów 😀',
        'Dokumenty i gotówka obowiązująca w krajach, do których zmierzasz.',
        'Ubrania na gorące i chłodne dni, gdyż pogoda lubi zaskakiwać.',
        '2-3 pary wygodnych butów, w zależności od planów podróży. Jedna para to ryzykowne podejście.',
        'Kosmetyki i akcesoria higieniczne.',
        'Ręczniki, pościel, poduszki - jeśli nie chcesz od nas wypożyczać.',
        'Kocyk na plażę lub łąkę - zawsze się przydaje.',
        'Leki, apteczka pierwszej pomocy i suplementy – elektrolity i lek na biegunkę to coś, co lepiej mieć niż nie mieć. Nigdy nie wiesz, co zjesz, a problemy gastryczne lubią psuć wyjazdy.',
        'Zatyczki do uszu – lepiej się wyśpisz.',
        'Krem z filtrem – potrzebne nie tylko nad morzem, gdyż nawet górskie słońce może podrażnić naszą skórę.',
        'Coś przeciwdeszczowego lub parasolka.',
        'Ładowarki, powerbanki, słuchawki i inne dobrodziejstwa elektroniczne.',
        'Książki, gry planszowe i karty na spokojne wieczory.',
        'Kilka ulubionych zabawek dla dzieci – tu polecamy ograniczyć dziecko do na przykład jednego małego plecaczka, aby uniknąć nadmiarowego bagażu.',
        'Małe pudełka na jedzenie i przekąski, jeśli planujemy piesze wycieczki z własnym prowiantem.',
        'Mapy, jeśli nie ufasz swojemu GPS i połączeniu z internetem. Zwróć uwagę na rok ich wydruku. Możesz je również zakupić w trakcie drogi na przykład na stacjach benzynowych.',
        'Sprzęt sportowy, gdy planujesz aktywny wypoczynek.',
        'Ulubioną kawę, herbatę lub yerba mate – co wolisz. Zajmuje mało miejsca, nie psuje się i zawsze na postoju możesz delektować się swoim ulubionym napojem.',
        'Jeśli planujesz gotować, weź ze sobą ulubione przyprawy – zajmują mało miejsca i się nie psują. Szczególnie za granicą możesz mieć problem z ich dostępnością i kupnem – nawet jeśli są dostępne bywają bardzo drogie.',
      ],
      adviceImage: adviceImage02,
    },
  ];
  const otherAdvicePagesList = [
    {
      pageName: 'Z dziećmi',
      urlToPage: 'poradniki/z-dziecmi',
      pagePhoto: guideChildrenPhoto,
    },
    {
      pageName: 'Bezpieczeństwo',
      urlToPage: 'poradniki/bezpieczenstwo',
      pagePhoto: guideSafetyPhoto,
    },
    {
      pageName: 'Pierwszy raz',
      urlToPage: 'poradniki/pierwszy-raz',
      pagePhoto: guideFirstTimePhoto,
    },
  ];
  return (
    <PageWrapperImageHead pageTitle='Co spakować?' photo_brand={heroImage}>
      <AdviceList
        adviceList={adviceList}
        adviceSectionDescription={`Pakując się na podróż kamperem nie zapominaj, że less is more - mniej znaczy więcej.\n Stawiaj na praktyczność, zrób listę potrzebnych rzeczy jeszcze przed wyjazdem.\nPrzedmioty takie jak naczynia, czy też przewód do ładowania akumulatora (i wiele innych) mamy już na wyposażeniu. Większość masz z głowy.`}
      />
      <OtherAdvicesBoxLinks otherAdvicePages={otherAdvicePagesList} />
    </PageWrapperImageHead>
  );
};

export default GuidePacking;
