import React from 'react';
import PageWrapperImageHead from '../component/UI/Wrappers/PageWrapper/PageWrapperImageHead';
import LinkBoxPhoto from '../component/LinkBoxPhoto/LinkBoxPhoto';
import { pagesListAdvices } from '../constants/constantsAdvices';
import heroImage from '../assets/images/guides/img_hero_guides.jpg';

const Guides = () => {
  return (
    <PageWrapperImageHead
      photo_brand={heroImage}
      pageTitle='Porady i wskazówki'
    >
      <section>
        <div className='grid-advices'>
          {pagesListAdvices.map((p, idx) => {
            return (
              <LinkBoxPhoto
                key={idx}
                pageUrl={p.pageUrl}
                pageName={p.pageName}
                pageDescription={p.pageDescription}
                pagePhoto={p.pagePhoto}
              />
            );
          })}
        </div>
      </section>
    </PageWrapperImageHead>
  );
};

export default Guides;
