import React from 'react';
import PageWrapper from '../component/UI/Wrappers/PageWrapper/PageWrapper';
import CamperCard from '../component/CamperCard/CamperCard';
import { campersCards } from '../constants/constantsCampers';
import PageWrapperImageHead from '../component/UI/Wrappers/PageWrapper/PageWrapperImageHead';
import heroImage from '../assets/images/our-cars/img-hero-our-cars.jpg';
const OurCars = () => {
  return (
    <PageWrapperImageHead photo_brand={heroImage} pageTitle='Nasze kampery'>
      <section className=' padding-block-400'>
        <p className='center-text clr-text-dblue'>
          Nasze kampery oferują najwyższy komfort.
          <br /> Dbamy o ich pełną sprawność i funkcjonalność, więc
          przejedziecie nimi setki, a nawet tysiące kilometrów, <br />
          skupiając się wyłącznie na radości z odkrywania pięknych miejsc i
          przygodzie.
          <br />
          Wybierz model najbardziej pasujący do Twoich potrzeb:
        </p>
      </section>

      <section>
        <div className='grid-our-cars'>
          {campersCards.map((c, idx) => {
            return (
              <CamperCard
                key={idx}
                camperModel={c.camperModel}
                camperHirePrice={c.camperHirePrice}
                camperDescription={c.camperDescription}
                camperMainImage={c.camperMainImage}
                camperAmountBeds={c.camperAmountBeds}
                camperAmountPerson={c.camperAmountPerson}
                camperUrlShowMoreInfo={c.camperUrlShowMoreInfo}
              />
            );
          })}
        </div>
      </section>
    </PageWrapperImageHead>
  );
};

export default OurCars;
