import React from 'react';
import './Footer.scss';
import { docsPdfTerms } from '../../../constants/constants';
import { Link } from 'react-router-dom';
const Footer = () => {
  const year = new Date().getFullYear();
  const copyrightSymbol = '\u00A9'; // Unicode character for copyright symbol
  const copyrightText = `Copyright ${copyrightSymbol} ${year} Ikifriki`;
  const handleRenewClick = () => {
    // Call the Cookiebot.renew() function or perform any other necessary action
    window.Cookiebot.renew();
  };
  return (
    <footer className='footer'>
      <div className='footer-container container'>
        <div className='footer-branding padding-block-400'>
          <div className='footer-branding__logo'></div>
        </div>
        <div className='footer__row'>
          <div className='footer__column  padding-block-400'>
            <h4>Kontakt:</h4>
            <ul className='list'>
              <li className='footer-list-item'>
                <div className='footer-list-item-icon icon-phone'></div>
                <a href='tel:+48519742598' title='zadzwoń'>
                  +48 519 742 598
                </a>
              </li>
              <li className='footer-list-item'>
                <div className='footer-list-item-icon icon-message'></div>
                <a href='mailto:kontakt@ikifriki.com' title='napisz do nas'>
                  kontakt@ikifriki.com
                </a>
              </li>
              <li className='footer-list-item'>
                <div className='footer-list-item-icon icon-home icon-32'></div>
                <address>
                  <a
                    href='https://goo.gl/maps/KgdXYPUvr5552HMF9'
                    rel='noreferrer'
                    target='_blank'
                    title='pokaż lokalizację na mapie'
                  >
                    ul. Prosta 36, 53-508 Wrocław
                  </a>
                </address>
              </li>
              {/* <li className='footer-list-item'>
                <div className='footer-list-item-icon icon-send-message'></div>
                <Link to='/kontakt'>Napisz do nas</Link>
              </li>
              <li className='footer-list-item'>
                <div className='footer-list-item-icon icon-rate-us'></div>
                <Link to='/ocen-nas'>Oceń nas</Link>
              </li> */}
            </ul>
          </div>
          <div className='footer__column  padding-block-400'>
            <h4>Pomocne linki:</h4>
            <ul className='list'>
              <li className='footer-list-item'>
                <div className='footer-list-item-icon icon-rent-doc'></div>
                <Link to='/regulaminy'>Regulaminy</Link>
              </li>
              <li className='footer-list-item'>
                <span className='footer-list-item-icon  icon-web'></span>
                <a href={docsPdfTerms[0]} target='_blank' rel='noreferrer'>
                  Regulamin Serwisu
                </a>
              </li>
              <li className='footer-list-item'>
                <span className='footer-list-item-icon  icon-rent-doc'></span>
                <a href={docsPdfTerms[1]} target='_blank' rel='noreferrer'>
                  Regulamin najmu pojazdu
                </a>
              </li>

              <li className='footer-list-item'>
                <span className='footer-list-item-icon icon-pricing'></span>

                <a href={docsPdfTerms[2]} target='_blank' rel='noreferrer'>
                  Cennik
                </a>
              </li>
              <li className='footer-list-item'>
                <span className='footer-list-item-icon icon-privacy'></span>

                <a href={docsPdfTerms[3]} target='_blank' rel='noreferrer'>
                  Polityka prywatności
                </a>
              </li>
              <li className='footer-list-item'>
                <span className='footer-list-item-icon icon-cookies'></span>
                <a href='#' rel='noreferrer' onClick={handleRenewClick}>
                  Zarządzaj plikami Cookies
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className='center padding-block-200 opacity-400'>
        <small>
          {' '}
          Proudly developed by{' '}
          <a
            href='https://sigmacem.com/'
            target='_blank'
            rel='noopener noreferrer'
            className='white-text'
            title='Przejdź na stronę www.sigmacem.com'
          >
            Sigmacem Analytics
          </a>
          <br />
          {copyrightText}
        </small>
      </div>
    </footer>
  );
};

export default Footer;
