import React, { useState, useRef, useEffect } from 'react';
import './AdviceItem.scss';
import { RenderDescriptionWithLinks } from '../../helpers/helpersReturns';

interface AdviceItemProps {
  adviceImage: string;
  adviceNumber: string;
  adviceName: string;
  adviceInfo: string;
  adviceInfoList?: string[];
}

export const AdviceItem = (props: AdviceItemProps) => {
  const domRef = useRef<HTMLDivElement>(null); // Specify the type for useRef

  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setVisible(true);
        // Check if current is not null before unobserving
        if (domRef.current) {
          observer.unobserve(domRef.current);
        }
      }
    });

    // Check if current is not null before observing
    if (domRef.current) {
      observer.observe(domRef.current);
    }

    return () => {
      // Check if current is not null before unobserving for cleanup
      if (domRef.current) {
        observer.unobserve(domRef.current);
      }
    };
  }, []); // The dependency array is empty as it should only run on mount and unmount

  return (
    <div
      ref={domRef}
      className={isVisible ? 'is-visible advice-item' : 'advice-item'}
    >
      <div
        className='advice-item__image'
        style={{
          backgroundImage: `url(${props.adviceImage})`,
        }}
      ></div>
      <div className='advice-item__info'>
        <h3>{props.adviceName}</h3>
        {props.adviceInfo && (
          <p>
            <RenderDescriptionWithLinks description={props.adviceInfo} />
          </p>
        )}
        {props.adviceInfoList && (
          <ul className='list-with-marker'>
            {props.adviceInfoList.map((el, idx) => (
              <li key={idx}>{el}</li> // Removed explicit type annotation for map parameters
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
