import React from 'react';
import './Camper.scss';
import svgCamper from '../../../assets/icons/svg-camper.svg';
const Camper = () => {
  return (
    <div className='camper'>
      <div className='camper-main'>
        {/* <div className='window front-window'></div>
        <div className='window back-window'></div>
        <div className='wheel front-wheel'></div>
        <div className='wheel back-wheel'></div> */}
        <img src={svgCamper} />
      </div>
    </div>
  );
};

export default Camper;
