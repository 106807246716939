import React, { ReactNode } from 'react';

interface WrapperSectionStyledHeaderProps {
  sectionName: string;
  children: ReactNode;
}

const WrapperSectionStyledHeader: React.FC<WrapperSectionStyledHeaderProps> = ({
  sectionName,
  children,
}) => {
  return (
    <section>
      <div className='container'>
        <h2 className='underline-header'>{sectionName}</h2>
        {children}
      </div>
    </section>
  );
};

export default WrapperSectionStyledHeader;

//  <WrapperSectionStyledHeader sectionName=""></WrapperSectionStyledHeader>
