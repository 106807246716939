import imageCamperFordRoller266TL00 from '../assets/images/our-cars/camper-ford-roller-266tl/card-image-ford-roller-266tl-01.jpg';
import imageCamperFordRoller266TL01 from '../assets/images/our-cars/camper-ford-roller-266tl/card-image-ford-roller-266tl-02.jpg';
import imageCamperFordRoller266TL02 from '../assets/images/our-cars/camper-ford-roller-266tl/card-image-ford-roller-266tl-03.jpg';
import imageCamperFordRoller266TL03 from '../assets/images/our-cars/camper-ford-roller-266tl/card-image-ford-roller-266tl-04.jpg';
import imageCamperFordRoller266TL04 from '../assets/images/our-cars/camper-ford-roller-266tl/card-image-ford-roller-266tl-05.jpg';
import imageCamperFordRoller266TL05 from '../assets/images/our-cars/camper-ford-roller-266tl/card-image-ford-roller-266tl-06.jpg';
import imageCamperFordRoller266TL06 from '../assets/images/our-cars/camper-ford-roller-266tl/card-image-ford-roller-266tl-07.jpg';
import imageCamperFordRoller266TL07 from '../assets/images/our-cars/camper-ford-roller-266tl/card-image-ford-roller-266tl-08.jpg';
import imageCamperFordRoller266TL08 from '../assets/images/our-cars/camper-ford-roller-266tl/card-image-ford-roller-266tl-09.jpg';
import imageCamperFordRoller266TL09 from '../assets/images/our-cars/camper-ford-roller-266tl/card-image-ford-roller-266tl-10.jpg';
import imageCamperFordRoller266TL10 from '../assets/images/our-cars/camper-ford-roller-266tl/card-image-ford-roller-266tl-11.jpg';
import imageCardFordRoller266TL from '../assets/images/our-cars/image-card-ford-roller-266tl.jpg';

//second
import imageCamperFordRoller29800 from '../assets/images/our-cars/camper-ford-roller-298/image-camper-ford-roller-298-00.jpg';
import imageCamperFordRoller29801 from '../assets/images/our-cars/camper-ford-roller-298/image-camper-ford-roller-298-01.jpg';
import imageCamperFordRoller29802 from '../assets/images/our-cars/camper-ford-roller-298/image-camper-ford-roller-298-02.jpg';
import imageCamperFordRoller29803 from '../assets/images/our-cars/camper-ford-roller-298/image-camper-ford-roller-298-03.jpg';
import imageCamperFordRoller29804 from '../assets/images/our-cars/camper-ford-roller-298/image-camper-ford-roller-298-04.jpg';
import imageCamperFordRoller29805 from '../assets/images/our-cars/camper-ford-roller-298/image-camper-ford-roller-298-05.jpg';
import imageCamperFordRoller29806 from '../assets/images/our-cars/camper-ford-roller-298/image-camper-ford-roller-298-06.jpg';
import imageCamperFordRoller29807 from '../assets/images/our-cars/camper-ford-roller-298/image-camper-ford-roller-298-07.jpg';
import imageCamperFordRoller29808 from '../assets/images/our-cars/camper-ford-roller-298/image-camper-ford-roller-298-08.jpg';
import imageCamperFordRoller29809 from '../assets/images/our-cars/camper-ford-roller-298/image-camper-ford-roller-298-09.jpg';
import imageCamperFordRoller29810 from '../assets/images/our-cars/camper-ford-roller-298/image-camper-ford-roller-298-10.jpg';
import imageCamperFordRoller29811 from '../assets/images/our-cars/camper-ford-roller-298/image-camper-ford-roller-298-11.jpg';
import imageCamperFordRoller29812 from '../assets/images/our-cars/camper-ford-roller-298/image-camper-ford-roller-298-12.jpg';

//third
import imageCamperHoronOvercab71M00 from '../assets/images/our-cars/camper-horon-overcab-71m/image-camper-horon-overcab-71m-00.jpg';
import imageCamperHoronOvercab71M01 from '../assets/images/our-cars/camper-horon-overcab-71m/image-camper-horon-overcab-71m-01.jpg';
import imageCamperHoronOvercab71M02 from '../assets/images/our-cars/camper-horon-overcab-71m/image-camper-horon-overcab-71m-02.jpg';
import imageCamperHoronOvercab71M03 from '../assets/images/our-cars/camper-horon-overcab-71m/image-camper-horon-overcab-71m-03.jpg';
import imageCamperHoronOvercab71M04 from '../assets/images/our-cars/camper-horon-overcab-71m/image-camper-horon-overcab-71m-04.jpg';
import imageCamperHoronOvercab71M05 from '../assets/images/our-cars/camper-horon-overcab-71m/image-camper-horon-overcab-71m-05.jpg';
import imageCamperHoronOvercab71M06 from '../assets/images/our-cars/camper-horon-overcab-71m/image-camper-horon-overcab-71m-06.jpg';
import imageCamperHoronOvercab71M07 from '../assets/images/our-cars/camper-horon-overcab-71m/image-camper-horon-overcab-71m-07.jpg';
import imageCamperHoronOvercab71M08 from '../assets/images/our-cars/camper-horon-overcab-71m/image-camper-horon-overcab-71m-08.jpg';
const campersDetailsList = [
  {
    camperId: '0',
    camperModel: 'Ford Roller Team 266TL',
    camperTransmitionType: 'manualna',
    camperEnginePower: '170',
    camperLength: '745',
    camperWidth: '235',
    camperHeight: '340',
    camperWeight: '3115',
    camperCapacity: '385',
    camperAmountBeds: '4',
    camperAmountPerson: '5',
    chassisType: 'FORD Transit 2.0 cm EURO 6D',
    camperMainImage: imageCamperFordRoller266TL00,
    camperUrlShowMoreInfo: 'kampery/ford-roller-266tl',
    camperManufactureYear: '2018',
    camperDescription:
      'Komfortowy kamper 2+2. Wydzielone zamykanymi drzwiami trzy strefy: sypialnia, strefa łazienka/WC, strefa dzienna - pełny komfort podróży. W drogę ruszasz zdezynfekowanym i zadbanym autem, wyposażonym w meble ogrodowe, dwie butle z gazem (zapas na przynajmniej tydzień) oraz możliwością wypożyczenia hulajnogi elektrycznej, SUP’a lub innych opcji dodatkowych umilających Twoją wycieczkę.',
    camperHirePrice: [330, 440, 640],
    camperEquipment: [
      {
        id: 0,
        typeAccordion: 'list',
        header: 'Salon',
        list: [
          {
            listItem: 'Jadalnia z wygodnymi fotelami',
          },
          {
            listItem: 'Duże okna z moskitierami',
          },
          {
            listItem: 'Duży blat stołu',
          },
          {
            listItem: 'Meble z jasnego drewna',
          },
          {
            listItem: 'Obrotowe fotele w kabinie',
          },
          {
            listItem:
              'Podnoszone elektryczne łóżko z wygodnym materacem, drabinką oraz zabezpieczeniem przed wypadnięciem',
          },
        ],
      },
      {
        id: 1,
        typeAccordion: 'list',
        header: 'Kuchnia',
        list: [
          {
            listItem:
              'Pojemna lodówka z zamrażalnikiem, zasilanie 230V/12V/gaz',
          },
          { listItem: 'Płyta gazowa trzypalnikowa ze szklana pokrywą' },
          {
            listItem:
              'Pełna zabudowa kuchni z zabezpieczeniem przed otwieraniem podczas jazdy oraz matami antypoślizgowymi w szafkach',
          },

          {
            listItem:
              'Kompletne wyposażenie kuchni dla 4 osób- naczynia, garnki, sztućce itp.',
          },
        ],
      },
      {
        id: 2,
        typeAccordion: 'list',
        header: 'Główna sypialnia',
        list: [
          {
            listItem: 'Oddzielona drzwiami od części dziennej',
          },
          {
            listItem:
              'Komfortowe łóżko na stelażu 190x150cm z oddychającym materacem',
          },
          {
            listItem: 'Oświetlenie do czytania z półką na smartfony i tablety',
          },
          {
            listItem:
              'Boczne kieszenie i szafy ( również na wieszaki) do przechowywania',
          },
          {
            listItem: 'Telewizor z tunerem cyfrowym',
          },
        ],
      },
      {
        id: 3,
        typeAccordion: 'list',
        header: 'Łazienka',
        list: [
          {
            listItem: 'Wydzielona strefa łazienkowa i WC',
          },
          {
            listItem:
              'Drewniane meble z pojemnymi szafkami i oświetlonym lustrem',
          },
          {
            listItem: 'Duży prysznic posiadający sztywne drzwiczki',
          },
          {
            listItem: 'Świetlik dachowy',
          },
        ],
      },
      {
        id: 4,
        typeAccordion: 'list',
        header: 'Wyposażenie pokładowe',
        list: [
          {
            listItem: 'Panel zarządzania pojazdem',
          },
          {
            listItem: 'Grzejnik obwodowy',
          },
          {
            listItem: 'Ogrzewanie Truma Combi 4 gaz',
          },
          {
            listItem: 'Oświetlenie LED',
          },
          {
            listItem: 'Nocne oświetlenie podłogowe LED pod meblościankami',
          },
          {
            listItem: 'Gniazdo USB w części dziennej oraz sypialnej',
          },
          {
            listItem: 'Gniazdko elektryczne 230V',
          },
          {
            listItem: 'Lusterka wsteczne elektryczne z odmrażaniem',
          },
          {
            listItem: 'Ręczny system klimatyzacji',
          },
          {
            listItem: 'Kamera cofania',
          },
          {
            listItem: 'Tempomat',
          },
        ],
      },
      {
        id: 5,
        typeAccordion: 'list',
        header: 'Z zewnątrz',
        list: [
          {
            listItem: 'Drzwi wejściowe z oknem stałym oraz moskitierą',
          },
          {
            listItem: 'Koła stalowe 16” (FORD)',
          },
          {
            listItem: 'Niski maxi schowek na dwie butle gazowe',
          },
          {
            listItem: 'Garaż XXL o maksymalnej ładowności 300kg',
          },
          {
            listItem: 'Solar 140W',
          },
          {
            listItem: 'Markiza',
          },
          {
            listItem: 'Bagażnik rowerowy',
          },
        ],
      },
    ],
    imageGalery: [
      {
        id: 0,
        urlImg: imageCamperFordRoller266TL00,
        altTextImg: '',
      },
      {
        id: 1,
        urlImg: imageCamperFordRoller266TL01,
        altTextImg: '',
      },
      {
        id: 2,
        urlImg: imageCamperFordRoller266TL02,
        altTextImg: '',
      },
      {
        id: 3,
        urlImg: imageCamperFordRoller266TL03,
        altTextImg: '',
      },
      {
        id: 4,
        urlImg: imageCamperFordRoller266TL04,
        altTextImg: '',
      },
      {
        id: 5,
        urlImg: imageCamperFordRoller266TL05,
        altTextImg: '',
      },
      {
        id: 6,
        urlImg: imageCamperFordRoller266TL06,
        altTextImg: '',
      },
      {
        id: 7,
        urlImg: imageCamperFordRoller266TL07,
        altTextImg: '',
      },
      {
        id: 8,
        urlImg: imageCamperFordRoller266TL08,
        altTextImg: '',
      },
      {
        id: 9,
        urlImg: imageCamperFordRoller266TL09,
        altTextImg: '',
      },
      {
        id: 10,
        urlImg: imageCamperFordRoller266TL10,
        altTextImg: '',
      },
    ],
  },
  {
    camperId: '1',
    camperModel: 'Ford Roller Team 298',
    camperTransmitionType: 'automatyczna',
    camperUrlShowMoreInfo: 'kampery/ford-roller-298',
    camperEnginePower: '170',
    camperLength: '734',
    camperWidth: '235',
    camperHeight: '330',
    camperWeight: '3110',
    camperCapacity: '525',
    camperAmountBeds: '4',
    camperAmountPerson: '5',
    camperManufactureYear: '2021',
    chassisType: 'FORD Transit 2.0cm EURO 6D',
    camperDescription:
      'Komfortowy Kamper 2+2 z wygodnym łóżkiem małżeńskim, wydzieloną dużą łazienką (oddzielny zlewozmywak/kabina/WC) i dużą kuchnią. W drogę ruszasz zdezynfekowanym i pachnącym nowością autem, wyposażonym w meble ogrodowe, dwie butle z gazem (zapas na przynajmniej tydzień).',
    camperMainImage: imageCamperFordRoller29800,
    camperHirePrice: [330, 440, 640],
    camperEquipment: [
      {
        id: 0,
        typeAccordion: 'list',
        header: 'Salon',
        list: [
          {
            listItem: 'Jadalnia z wygodnymi fotelami',
          },
          {
            listItem: 'Duże okna z moskitierami',
          },
          {
            listItem: 'Duży jasny blat stołu',
          },
          {
            listItem: 'Meble z ciemnego drewna',
          },
          {
            listItem:
              'Podnoszone elektryczne łóżko z wygodnym materacem, drabinką oraz zabezpieczeniem przed wypadnięciem',
          },
          {
            listItem: 'Obrotowe fotele w kabinie',
          },
        ],
      },
      {
        id: 1,
        typeAccordion: 'list',
        header: 'Kuchnia',
        list: [
          {
            listItem:
              'Pojemna lodówka z zamrażalnikiem, zasilanie 230V/12V/gaz',
          },
          { listItem: 'Płyta gazowa trzypalnikowa ze szklana pokrywą' },
          {
            listItem:
              'Pełna zabudowa kuchni z zabezpieczeniem przed otwieraniem podczas jazdy oraz matami antypoślizgowymi w szafkach',
          },

          {
            listItem:
              'Kompletne wyposażenie kuchni dla 4 osób- naczynia metalowe, szklane, sztućce itp.',
          },
        ],
      },
      {
        id: 2,
        typeAccordion: 'list',
        header: 'Główna sypialnia',
        list: [
          {
            listItem:
              'Komfortowe łóżko na stelażu 190x150cm z oddychającym materacem',
          },
          {
            listItem:
              'Oświetlenie umożliwiające np. czytanie późną porą z półka na smartfony oraz tablety',
          },
          {
            listItem: 'Okno dachowe',
          },
          {
            listItem:
              'Boczne kieszenie i szafy (również na wieszaki) do przechowywania',
          },
          {
            listItem: 'Lustro podświetlone LED',
          },
          {
            listItem:
              'System nagłośnienia zintegrowany z głównym radiem w kabinie kierowcy',
          },
        ],
      },
      {
        id: 3,
        typeAccordion: 'list',
        header: 'Łazienka',
        list: [
          {
            listItem: 'Wydzielona strefa łazienkowa i WC',
          },
          {
            listItem:
              'Drewniane meble z pojemnymi szafkami i oświetlonym lustrem',
          },
          {
            listItem: 'Duży prysznic posiadający sztywne drzwiczki',
          },
          {
            listItem: 'Świetlik dachowy',
          },
        ],
      },
      {
        id: 4,
        typeAccordion: 'list',
        header: 'Wyposażenie pokładowe',
        list: [
          {
            listItem: 'Panel zarządzania pojazdem',
          },
          {
            listItem: 'Grzejnik obwodowy',
          },
          {
            listItem: 'Ogrzewanie Truma Combi 4 gaz',
          },
          {
            listItem: 'Oświetlenie ledowe',
          },
          {
            listItem: 'Nocne oświetlenie podłogowe LED na obwodzie podłogi',
          },
          {
            listItem: 'Gniazdo USB w części dziennej oraz sypialnej',
          },
          {
            listItem: 'Gniazdko elektryczne 230V',
          },
          {
            listItem: 'Kamera cofania',
          },
          {
            listItem: 'Tempomat',
          },
          {
            listItem: 'Ręczny system klimatyzacji',
          },

          {
            listItem: 'Lusterka wsteczne elektryczne z odmrażaniem',
          },
          {
            listItem: 'Zabezpieczenie przed zamarzaniem wody w Trumie',
          },
        ],
      },
      {
        id: 5,
        typeAccordion: 'list',
        header: 'Z zewnątrz',
        list: [
          {
            listItem: 'Drzwi wejściowe z oknem stałym oraz moskitierą',
          },
          {
            listItem: 'Koła stalowe 16”',
          },
          {
            listItem: 'Niski maxi schowek na dwie butle gazowe',
          },
          {
            listItem: 'Garaż XXL o maksymalnej ładowności 300 kg',
          },
          {
            listItem: 'Solar 140W',
          },
          {
            listItem: 'Markiza',
          },
          {
            listItem:
              'Zewnętrzny głośnik, zintegrowany z głównym radiem w kabinie kierowcy',
          },
          {
            listItem: 'Bagażnik rowerowy ',
          },
        ],
      },
    ],
    imageGalery: [
      {
        id: 0,
        urlImg: imageCamperFordRoller29800,

        altTextImg: '',
      },
      {
        id: 1,
        urlImg: imageCamperFordRoller29801,

        altTextImg: '',
      },
      {
        id: 2,
        urlImg: imageCamperFordRoller29802,

        altTextImg: '',
      },
      {
        id: 3,
        urlImg: imageCamperFordRoller29803,

        altTextImg: '',
      },
      {
        id: 4,
        urlImg: imageCamperFordRoller29804,

        altTextImg: '',
      },
      {
        id: 5,
        urlImg: imageCamperFordRoller29805,

        altTextImg: '',
      },
      {
        id: 6,
        urlImg: imageCamperFordRoller29806,

        altTextImg: '',
      },
      {
        id: 7,
        urlImg: imageCamperFordRoller29807,

        altTextImg: '',
      },
      {
        id: 8,
        urlImg: imageCamperFordRoller29808,

        altTextImg: '',
      },
      {
        id: 9,
        urlImg: imageCamperFordRoller29809,

        altTextImg: '',
      },
      {
        id: 10,
        urlImg: imageCamperFordRoller29810,

        altTextImg: '',
      },
      {
        id: 11,
        urlImg: imageCamperFordRoller29811,

        altTextImg: '',
      },
      {
        id: 12,
        urlImg: imageCamperFordRoller29812,

        altTextImg: '',
      },
    ],
  },
  {
    camperId: '2',
    camperModel: 'Horon Overcab 71 M',
    camperTransmitionType: 'manualna',
    camperEnginePower: '130',
    camperLength: '599',
    camperWidth: '235',
    camperHeight: '320',
    camperWeight: '2835',
    camperCapacity: '665',
    camperAmountBeds: '6',
    camperAmountPerson: '6',
    camperManufactureYear: '2022',
    chassisType: '',
    camperUrlShowMoreInfo: 'nasze-auta/horon-overcab-71m',
    camperDescription:
      'Komfortowy Kamper z wygodnym łóżkiem małżeńskim, wydzieloną dużą łazienką (oddzielny zlewozmywak/kabina/WC) i dużą kuchnią. W drogę ruszasz zdezynfekowanym i pachnącym nowością autem, wyposażonym w meble ogrodowe, dwie butle z gazem (zapas na przynajmniej tydzień).',
    camperMainImage: imageCamperHoronOvercab71M00,
    camperHirePrice: [330, 440, 640],
    camperEquipment: [
      {
        id: 0,
        typeAccordion: 'list',
        header: 'Salon',
        list: [
          {
            listItem: 'Jadalnia "twarzą w twarz"',
          },
          {
            listItem: 'Duże okna i świetliki',
          },
          {
            listItem: 'Duży blat stołu',
          },
          {
            listItem: 'Meble z jasnego drewna',
          },
          {
            listItem: 'Ergonomiczne siedzenia w jadalni',
          },
          {
            listItem:
              'Pokrowce na siedzenia skoordynowane z tapicerką z tkaniny',
          },
          {
            listItem:
              'Podnoszone elektrycznie łóżko z wygodnym materacem i zabezpieczeniem przed wypadnięciem',
          },
          {
            listItem: 'Łóżko uzupełniające',
          },
        ],
      },
      {
        id: 1,
        typeAccordion: 'list',
        header: 'Kuchnia',
        list: [
          {
            listItem:
              'Lodówka z zamrażalnikiem z przegrodą na butelki; do 145 l butelek, zasilanie 230V/12V/Gaz.',
          },
          { listItem: 'Płyta gazowa trzypalnikowa ze szklaną pokrywą' },
          {
            listItem:
              'Pełna zabudowa kuchni z zabezpieczeniem przed otwieraniem podczas jazdy',
          },

          {
            listItem:
              'Kompletne wyposażenie kuchni dla 4 osób (naczynia metalowe, szklane, sztućce)',
          },
        ],
      },
      {
        id: 2,
        typeAccordion: 'list',
        header: 'Główna sypialnia',
        list: [
          {
            listItem: 'Dwa oddzielne łóżka',
          },
          {
            listItem: 'Materac oddychający',
          },
          {
            listItem:
              'Okna i świetliki dla większej ilości światła i wentylacji',
          },
          {
            listItem:
              'Wyściełany tkaniną panel sufitowy z kieszenią do przechowywania drobiazgów',
          },
          {
            listItem: 'Boczne kieszenie i szafy do przechowywania',
          },
          {
            listItem: 'Oświetlenie do czytania z półką na smartfony i tablety',
          },
          {
            listItem:
              'System nagłośnienia zintegrowany z głównym radiem w kabinie kierowcy',
          },
        ],
      },
      {
        id: 3,
        typeAccordion: 'list',
        header: 'Łazienka',
        list: [
          {
            listItem: 'Wydzielona strefa do mycia i WC',
          },
          {
            listItem: 'Meble drewniane',
          },
          {
            listItem: 'Duży prysznic z podwójnym odpływem',
          },
          {
            listItem: 'Lustra ze zintegrowanym oświetleniem i świetlikiem',
          },
          {
            listItem: 'Sztywne drzwiczki prysznicowe',
          },
          {
            listItem: 'Świetlik dachowy',
          },
        ],
      },
      {
        id: 4,
        typeAccordion: 'list',
        header: 'Wyposażenie pokładowe',
        list: [
          {
            listItem: 'Panel zarządzania pojazdem',
          },
          {
            listItem: 'Grzejnik obwodowy',
          },
          {
            listItem: 'Ogrzewanie Truma Combi 4 gaz',
          },
          {
            listItem: 'Oświetlenie ledowe',
          },
          {
            listItem: 'Podłogowe oświetlenie nocne na obwodzie podłogi',
          },
          {
            listItem: 'Oświetlenie punktowe LED pod meblościankami',
          },
          {
            listItem: 'Gniazdo USB w części dziennej i sypialnej',
          },
          {
            listItem: 'Gniazdko elektryczne 230 V',
          },
          {
            listItem: 'Tempomat',
          },
        ],
      },
      {
        id: 5,
        typeAccordion: 'list',
        header: 'Podwozie',
        list: [
          {
            listItem: 'FORD Alkova 130 KM Overcab 71 M 2022',
          },
          {
            listItem: 'Antena DAB',
          },
          {
            listItem: 'Poduszki powietrzne kierowcy i pasażera',
          },
          {
            listItem: 'Lusterka wsteczne elektryczne z odmrażaniem',
          },
          {
            listItem: 'Radio Bluetooth ze sterowaniem na kierownicy (FORD)',
          },
          {
            listItem: 'Ręczny system klimatyzacji',
          },
          {
            listItem: 'Obrotowe fotele w kabinie',
          },
          {
            listItem:
              'Pokryta skórą kierownica i gałka dźwigni zmiany biegów (FORD)',
          },
          {
            listItem: 'Fix&Go - zestaw naprawczy kół',
          },
        ],
      },
      // {
      //   header: "Z zewnątrz",
      //   list: [
      //     {
      //       listItem: "Biała kabina i zderzaki",
      //     },
      //     {
      //       listItem: "Drzwi wejściowe z oknem stałym i moskitierą",
      //     },
      //     {
      //       listItem: "Koła stalowe 16” (FORD)",
      //     },
      //     {
      //       listItem: "Niski maxi schowek na dwie butle gazowe",
      //     },
      //     {
      //       listItem: "Garaż XXL o maksymalnej ładowności 300 kg",
      //     },
      //     {
      //       listItem: "Solar 140W",
      //     },
      //     {
      //       listItem: "Markiza",
      //     },
      //     {
      //       listItem:
      //         "Zewnętrzny głośnik, zintegrowany z głównym radiem w kabinie kierowcy",
      //     },
      //     {
      //       listItem: "Centralny zamek dla części kabinowej (dwoje drzwi)",
      //     },
      //   ],
      // },
      // {
      //   header: "Bezpieczeństwo",
      //   list: [
      //     {
      //       listItem:
      //         "Pełne ubezpieczenie OC/AC z udziałem własnym wynajmującego w zawinionych szkodach komunikacyjnych i szkodach niekomunikacyjnych na pojeździe maksymalnie do 5000 zł",
      //     },
      //     {
      //       listItem: "Kamera cofania",
      //     },
      //     {
      //       listItem: "Siatki zabezpieczające przed wypadnięciem z łóżek",
      //     },
      //     {
      //       listItem:
      //         "Pasy bezpieczeństwa na wszystkich miejscach przeznaczonych do jazdy",
      //     },
      //     {
      //       listItem: "Dwie poduszki powietrzne",
      //     },
      //     {
      //       listItem: "ABS, ASR",
      //     },
      //     {
      //       listItem: "Zabezpieczenie przed zamarzaniem wody w Trumie",
      //     },
      //   ],
      // },
    ],

    imageGalery: [
      {
        id: 0,
        urlImg: imageCamperHoronOvercab71M00,
        altTextImg: '',
      },
      {
        id: 1,
        urlImg: imageCamperHoronOvercab71M01,
        altTextImg: '',
      },
      {
        id: 2,
        urlImg: imageCamperHoronOvercab71M02,
        altTextImg: '',
      },
      {
        id: 3,
        urlImg: imageCamperHoronOvercab71M03,
        altTextImg: '',
      },
      {
        id: 4,
        urlImg: imageCamperHoronOvercab71M04,
        altTextImg: '',
      },
      {
        id: 5,
        urlImg: imageCamperHoronOvercab71M05,
        altTextImg: '',
      },
      {
        id: 6,
        urlImg: imageCamperHoronOvercab71M06,
        altTextImg: '',
      },
      {
        id: 7,
        urlImg: imageCamperHoronOvercab71M07,
        altTextImg: '',
      },
      {
        id: 8,
        urlImg: imageCamperHoronOvercab71M08,
        altTextImg: '',
      },
    ],
  },
];

export const camperDetailsFordRoller266TL = campersDetailsList[0];
export const camperDetailsFordRoller298 = campersDetailsList[1];
// export const camperDetailsHoronOverCab71M = campersDetailsList[2];

export const campersCards = [
  {
    camperId: '0',
    camperModel: campersDetailsList[0].camperModel,
    camperDescription: campersDetailsList[0].camperTransmitionType,
    camperMainImage: campersDetailsList[0].camperMainImage,
    camperUrlShowMoreInfo: campersDetailsList[0].camperUrlShowMoreInfo,
    camperAmountPerson: campersDetailsList[0].camperAmountPerson,
    camperAmountBeds: campersDetailsList[0].camperAmountBeds,
    camperHirePrice: campersDetailsList[0].camperHirePrice,
  },
  {
    camperId: '1',
    camperModel: campersDetailsList[1].camperModel,
    camperDescription: campersDetailsList[1].camperTransmitionType,
    camperMainImage: campersDetailsList[1].camperMainImage,
    camperUrlShowMoreInfo: campersDetailsList[1].camperUrlShowMoreInfo,
    camperAmountPerson: campersDetailsList[1].camperAmountPerson,
    camperAmountBeds: campersDetailsList[1].camperAmountBeds,
    camperHirePrice: campersDetailsList[1].camperHirePrice,
  },
  // {
  //   camperId: '2',
  //   camperModel: campersDetailsList[2].camperModel,
  //   camperDescription: campersDetailsList[2].camperTransmitionType,
  //   camperMainImage: campersDetailsList[2].camperMainImage,
  //   camperUrlShowMoreInfo: campersDetailsList[2].camperUrlShowMoreInfo,
  //   camperAmountPerson: campersDetailsList[2].camperAmountPerson,
  //   camperAmountBeds: campersDetailsList[2].camperAmountBeds,
  //   camperHirePrice: campersDetailsList[2].camperHirePrice,
  // },
];

export const campersCardsBooking = [
  {
    camperId: '0',
    camperModel: campersDetailsList[0].camperModel,
    camperMainImage: campersDetailsList[0].camperMainImage,
    camperUrlShowMoreInfo: campersDetailsList[0].camperUrlShowMoreInfo,
    camperAmountPerson: campersDetailsList[0].camperAmountPerson,
    camperAmountBeds: campersDetailsList[0].camperAmountBeds,
    camperHirePrice: campersDetailsList[0].camperHirePrice,
    camperTransmitionType: campersDetailsList[0].camperTransmitionType,
    camperEnginePower: campersDetailsList[0].camperEnginePower,
    camperLength: campersDetailsList[0].camperLength,
    camperWidth: campersDetailsList[0].camperWidth,
    camperHeight: campersDetailsList[0].camperHeight,
    camperWeight: campersDetailsList[0].camperWeight,
    camperCapacity: campersDetailsList[0].camperCapacity,
    camperManufactureYear: campersDetailsList[0].camperManufactureYear,
  },
  {
    camperId: '1',
    camperModel: campersDetailsList[1].camperModel,
    camperMainImage: campersDetailsList[1].camperMainImage,
    camperUrlShowMoreInfo: campersDetailsList[1].camperUrlShowMoreInfo,
    camperAmountPerson: campersDetailsList[1].camperAmountPerson,
    camperAmountBeds: campersDetailsList[1].camperAmountBeds,
    camperHirePrice: campersDetailsList[1].camperHirePrice,
    camperTransmitionType: campersDetailsList[1].camperTransmitionType,
    camperEnginePower: campersDetailsList[1].camperEnginePower,
    camperLength: campersDetailsList[1].camperLength,
    camperWidth: campersDetailsList[1].camperWidth,
    camperHeight: campersDetailsList[1].camperHeight,
    camperWeight: campersDetailsList[1].camperWeight,
    camperCapacity: campersDetailsList[1].camperCapacity,
    camperManufactureYear: campersDetailsList[1].camperManufactureYear,
  },
  // {
  //   camperId: '2',
  //   camperModel: campersDetailsList[2].camperModel,
  //   camperMainImage: campersDetailsList[2].camperMainImage,
  //   camperUrlShowMoreInfo: campersDetailsList[2].camperUrlShowMoreInfo,
  //   camperAmountPerson: campersDetailsList[2].camperAmountPerson,
  //   camperAmountBeds: campersDetailsList[2].camperAmountBeds,
  //   camperHirePrice: campersDetailsList[2].camperHirePrice,
  //   camperTransmitionType: campersDetailsList[2].camperTransmitionType,
  //   camperEnginePower: campersDetailsList[2].camperEnginePower,
  //   camperLength: campersDetailsList[2].camperLength,
  //   camperWidth: campersDetailsList[2].camperWidth,
  //   camperHeight: campersDetailsList[2].camperHeight,
  //   camperWeight: campersDetailsList[2].camperWeight,
  //   camperCapacity: campersDetailsList[2].camperCapacity,
  //   camperManufactureYear: campersDetailsList[2].camperManufactureYear,
  // },
];
