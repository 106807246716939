import React, { useState, useEffect } from 'react';
import './EntryLandingForm.scss';
import {
  selectPickupAdress,
  selectPickupDate,
  selectPickupTime,
  selectReturnAdress,
  selectReturnDate,
  selectReturnTime,
  setPickupAdress,
  setPickupData,
  setPickupTime,
  setReturnAdress,
  setReturnData,
  setReturnTime,
  setStep1FormData,
  selectFormStep1Data,
} from '../../ducks/formSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import { Link } from 'react-router-dom';

interface EntryLandingFormProps {
  // Define any props that this component might receive
}

const EntryLandingForm: React.FC<EntryLandingFormProps> = () => {
  const dispatch = useAppDispatch();
  const step1 = useAppSelector(selectFormStep1Data);

  // Step 4 getter
  const pickupPlaceAdress = useAppSelector(selectPickupAdress);
  const pickupDate = useAppSelector(selectPickupDate);
  const pickupTime = useAppSelector(selectPickupTime);
  const returnPlaceAdress = useAppSelector(selectReturnAdress);
  const returnDate = useAppSelector(selectReturnDate);
  const returnTime = useAppSelector(selectReturnTime);
  const [minimalRentRange, setMinimalRentRange] = useState<number>(3);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [personAmount, setPersonAmount] = useState<number>(step1.personAmount);
  // Validate next day as min
  const [minDate, setMinDate] = useState<string>('');
  const [minDateReturn, setMinDateReturn] = useState<string>('');

  const handleSave = () => {
    dispatch(
      setStep1FormData({
        pickupPlaceAdress,
        returnPlaceAdress,
        pickupDate,
        returnDate,
        pickupTime,
        returnTime,
        personAmount,
      })
    );
  };

  useEffect(() => {
    const currentDate = new Date();
    const nextDay = new Date(currentDate);
    nextDay.setDate(currentDate.getDate() + 1);
    const nextDayFormatted = nextDay.toISOString().split('T')[0];
    setMinDate(nextDayFormatted);
  }, []);

  const checkPickupDateSeason = () => {
    const startingDate = new Date(pickupDate);

    const month = startingDate.getMonth() + 1;
    const day = startingDate.getDate();

    if (
      (month >= 11 && month <= 12) ||
      month === 1 ||
      (month >= 2 && month <= 3)
    ) {
      setMinimalRentRange(3);
    } else if (
      month === 6 ||
      month === 7 ||
      month === 8 ||
      (month === 4 && day >= 28) ||
      (month === 5 && day <= 7)
    ) {
      setMinimalRentRange(5);
    } else {
      setMinimalRentRange(5);
    }
  };

  // Use useEffect to react to changes in minimalRentRange
  useEffect(() => {
    const minDate = pickupDate ? new Date(pickupDate) : new Date();
    minDate.setDate(minDate.getDate() + minimalRentRange);
    setMinDateReturn(minDate.toISOString().split('T')[0]);
  }, [minimalRentRange, pickupDate]);

  const pickupDateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setPickupData(e.target.value));
    setStartDate(e.target.value);
    checkPickupDateSeason();
    dispatch(setReturnData(''));
  };

  const returnDateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setReturnData(e.target.value));
    setEndDate(e.target.value);
  };

  return (
    <form className='entry-form container'>
      <div className='even-columns'>
        <div className='input-group__container padding-block-400'>
          <div className='input-group input-field input-field__large-width'>
            <input
              type='text'
              id='pickup-place'
              className='input-group__input'
              required
              value={pickupPlaceAdress}
              onChange={(e) => dispatch(setPickupAdress(e.target.value))}
            />
            <label htmlFor='pickup-place' className='input-group__label'>
              Miejsce odbioru
            </label>
          </div>
          <div className='input-group__date input-field input-field__small-width'>
            <input
              required
              type='date'
              min={minDate}
              value={pickupDate}
              onChange={(e) => pickupDateHandler(e)}
            />
            <label> Data odbioru</label>
          </div>
          <div className='input-group__time input-field input-field__small-width'>
            <input
              required
              type='time'
              value={pickupTime}
              onChange={(e) => {
                dispatch(setPickupTime(e.target.value));
              }}
            />
            <label>Godzina odbioru</label>
          </div>
        </div>

        <div className='input-group__container padding-block-400'>
          <div className='input-group input-field input-field__large-width'>
            <input
              type='text'
              id='return-place'
              className='input-group__input'
              required
              value={returnPlaceAdress}
              onChange={(e) => dispatch(setReturnAdress(e.target.value))}
            />
            <label htmlFor='return-place' className='input-group__label'>
              Miejsce zwrotu
            </label>
          </div>
          <div className='input-group__date input-field input-field__small-width'>
            <input
              required
              type='date'
              value={returnDate}
              min={minDateReturn}
              onChange={(e) => {
                returnDateHandler(e);
              }}
            />
            <label>Data zwrotu</label>
          </div>
          <div className='input-group__time input-field input-field__small-width'>
            <input
              required
              type='time'
              value={returnTime}
              onChange={(e) => dispatch(setReturnTime(e.target.value))}
            />
            <label>Godzina zwrotu</label>
          </div>
        </div>
        <div className='input-group__container no-wrap padding-block-400'>
          <div className='input-group__select input-field input-field__small-width'>
            <select
              name='selectedAmountPerson'
              value={personAmount}
              onChange={(e) => setPersonAmount(parseInt(e.target.value))}
              required
            >
              <option value='1'>1</option>
              <option value='2'>2</option>
              <option value='3'>3</option>
              <option value='4'>4</option>
              <option value='5'>5</option>
              <option value='6'>6</option>
              <option value='7'>7</option>
              <option value='8'>8</option>
            </select>
            <label className='label' htmlFor='select-person-amount'>
              Liczba osób
            </label>
          </div>
          <div className='center input-field input-field__large-width'>
            <Link
              to='/rezerwuj'
              className='btn btn-form btn-cta'
              onClick={handleSave}
            >
              <span className='cta-container'>
                <span className='icon-booking'></span>
                Rezerwuj
              </span>
            </Link>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EntryLandingForm;
