import React from 'react';
import PageWrapperImageHead from '../../component/UI/Wrappers/PageWrapper/PageWrapperImageHead';
import AdviceList from '../../component/Advices/AdviceList';
import heroImage from '../../assets/images/guides/guides-with-children-hero.jpg';
import imgAdvice00 from '../../assets/images/guides/advices-with-children/img-advice-children-00.jpg';
import imgAdvice01 from '../../assets/images/guides/advices-with-children/img-advice-children-01.jpg';
import imgAdvice02 from '../../assets/images/guides/advices-with-children/img-advice-children-02.jpg';
import imgAdvice03 from '../../assets/images/guides/advices-with-children/img-advice-children-03.jpg';
import imgAdvice04 from '../../assets/images/guides/advices-with-children/img-advice-children-04.jpg';
import imgAdvice05 from '../../assets/images/guides/advices-with-children/img-advice-children-05.jpg';
import imgAdvice06 from '../../assets/images/guides/advices-with-children/img-advice-children-06.jpg';
import imgAdvice07 from '../../assets/images/guides/advices-with-children/img-advice-children-07.jpg';
import imgAdvice08 from '../../assets/images/guides/advices-with-children/img-advice-children-08.jpg';
import imgAdvice09 from '../../assets/images/guides/advices-with-children/img-advice-children-09.jpg';

import guideFirstTimePhoto from '../../assets/images/guides/guide_first_time_photo.jpg';
import guideSafetyPhoto from '../../assets/images/guides//guide_safety_photo.jpg';
import guidePackPhoto from '../../assets/images/guides/guide_pack_guide_photo.jpg';
import OtherAdvicesBoxLinks from '../../component/Advices/OtherAdvicesBoxLinks';
const GuideWithChildren = () => {
  const adviceList = [
    {
      adviceNumber: 0,
      adviceName: 'Zaangażuj swoje dzieci w planowanie',
      adviceInfo:
        'Szukasz sposobu na uniknięcie ciągłego pytania „ile jeszcze!?”. Zaangażuj swoje dzieci w planowanie wycieczki kamperem. Pozwól im mieć wpływ na to, dokąd się udacie i jakie aktywności podejmiecie. Jeśli Twoje dzieci są trochę starsze, może warto dać im wolność i odpowiedzialność, pozwalając zaplanować wycieczkę? Wspólne przygotowywanie posiłków w kamperze również może okazać się świetnym pomysłem! Im bardziej Twoje dzieci są zaangażowane w to, co robisz, tym bardziej to pokochają. Szczególnie prawdziwe jest to, jeśli masz nastolatka, który niechętnie podróżuje z rodziną.',
      adviceImage: imgAdvice00,
    },
    {
      adviceNumber: 1,
      adviceName: 'Bezpieczeństwo przede wszystkim',
      adviceInfo:
        'Mocowanie fotelika dziecięcego w kamperze wygląda podobnie jak w samochodzie osobowym. Jeśli nie posiadasz własnego istnieje możliwość wypożyczenia go u nas.\nPodczas podróży wszyscy muszą być zapięci w pasy. Wiemy, że w niektórych krajach, nie jest to sprecyzowane, czy można wstać podczas jazdy i podejść np. do lodówki, ale przyjmij, że jest to zakazane. Tak będzie bezpieczniej.',
      adviceImage: imgAdvice01,
    },
    {
      adviceNumber: 2,
      adviceName: 'Planuj krótsze wycieczki',
      adviceInfo:
        'Wiele godzin jazdy może być niewygodne, nudne i męczące dla dzieci. Aby utrzymać dobry nastrój podczas podróży kamperem, warto skrócić czas jazdy. Starsze dzieci powinny wytrzymać do ośmiu godzin, ale dla młodszych warto ograniczyć podróż do 3-4 godzin dziennie. Jeśli nie uda Ci się dojechać do celu w ciągu jednego dnia, zatrzymaj się na nocleg. Kamper daje ogromną swobodę podróżowania, więc skorzystaj z tej możliwości. Krótszy czas jazdy sprawi, że dzieci będą mniej znudzone, kierowca odpocznie, a pasażer będzie mógł uzupełnić zapasy przekąsek i rozprostować nogi.\n\nPlanując wycieczkę kamperem, należy pamiętać, że będzie się poruszało z mniejszą prędkością niż samochodem osobowym. Pomijając przystanki na potrzeby fizjologiczne, warto dodać 15-20 minut dla każdej godziny podawanej przez nawigację GPS.',
      adviceImage: imgAdvice02,
    },
    {
      adviceNumber: 3,
      adviceName: 'Zabawki lubią latać',
      adviceInfo:
        'Dla bezpieczeństwa ogranicz ilość nieschowanych zabawek dla dzieci, żeby w trakcie jazdy nie zaczęły „latać” po aucie. Nie polecamy pozostawiania na stole zabawek okrągłych, ponieważ jak spadną lub wypadną z ręki maluchowi, to mogą potoczyć się pod najmniej spodziewane miejsce – np. pod pedał hamulca. Aby temu zapobiec warto, aby Twoje dziecko dostało własną przestrzeń do przechowywania swoich skarbów. Kamper jest duży - na pewno znajdzie się miejsce.',
      adviceImage: imgAdvice03,
    },
    {
      adviceNumber: 4,
      adviceName: 'Zadbaj o uszy',
      adviceInfo:
        'Kamper podczas jazdy nie jest cichą maszyną. Zapewnij dzieciom słuchawki do oglądania filmów, słuchania muzyki lub audiobooków. Dla najmłodszych nie trzeba organizować zatyczek do uszu, bo nic tak nie usypia naszych pociech jak monotonny szum i bujanie podczas jazdy. 😊',
      adviceImage: imgAdvice04,
    },
    {
      adviceNumber: 5,
      adviceName: 'Zapewnij przestrzeń dla każdego',
      adviceInfo:
        'Najlepszą rzeczą jaką możesz zrobić podczas podróży z dziećmi, to zapewnienie im własnej przestrzeni do przechowywania. Pierwszego dnia wycieczki pokaż swoim pociechom, gdzie mogą chować swoje skarby. Kampery oferują dużo szafek, więc warto z nich skorzystać. Pokaż też to, gdzie każdy z Was ma swoje miejsce do spania. Unikniesz tym sposobem stresu i dezorientacji dzieci, jeśli obudzą się w środku nocy.',
      adviceImage: imgAdvice05,
    },
    {
      adviceNumber: 6,
      adviceName: 'Przekąski Twoim przyjacielem',
      adviceInfo:
        'Dzieci jedzą dużo przekąsek podczas podróży. Przygotuj się na to, że zjedzą podwójną ilość podczas podróży kamperem. To może wynikać z ekscytacji związanej z nowym miejscem, dużą ilością świeżego powietrza i czasu spędzonego na zewnątrz. Apetyt dzieci nie zna granic, gdy jesteśmy w drodze. Aby zachęcić nasze dzieci do jedzenia zdrowiej żywności, umieszczajmy na widoku łatwo dostępne jabłka i inne zdrowe przekąski, a te mniej zdrowe schowajmy na wyższej półce.',
      adviceImage: imgAdvice06,
    },
    {
      adviceNumber: 7,
      adviceName: 'Miej plan na deszczowe dni',
      adviceInfo:
        'Możesz jechać do słonecznej Hiszpanii, ale na wszelki wypadek warto być przygotowanym, że nawet tam może padać. Przezorny zawsze ubezpieczony. W trakcie pochmurnych dni może okazać się, że spędzisz sporo czasu wewnątrz kampera, zamiast np. na plaży. Na szczęście w aucie możesz oglądać filmy, grać w gry rodzinne, czytać książki lub bawić się w chowanego (a właściwie w chowanie rzeczy i szukanie na czas). Najważniejsza jest wyobraźnia. Na wakacjach nie ograniczaj dzieci - nauczysz się ich kreatywności.\n\nJeśli nie chcesz, aby cały deszczowy dzień minął w kamperze, warto skorzystać z pomocy Internetu i poszukać atrakcji w pobliżu. Możesz też zaczepić innych turystów lub lokalsów, żeby podpytać ich o ciekawe miejsca na niepogodę.',
      adviceImage: imgAdvice07,
    },
    {
      adviceNumber: 8,
      adviceName: 'Zorganizuj przestrzeń do zabawy przed kamperem',
      adviceInfo:
        'Zabawy na świeżym powietrzu są idealne do zapewnienia dzieciom rozrywki - to największa radość, jaką możesz sprawić najmłodszym. Duża mata umieszczona na zewnętrz przed kamperem zapewnia doskonałą przestrzeń do zabawy. Idealnie sprawdza się do układania klocków, kolorowania, a także do raczkowania dla niemowląt. Nasze kampery są wyposażone w rozkładaną markizę, więc nie musisz martwić się o nadmiar słońca. Zestawy odkrywców dla dzieci z lornetką, kompasem i sprzętem do obserwacji owadów to świetna aktywność podczas odkrywania tymczasowego podwórka. Wszystko to może być świetną zabawą dla całej rodziny.',
      adviceImage: imgAdvice08,
    },
    {
      adviceNumber: 9,
      adviceName: 'Na koniec - miej dystans',
      adviceInfo:
        'Nie popełniaj błędu niektórych, traktując sprawy zbyt poważnie lub denerwując się drobiazgami podczas podróży kamperem. Piękno tej formy spędzania czasu polega na wolności, a ona wymaga tego, żeby dawać ją sobie i innym. Miej luz - pokaż dzieciom, że biwakowanie to świetna zabawa i wolność, której powinni mieć w życiu jak najwięcej. Ciesz się na możliwość przeżycia tego typu przygody z rodziną. Te doświadczenia będą najważniejsze i to one zostaną w Twojej głowie na wiele lat.',
      adviceImage: imgAdvice09,
    },
  ];
  const otherAdvicePagesList = [
    {
      pageName: 'Bezpieczeństwo',
      urlToPage: 'poradniki/bezpieczenstwo',
      pagePhoto: guideSafetyPhoto,
    },
    {
      pageName: 'Pierwszy raz',
      urlToPage: 'poradniki/pierwszy-raz',
      pagePhoto: guideFirstTimePhoto,
    },
    {
      pageName: 'Co spakować?',
      urlToPage: 'poradniki/co-spakowac',
      pagePhoto: guidePackPhoto,
    },
  ];
  return (
    <PageWrapperImageHead pageTitle='Z dziećmi' photo_brand={heroImage}>
      <AdviceList
        adviceList={adviceList}
        adviceSectionDescription={`Wiedz, że zaplanowanie podróży kamperem z dziećmi wcale nie jest takie trudne!\nWarto zastosować się do kilku wskazówek, które pomogą przeżyć Wam fantastyczne wakacje.\nPrawdopodobnie ten sposób wypoczynku będzie dla Twojego dziecka dużo ciekawszy niż wakacje all-inclusive, więc szykuj się na niezapomnianą przygodę kamperem!`}
      />
      <OtherAdvicesBoxLinks otherAdvicePages={otherAdvicePagesList} />
    </PageWrapperImageHead>
  );
};

export default GuideWithChildren;
