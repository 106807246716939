import React, { useState } from 'react';
import AdviceList from '../Advices/AdviceList';
import './AccessibleTabs.scss';
import AccordionSwitchTypeList from '../Accordions/AccorditionSwitchType/AccordionSwitchTypeList';
import { faqsList } from '../../constants/constantsFaqs';
import { Icon } from '@iconify/react';
import { RenderDescriptionWithLinks } from '../../helpers/helpersReturns';
export const AccessibleTabsAdvices = ({ tabsConfig, defaultIndex }) => {
  const [selectedIndex, setSelectedIndex] = useState(defaultIndex ?? 0);

  const handleClick = (index) => setSelectedIndex(index);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <>
      <div className='sticky'>
        <div className='sliding-tabs'>
          {tabsConfig.map((tab, index) => {
            return (
              <React.Fragment>
                <input
                  key={index}
                  type='radio'
                  id={`radio-${index}`}
                  name='tabs'
                  value={index}
                  checked={selectedIndex === index}
                  onChange={() => {
                    handleClick(index);
                    scrollToTop();
                  }}
                />
                <label className='sliding-tabs__tab' htmlFor={`radio-${index}`}>
                  <span>{tab.label}</span>
                </label>
              </React.Fragment>
            );
          })}
          <span className='glider'></span>
        </div>
      </div>
      <div className='tabpanel-wrapper'>
        {tabsConfig.map((tab, index) => (
          <section key={`tabpanel-${index}`} hidden={selectedIndex !== index}>
            <div className='advice-item__info'>
              <p>
                <RenderDescriptionWithLinks description={tab.description} />
                {/* {tab.description} */}
              </p>
            </div>
            <AdviceList adviceList={tab.content} />
          </section>
        ))}
      </div>
    </>
  );
};

export const AccessibleTabsFaqs = ({ tabsConfig, defaultIndex }) => {
  const [selectedIndex, setSelectedIndex] = useState(defaultIndex ?? 0);

  const handleClick = (index) => setSelectedIndex(index);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <>
      <div className='sticky'>
        <div className='sliding-tabs-3'>
          {tabsConfig.map((tab, index) => {
            return (
              <React.Fragment>
                <input
                  key={index}
                  type='radio'
                  id={`radio-${index}`}
                  name='tabs'
                  value={index}
                  checked={selectedIndex === index}
                  onChange={() => {
                    handleClick(index);
                    scrollToTop();
                  }}
                />
                <label className='sliding-tabs__tab' htmlFor={`radio-${index}`}>
                  <span>{tab.label}</span>
                </label>
              </React.Fragment>
            );
          })}
          <span className='glider'></span>
        </div>
      </div>
      <div className='tabpanel-wrapper'>
        {tabsConfig.map((tab, index) => (
          <section key={`tabpanel-${index}`} hidden={selectedIndex !== index}>
            <div className='flex-center padding-block-100'>
              <Icon icon={tab.icon} color='#e07000' width='80' height='80' />
            </div>
            <h3 className='underline-header'>{tab.label}</h3>

            <AccordionSwitchTypeList faqs={faqsList[selectedIndex].list} />
          </section>
        ))}
      </div>
    </>
  );
};
