import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { imgMainLogo } from '../../../constants/images';
import './Navbar.scss';
import CamperAnim from '../CamperAnim/CamperAnim';
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const closeMobileNavHandler = () => {
    setIsOpen(false);
  };
  return (
    <header className='primary-header'>
      {/* do przerobienia */}
      <nav className='nav container'>
        <div
          className='nav-branding'
          itemType='http://schema.org/Organization'
          itemScope=''
        >
          <Link
            to='/'
            onClick={closeMobileNavHandler}
            title='Ikifriki - Strona główna'
            itemProp='url'
          >
            <img
              src={imgMainLogo}
              alt='Ikifriki - make your adventure logo'
              title='Ikifriki'
              itemProp='logo'
            />
          </Link>
        </div>
        <ul className={`nav-items ${isOpen && 'open'}`}>
          <li>
            <Link to='/kampery' onClick={closeMobileNavHandler}>
              Kampery
            </Link>
          </li>
          <li>
            <Link to='/poradniki' onClick={closeMobileNavHandler}>
              Porady i wskazówki
            </Link>
          </li>
          <li>
            <Link to='/faq' onClick={closeMobileNavHandler}>
              FAQ
            </Link>
          </li>
          <li>
            <Link to='/o-nas' onClick={closeMobileNavHandler}>
              O nas
            </Link>
          </li>
          <li>
            <Link to='/rezerwuj' onClick={closeMobileNavHandler}>
              <span className='button-cta'>Rezerwuj</span>
              <CamperAnim />
            </Link>
          </li>
        </ul>
        <div
          className={`nav-toggle ${isOpen && 'open'}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className='bar'></div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
