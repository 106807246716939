import React, { useEffect, useState } from 'react';
import './AccesoriesRadioSelect.scss';
import AccesoriesRadioInput from './AccesoriesRadioInput';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import { Icon } from '@iconify/react';
import {
  calculateAccesoriesPrice,
  selectFormStep3Data,
  setStep2AccesoriesData,
  totalDays,
} from '../../../ducks/formSlice';
import roundNumber from '../../../helpers/helpers';
import { NumberWithThousands } from '../../../helpers/helpersReturns';

interface AccesoriesPricingList {
  id: number;
  amount: number;
}

interface AccesoriesRadioInputProps {
  accesoriesID: number;
  name: string;
  description: string;
  pricePerItem: number;
  nameAccesoriesInput: string;
  accesoriessIcon: string;
  accesoriesOptions: AccesoriesPricingList[];
  totalPrice: number;
  totalDays: number;
}
const AccesoriesRadioSelect: React.FC<AccesoriesRadioInputProps> = ({
  accesoriesID,
  name,
  accesoriessIcon,
  accesoriesOptions,
  nameAccesoriesInput,
  description,
  pricePerItem,
  totalPrice,
}) => {
  const dispatch = useAppDispatch();
  const [selectedAccesoriesRadioId, setSelectedAccesoriesRadioId] = useState<
    number | null
  >(0);

  const selectedAccesories = useAppSelector(selectFormStep3Data);

  useEffect(() => {
    selectedAccesories.addedAccesories.map((x) =>
      x.accesoriesID === accesoriesID
        ? setSelectedAccesoriesRadioId(x.selectedAccesories)
        : null
    );
  }, [accesoriesID, selectedAccesories.addedAccesories]);
  const totalAmountDays = useAppSelector(totalDays);
  const saveAccesoriesDataHandler = (data: any) => {
    dispatch(setStep2AccesoriesData(data));
  };

  const handleAccesoriesRadioChange = (id: number) => {
    const priceMultiPlyAmount = accesoriesOptions[id].amount * pricePerItem;
    const priceMultiPlyAmountRound = roundNumber(priceMultiPlyAmount, 2);
    const priceForAccessoriessMultiplyDays =
      priceMultiPlyAmountRound * totalAmountDays;
    const priceForAccessoriessMultiplyDaysRound = roundNumber(
      priceForAccessoriessMultiplyDays,
      2
    );
    setSelectedAccesoriesRadioId(id);
    const data = {
      accesoriesID: accesoriesID,
      selectedAccesories: id,
      accesoriesName: name,
      accesoriesAmount: accesoriesOptions[id].amount,
      accesoriesPrice: pricePerItem,
      totalPrice: priceMultiPlyAmountRound,
      finalPrice: priceForAccessoriessMultiplyDaysRound,
    };
    saveAccesoriesDataHandler(data);

    dispatch(calculateAccesoriesPrice());
  };
  // tooltop
  const [isShowInfo, setIsShowInfo] = useState(false);

  return (
    <div className='accessories-radio'>
      <div className='accessories-radio__header'>
        <div
          className='accessories-radio__header-icon'
          style={{
            backgroundImage: `url(${accesoriessIcon})`,
          }}
        ></div>
        <div className='accessories-radio__header-name'>
          <h3>{name}</h3>
        </div>
        <div
          title='pokaż opis'
          className='accessories-radio__info'
          onClick={() => setIsShowInfo(!isShowInfo)}
        >
          {!isShowInfo && (
            <Icon
              icon='majesticons:info-circle-line'
              color='#3c4043'
              width='24'
              height='24'
            />
          )}
        </div>
      </div>

      <div className='accessories-radio__body'>
        <div className='accessories-radio__body-pricing'>
          <span>
            Cena <NumberWithThousands value={pricePerItem} /> zł. za dobę
          </span>
        </div>
        <AccesoriesRadioInput
          options={accesoriesOptions}
          selectedOptionId={selectedAccesoriesRadioId}
          onOptionChange={handleAccesoriesRadioChange}
          nameAccesoriesInput={nameAccesoriesInput}
        />
        {isShowInfo && (
          <div className='accessories-radio__show-info'>
            <div className='accessories-radio__show-info__header'>
              <h3>{name}</h3>
              <div
                title='ukryj opis'
                className='accessories-radio__info'
                onClick={() => setIsShowInfo(!isShowInfo)}
              >
                {isShowInfo && (
                  <Icon
                    icon='mdi:close-circle-outline'
                    color='#3c4043'
                    width='24'
                    height='24'
                  />
                )}
              </div>
            </div>
            <div className='accessories-radio__show-info__content'>
              <p>{description}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccesoriesRadioSelect;
