import React from 'react';
import Slider from '../../component/Slider/Slider';
import CamperPricingSection from './CamperPricingSection';
import './CamperMoreInfo.scss';
import EntryLandingForm from '../Forms/EntryLandingForm';
import AccordionSwitchTypeList from '../Accordions/AccorditionSwitchType/AccordionSwitchTypeList';
import WrapperSectionStyledHeader from '../UI/Wrappers/WrapperSection/WrapperSectionStyledHeader';
import ImageSlider from '../ImageSlider/ImageSlider';
const CamperMoreInfo = ({ props }) => {
  const priceForCamperPerSeason = props.camperHirePrice;

  return (
    <div>
      <section
        className='camper__hero-section'
        style={{
          backgroundImage: `url(${props.camperMainImage})`,
        }}
      >
        <div className='camper__title'>
          <h1>{props.camperModel}</h1>
          <div className='camper__title-person'>
            <div
              title='Liczba pasażerów'
              className='camper__title-person__icon'
            ></div>
            <span>{props.camperAmountPerson}</span>
          </div>
          <div className='camper__title-person'>
            <div className='icon-bed'></div>
            <span>{props.camperAmountBeds}</span>
          </div>
          <div className='camper__title-transmition'>
            <div className='camper__title-transmition__icon'></div>
            <span> {props.camperTransmitionType}</span>
          </div>
          <div className='camper__title-length'>
            <div className='camper__title-length__icon'></div>
            <span>{props.camperLength} cm</span>
          </div>
        </div>
        <div className='container'>
          {' '}
          <div className='camper__title-big'>
            <h1>{props.camperModel}</h1>
            <div className='camper__title-big__content padding-block-200'>
              <div
                title='Liczba miejsc do jazdy'
                className='camper__title-person'
              >
                <div className='camper__title-person__icon'></div>
                <span>{props.camperAmountPerson}</span>
              </div>
              <div
                title='Liczba miejsc do spania'
                className='camper__title-person'
              >
                <div className='icon-bed'></div>
                <span>{props.camperAmountBeds}</span>
              </div>
              <div
                title='Typ skrzyni biegów'
                className='camper__title-transmition'
              >
                <div
                  title='Długość pojazdu'
                  className='camper__title-transmition__icon'
                ></div>
                <span> {props.camperTransmitionType}</span>
              </div>
              <div className='camper__title-length'>
                <div className='camper__title-length__icon'></div>
                <span>{props.camperLength} cm</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CamperPricingSection priceForCamperPerSeason={priceForCamperPerSeason} />
      <WrapperSectionStyledHeader sectionName='Rezerwuj'>
        <div className='relative entry-form__wrapper'>
          <EntryLandingForm />
        </div>
      </WrapperSectionStyledHeader>
      <WrapperSectionStyledHeader sectionName='Galeria'>
        {/* <Slider mock={props.imageGalery} /> */}

        <ImageSlider images={props.imageGalery} />
      </WrapperSectionStyledHeader>
      <WrapperSectionStyledHeader
        sectionName={`Szczegółowe informacje ${props.camperModel}`}
      >
        <div className='margin-bottom-600'>
          <p>{props.camperDescription}</p>
        </div>

        <div className='split split-reverse stretch'>
          <div className='list-section'>
            <h3>Dane techniczne:</h3>
            <ul>
              <li>Typ podwozia: {props.chassisType}</li>
              <li>Liczba miejsc do jazdy: {props.camperAmountPerson}</li>
              <li>Liczba miejsc do spania: {props.camperAmountBeds}</li>
              <li>Moc: {props.camperEnginePower} KM.</li>
              <li>Skrzynia biegów: {props.camperTransmitionType} </li>
              <li>
                Wymiary pojazdu:
                <ul>
                  <li>długość: {props.camperLength} cm</li>
                  <li>szerokość: {props.camperWidth} cm</li>
                  <li>wysokość: {props.camperHeight} cm</li>
                </ul>
              </li>
              <li>Masa własna: {props.camperWeight} kg</li>
              {/* <li>Ładowność: {props.camperCapacity} kg</li> */}
            </ul>
          </div>
          <div className='car-equipment'>
            <h3>Wyposażenie</h3>
            <AccordionSwitchTypeList faqs={props.camperEquipment} />
          </div>
        </div>
      </WrapperSectionStyledHeader>
      <WrapperSectionStyledHeader sectionName='Opcje dodatkowe'>
        <div>
          <p>
            W ofercie dodatkowej posiadamy akcesoria, które urozmaicą twoją
            kamperową przygodę. Mamy do zaoferowania longboard, SUP, leżaki, czy
            też parasol. Oprócz tego możesz wypożyczyć przydatne, codzienne
            rzeczy takie jak: fotelik dla dziecka, pościel oraz ręczniki.
          </p>
          <br />
          <p>
            Jesteś już pewny, że chcesz z nami doświadczyć niezapomnianej
            przygody? Może masz jakieś pytania?
            <br /> Zachęcamy do kontaktu na adres
            <a
              className=' clr-text-dblue underline-bottom'
              href='mailto:kontakt@ikifriki.com'
            >
              {' '}
              kontakt@ikifriki.com
            </a>{' '}
            lub wypełnienia formularza w zakładce “rezerwuj” w celu znalezienia
            dogodnego terminu twojej wyprawy lub rozwiania wątpliwości.
          </p>
        </div>
      </WrapperSectionStyledHeader>
    </div>
  );
};

export default CamperMoreInfo;
