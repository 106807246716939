import React from "react";
const PageWrapperImageHead = (props) => {
  return (
    <div className="page-wrapper_image_header">
      <div
        className="page-wrapper_image_header__brand"
        style={{
          backgroundImage: `url(${props.photo_brand})`,
        }}>
            <h1>{props.pageTitle}</h1>
        </div>
        <div className="container">{props.children}
        </div>
    </div>
  );
};

export default PageWrapperImageHead;
