import React from 'react';
import { Link } from 'react-router-dom';
import './LinkBoxPhoto.scss';
import { Icon } from '@iconify/react';

const LinkBoxPhoto = (props) => {
  const urlToPage = '/' + props.pageUrl;
  return (
    <>
      <Link to={urlToPage} className='link-box-with-photo' title='Przejdź'>
        <div className='link-box-with-photo__img'>
          <picture
            className='link-box-with-photo__image'
            style={{
              backgroundImage: `url(${props.pagePhoto})`,
            }}
          ></picture>
        </div>
        <div className='link-box-with-photo__content'>
          <h3 className='link-box-with-photo__title'>{props.pageName}</h3>
          <p className='link-box-with-photo__description'>
            {props.pageDescription}
          </p>
        </div>
        <Icon
          className='move-to-icon'
          icon='majesticons:arrow-right'
          color='#e07000'
          width='32'
          height='32'
        />
      </Link>
    </>
  );
};

export default LinkBoxPhoto;
