import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './SelectedCamperCard.scss';
import { Icon } from '@iconify/react';
interface CamperProps {
  camperModel: string;
  camperAmountPerson: string;
  camperAmountBeds: string;
  camperMainImage: string;
  camperUrlShowMoreInfo: string;
  camperTransmitionType: string;
  camperLength: string;
  camperWidth: string;
  camperHeight: string;
  camperWeight: string;
  camperCapacity: string;
  camperHirePrice: Array<number>;
  camperManufactureYear: string;
}

interface Props {
  selectedCamper: CamperProps;
}

const SelectedCamperCard: React.FC<Props> = ({ selectedCamper }) => {
  const [isActive, setIsActive] = useState(false);
  const urlToPage = '/' + selectedCamper.camperUrlShowMoreInfo;
  return (
    <div className='selected-car-card'>
      <div
        className='selected-car-card__img'
        style={{
          backgroundImage: `url(${selectedCamper.camperMainImage})`,
        }}
      >
        <div className='selected-car-card__amounts'>
          <Icon
            icon='tabler:bed-filled'
            color='#e07000'
            width='24'
            height='24'
          />
          <span>{selectedCamper.camperAmountBeds}</span>
          <Icon
            icon='fluent:people-community-20-filled'
            color='#e07000'
            width='20'
            height='20'
          />
          <span>{selectedCamper.camperAmountPerson}</span>
        </div>
      </div>
      <div className='selected-car-card__content'>
        <h3 className='selected-car-card__title'>
          {selectedCamper.camperModel}
        </h3>
        <div className='selected-car-card__details'>
          <h4>Dane pojazdu</h4>
          <ul>
            <li> Liczba miejsc: {selectedCamper.camperAmountPerson}</li>
            <li>
              Liczba miejsc do spania: {selectedCamper.camperAmountBeds}
            </li>
            <li>Rok produkcji: {selectedCamper.camperManufactureYear}</li>
            <li>Skrzynia biegów: {selectedCamper.camperTransmitionType}</li>
            <li>Masa własna: {selectedCamper.camperWeight} kg.</li>
            <li>
              Wymiary pojazdu:
              <ul>
                <li>długość: {selectedCamper.camperLength} cm.</li>
                <li>szerokość: {selectedCamper.camperWidth} cm.</li>
                <li>wysokość: {selectedCamper.camperHeight} cm.</li>
              </ul>
            </li>
           
          </ul>
          <p className='selected-car-card__price'>
            Cena: od {selectedCamper.camperHirePrice[0]} zł / doba
          </p>
          <Link target='_blank' to={urlToPage} className='btn btn-medium btn-outlined'>
            Zobacz więcej
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SelectedCamperCard;
