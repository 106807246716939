import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import './ButtonBackToTop.scss';
const ButtonBackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      // Adjust the scroll threshold as needed
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <button
      title='W góre'
      className={`back-to-top-button ${isVisible ? 'visible' : ''}`}
      onClick={scrollToTop}
    >
      <Icon
        icon='majesticons:chevron-up-line'
        color='#e07000'
        width='32'
        height='32'
      />
    </button>
  );
};

export default ButtonBackToTop;
