import React from 'react';
import PageWrapperImageHead from '../../component/UI/Wrappers/PageWrapper/PageWrapperImageHead';

import { heroImg, otherAdvicePagesList } from '../../constants/constants';
import { advicesFirstTime } from '../../constants/constantsAdvices';
import OtherAdvicesBoxLinks from '../../component/Advices/OtherAdvicesBoxLinks';
import { AccessibleTabsAdvices } from '../../component/AccessibleTabs/AccessibleTabs';

const GuideFirstTime = () => {
  return (
    <PageWrapperImageHead pageTitle='Pierwszy raz' photo_brand={heroImg}>
      <section>
        <h4 className='text-center'>
          Pierwsza podróż kamperem wiąże się z ogromem emocji.
          <br /> Zebraliśmy dla Ciebie porady, które na pewno zmniejszą stres
          przed nieznanym. <br /> Z doświadczenia podpowiemy, że kierowca jest
          jak kapitan. Dobrze, jeśli działa z planem – wie z kim i dokąd jedzie,
          co będzie robił na miejscu, a przed każdym rozpoczęciem jazdy, jak
          dobry dowódca, sprawdzi sztauowanie ładunku i ludzi.
          <br /> Przyda się znajomość pojazdu i umiejętność jego prowadzenia.{' '}
          <br />
          Dla ułatwienia przedstawiamy Ci listę tematów, z którymi warto
          zaznajomić się przed wyjazdem. Nie musisz czytać dziesiątek artykułów
          - zebraliśmy tu wszystko!
        </h4>
      </section>
      <AccessibleTabsAdvices tabsConfig={advicesFirstTime} />

      <OtherAdvicesBoxLinks otherAdvicePages={otherAdvicePagesList} />
    </PageWrapperImageHead>
  );
};

export default GuideFirstTime;
