import React from 'react';
import BenefitCamper from './BenefitCamper';
import { Link } from 'react-router-dom';
import './BenefitsOurCampers.scss';
import iconBenefitBed from '../../assets/icons/benefits/icon_benefit_bed.svg';
import iconBenefitHeating from '../../assets/icons/benefits/icon_benefit_heating.svg';
import iconBenefitShower from '../../assets/icons/benefits/icon_benefit_shower.svg';
import iconBenefitKitchen from '../../assets/icons/benefits/icon_benefit_kitchen.svg';
import iconBenefitSolar from '../../assets/icons/benefits/icon_benefit_solar.svg';
import iconBenefitFurniture from '../../assets/icons/benefits/icon_benefit_furniture.svg';
const BenefitsOurCampers = () => {
  const benefitsList = [
    {
      benefitName: '4 wygodne miejsca do spania',
      benefitIcon: iconBenefitBed,
    },
    {
      benefitName: 'ogrzewanie postojowe',
      benefitIcon: iconBenefitHeating,
    },
    {
      benefitName: 'łazienka z prysznicem',
      benefitIcon: iconBenefitShower,
    },
    {
      benefitName: 'wyposażona kuchnia',
      benefitIcon: iconBenefitKitchen,
    },
    {
      benefitName: 'panel solarny',
      benefitIcon: iconBenefitSolar,
    },
    {
      benefitName: 'markiza z meblami',
      benefitIcon: iconBenefitFurniture,
    },
  ];
  return (
    <div className='container even-columns padding-block-400'>
      <div className='flex-center'>
        <h1 className='white-text ts-dblue'>
          Co oferują
          <br /> nasze kampery?
        </h1>
      </div>
      <div>
        <div className='benefits_our_campers__list'>
          {benefitsList.map((b, idx) => {
            return (
              <BenefitCamper
                key={idx}
                benefitName={b.benefitName}
                benefitIcon={b.benefitIcon}
              />
            );
          })}
        </div>

        <div className='center padding-block-600'>
          <Link
            to='/kampery'
            className='btn btn-big btn-primary btn__with-icon btn__our-cars'
          >
            Nasze kampery
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BenefitsOurCampers;
