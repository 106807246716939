import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import FormWrapper from '../../UI/Wrappers/FormWrapper/FormWrapper';
import pdfTermsRent from '../../../assets/docs/terms-camper-rent.pdf';
import pdfTermsService from '../../../assets/docs/terms-service-regulations.pdf';
import pdfTermsPrivacyPolicy from '../../../assets/docs/terms-privacy-policy.pdf';

import {
  selectEmail,
  selectFirstName,
  selectLastName,
  selectPhoneNumber,
  setEmail,
  setFirstName,
  setLastName,
  setPhoneNumber,
} from '../../../ducks/formSlice';
import BookingFormSummary from './BookingFormSummary';
import ScrollToTop from '../../../hoc/ScrollToTop';
import { Terms, TermsPrivacePolicy, TermsWebsite } from './Terms/Terms';

// type ContactData = {
//   firstName: string;
//   lastName: string;
//   phoneNumber: string;
//   email: string;
// };

const StepFormContact = () => {
  const dispatch = useAppDispatch();
  const firstName = useAppSelector(selectFirstName);
  const lastName = useAppSelector(selectLastName);
  const phoneNumber = useAppSelector(selectPhoneNumber);
  const email = useAppSelector(selectEmail);
  const [isValidSurname, setIsValidSurname] = useState(true);
  // validacja imienia
  const [isValidName, setIsValidName] = useState(true);
  const validateName = (name: string) => {
    const nameRegex = /^[A-Za-z]+$/;
    const minLengthCheck = name.length >= 2;
    return nameRegex.test(name) && minLengthCheck;
  };
  // walidacja nazwisk dwuczłonowych
  const validateSurname = (surname: string) => {
    console.log(surname);
    const surnameRegex = /^[\p{L}]+([- ]{1}[\p{L}]+)?$/u;
    const minLengthCheck = surname.length >= 2;

    return surnameRegex.test(surname) && minLengthCheck;
  };

  //terms
  const [isAccepted, setIsAccepted] = useState(false);
  const [isAcceptedRodo, setIsAcceptedRodo] = useState(false);
  const [isAcceptedTermsService, setIsAcceptedTermsServiceo] = useState(false);

  const handleCheckboxChange = (e: any) => {
    setIsAccepted(e.target.checked);
  };
  const handleCheckboxRodo = (e: any) => {
    setIsAcceptedRodo(e.target.checked);
  };
  const handleTermsService = (e: any) => {
    setIsAcceptedTermsServiceo(e.target.checked);
  };
  return (
    <FormWrapper title='Dane Kontaktowe'>
      <ScrollToTop />
      <section>
        <div className='even-columns'>
          <div className='booking-info'>
            <h3>Rezerwacja/zdanie kampera:</h3>
            <ul>
              <li>
                Wydanie auta odbywa się na terenie Wrocławia, na ulicy Prostej
                36, po godzinie 15, natomiast zwrot auta do godziny 11. Jeśli
                chcesz odebrać auto w innym miejscu lub o innych godzinach,
                wybierz odpowiednią lokalizację i godzinę, która Cię interesuje.
                Postaramy się spełnić Twoje potrzeby (dodatkowo płatne 200 zł +
                2 zł/km).
              </li>
              <li>
                Przy wydaniu auta przedstawimy Ci dokładnie, jak z niego
                korzystać i jak czerpać pełną radość z jego użytkowania.
              </li>
              <li>
                Pojazd jest gotowy do drogi - ma opróżnione zbiorniki wody
                szarej i kasetę WC, napełnione butle gazowe, zatankowane paliwo
                oraz AdBlue.
              </li>
              <li>
                Auta są ubezpieczone. W przypadku uszkodzenia pojazdu z winy
                wynajmującego czy udziału w szkodzie, szkoda pokrywana jest z
                kaucji, do jej maksymalnej wartości.
              </li>
              <li>
                Trzy dni przed terminem odbioru auta wpłacasz resztę kwoty
                wynajmu.
              </li>
              <li>
                Zdajesz kampera - powinien mieć opróżnione zbiorniki na wodę
                szarą i kasetę WC oraz być zatankowany do pełna.
              </li>
            </ul>
          </div>
          <div className='booking-info'>
            <h3>Warunki wynajmu:</h3>
            <ul>
              <li>
                Wypożyczenie dla osób powyżej 26 roku życia, z minimum 3 letnim
                prawem jazdy na kategorię B.
              </li>
              <li>Opłata rezerwacyjna 30% płatna przelewem.</li>
              <li>
                Kaucja 5 000 PLN i jej rozliczenie (w ciągu 5 dni) płatna
                gotówką w dniu odbioru pojazdu.
              </li>
              <li>Opłata serwisowa 350 zł.</li>
              <li>
                Reszta kwoty wynajmu płatna najpóźniej 3 dni przed odbiorem
                pojazdu.
              </li>
              <li>Regulamin najmu pojazdu.</li>
            </ul>
          </div>
        </div>
      </section>
      <div className='even-columns'>
        <div className='input-group__container'>
          <div className='input-group input-field input-field__large-width'>
            <input
              type='text'
              id='name'
              className='input-group__input'
              required
              value={firstName}
              onChange={(e) => {
                setIsValidName(validateName(e.target.value));
                dispatch(setFirstName(e.target.value));
              }}
            />
            <label htmlFor='name' className='input-group__label'>
              Imię
            </label>
            {!isValidName && (
              <small className='input-group__error'>
                Imię powinno się składać tylko z liter, i mieć minimum 2 litery.
              </small>
            )}
          </div>
          <div className='input-group input-field input-field__large-width'>
            <input
              type='text'
              id='lastname'
              className='input-group__input'
              required
              value={lastName}
              onChange={(e) => {
                setIsValidSurname(validateSurname(e.target.value));
                dispatch(setLastName(e.target.value));
              }}
            />
            <label htmlFor='lastname' className='input-group__label'>
              Nazwisko
            </label>
            {!isValidSurname && (
              <small className='input-group__error'>
                Nazwisko powinno składać się z min 2 liter, dozwolona jest
                przerwa/myślnik
              </small>
            )}
          </div>
          <div className='input-group input-field input-field__large-width'>
            <input
              type='tel'
              id='phone'
              className='input-group__input'
              required
              value={phoneNumber}
              onChange={(e) => dispatch(setPhoneNumber(e.target.value))}
            />
            <label htmlFor='phone' className='input-group__label'>
              Numer telefonu
            </label>
          </div>
          <div className='input-group input-field input-field__large-width'>
            <input
              type='email'
              id='email'
              className='input-group__input'
              required
              value={email}
              onChange={(e) => dispatch(setEmail(e.target.value))}
            />
            <label htmlFor='email' className='input-group__label'>
              Email
            </label>
          </div>
          <div className='terms-agreement'>
            <h4>
              Regulamin najmu pojazdu
              <sup>
                <a href={pdfTermsRent} title='pobierz plik pdf' download>
                  (pobierz)
                </a>
              </sup>
            </h4>
            <div className='terms-scroll-container'>
              <Terms />
            </div>
            <input
              type='checkbox'
              id='terms'
              checked={isAccepted}
              onChange={handleCheckboxChange}
              required
            />
            <label htmlFor='terms'>
              <span>Akceptuję warunki Regulaminu najmu pojazdu</span>
            </label>
            <h4>
              Polityka prywatności
              <sup>
                <a
                  href={pdfTermsPrivacyPolicy}
                  title='pobierz plik pdf'
                  download
                >
                  (pobierz)
                </a>
              </sup>
            </h4>
            <div className='terms-scroll-container'>
              <TermsPrivacePolicy />
            </div>
            <input
              type='checkbox'
              id='rodo'
              checked={isAcceptedRodo}
              onChange={handleCheckboxRodo}
              required
            />
            <label htmlFor='rodo'>
              <span>Wyrażam zgodę na przetwarzanie danych osobowych</span>
            </label>
            <div>
              <h4>
                Regulamin serwisu
                <sup>
                  <a href={pdfTermsService} title='pobierz plik pdf' download>
                    (pobierz)
                  </a>
                </sup>
              </h4>
              <div className='terms-scroll-container'>
                <TermsWebsite />
              </div>
              <input
                type='checkbox'
                id='termsService'
                checked={isAcceptedTermsService}
                onChange={handleTermsService}
                required
              />
              <label htmlFor='termsService'>
                <span>Akceptuję warunki Regulaminu Serwisu</span>
              </label>
            </div>
          </div>
        </div>
        <BookingFormSummary />
      </div>
    </FormWrapper>
  );
};
export default StepFormContact;
