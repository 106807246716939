import React from 'react';
import StarRating from '../component/StarRating/StarRating';
import WrapperSectionStyledHeader from '../component/UI/Wrappers/WrapperSection/WrapperSectionStyledHeader';
import PageWrapperImageHead from '../component/UI/Wrappers/PageWrapper/PageWrapperImageHead';
import imgHero from '../assets/images/faqs/img_hero_faq.jpg';

const PageRateUs = () => {
  return (
    <PageWrapperImageHead photo_brand={imgHero} pageTitle='Oceń nas'>
      <div className='booking-form'>
        <WrapperSectionStyledHeader sectionName='Kontakt'>
          {/* <StarRating /> */}
        </WrapperSectionStyledHeader>
      </div>
    </PageWrapperImageHead>
  );
};

export default PageRateUs;
