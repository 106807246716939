import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './ImageLinkBox.scss';
const ImageLinkBox = (props) => {
  const urlToPage = '/' + props.urlToPage;
  return (
    <>
      <Link
        to={urlToPage}
        className={`image-link-box `}
        style={{
          backgroundImage: `url(${props.pagePhoto})`,
        }}
      >
        <div className='image-link-box__name'>
          <h3>{props.pageName}</h3>
        </div>
      </Link>
    </>
  );
};

export default ImageLinkBox;
