import React from 'react';

type ImageDotsProps = {
  images: { urlImg: string; alt: string }[];
  imageIndex: number;
  setImageIndex: (index: number) => void;
};

const ImageSliderDots: React.FC<ImageDotsProps> = ({
  images,
  imageIndex,
  setImageIndex,
}) => {
  return (
    <div className='img-slider-dots'>
      {images.map((_, index) => (
        <button
          key={index}
          className={
            index === imageIndex
              ? 'img-slider-dot-btn img-slider-dot-btn__active'
              : 'img-slider-dot-btn'
          }
          aria-label={`View image ${index + 1}`}
          onClick={() => {
            setImageIndex(index);
          }}
        >
          <span className='img-slider-dot-btn__doted'></span>
        </button>
      ))}
    </div>
  );
};

export default ImageSliderDots;
