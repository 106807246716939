import React from 'react';
import CamperMoreInfo from '../../component/CamperMoreInfo/CamperMoreInfo';
import { camperDetailsHoronOverCab71M } from '../../constants/constantsCampers';

const CarCardHuronOvercab71M = () => {
  return <div></div>;
  //  <CamperMoreInfo props={camperDetailsHoronOverCab71M}></CamperMoreInfo>;
};

export default CarCardHuronOvercab71M;
