import React from 'react';
import { iconsContactFormFaqs } from '../constants/constants';
import { AccessibleTabsFaqs } from '../component/AccessibleTabs/AccessibleTabs';
import { faqsList } from '../constants/constantsFaqs';
import imgHero from '../assets/images/faqs/img_hero_faq.jpg';

import './Faq.scss';
import PageWrapperImageHead from '../component/UI/Wrappers/PageWrapper/PageWrapperImageHead';
const Faq = () => {
  return (
    <React.Fragment>
      <PageWrapperImageHead photo_brand={imgHero} pageTitle='Dobrze wiedzieć'>
        <AccessibleTabsFaqs tabsConfig={faqsList} />
      </PageWrapperImageHead>
      <div className='faqs_brand_bottom'>
        <h3>
          Skontaktuj się z nami
          <br /> w każdej sprawie,
          <br /> w sposób, który Ci odpowiada.
        </h3>
        <p className='text-center padding-block-400 faqs_brand_bottom-description'>
          Jesteśmy dostępni od poniedziałku do piątku
          <br /> w godzinach <time className='time'>09:00</time> -{' '}
          <time className='time'>17:00</time>. <br />
          Zachęcamy do{' '}
          <span className='callme360live-button-show'>
            <a className='m-btn2 underline-bottom'>pozostawienia numeru.</a>
          </span>
          <br /> Oddzwonimy do Ciebie w godzinach naszej pracy.
        </p>
        <div className='contact-box-list container'>
          {iconsContactFormFaqs.map((i) => {
            return i.id === '0' ? (
              <div key={i.id} className={`callme360live-button-show`}>
                <a className='m-btn2'>
                  <div className='contact-box'>
                    <div className='contact-box-icon'>
                      <img src={i.icon} alt={i.icon} />
                    </div>
                    <div className='contact-box-name'>
                      <p>{i.name}</p>
                    </div>
                  </div>
                </a>
              </div>
            ) : i.id === '1' ? (
              <div key={i.id} className={`callme360-widget-chat`}>
                <div className='contact-box'>
                  <div className='contact-box-icon'>
                    <img src={i.icon} alt={i.icon} />
                  </div>
                  <div className='contact-box-name'>
                    <p>{i.name}</p>
                  </div>
                </div>
              </div>
            ) : i.id === '2' ? (
              <a key={i.id} href='mailto:kontakt@ikifriki.com'>
                <div className='contact-box'>
                  <div className='contact-box-icon'>
                    <img src={i.icon} alt={i.icon} />
                  </div>
                  <div className='contact-box-name'>
                    <p>{i.name}</p>
                  </div>
                </div>
              </a>
            ) : i.id === '3' ? (
              <a key={i.id} href='tel:+48519742598'>
                <div className='contact-box'>
                  <div className='contact-box-icon'>
                    <img src={i.icon} alt={i.icon} />
                  </div>
                  <div className='contact-box-name'>
                    <p>{i.name}</p>
                  </div>
                </div>
              </a>
            ) : null;
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Faq;
