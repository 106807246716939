import React from "react";
import FormWrapper from "../../UI/Wrappers/FormWrapper/FormWrapper";
import AccesoriesRadioSelect from "./AccesoriesRadioSelect";
import { accesoriesList } from "../../../constants/constants";
import BookingFormSummary from "./BookingFormSummary";

const StepFormAccesories = () => {
  return (
    <FormWrapper title="Usługi dodatkowe">
      <div className="even-columns">
        <div className="accessories-form-list">
          {accesoriesList.map((a, idx) => {
            return (
              <AccesoriesRadioSelect
                accesoriesID={idx}
                key={idx}
                nameAccesoriesInput={a.nameAccesoriesInput}
                accesoriessIcon={a.accesoriessIcon}
                accesoriesOptions={a.accesoriesOptions}
                name={a.accesoriesName}
                description={a.description}
                pricePerItem={a.pricePerItem}
              />
            );
          })}
        </div>
        <BookingFormSummary/>
      </div>
    </FormWrapper>
  );
};

export default StepFormAccesories;
