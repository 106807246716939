import React from 'react';
import ImageLinkBox from '../LinkBox/ImageLinkBox';
import WrapperSectionStyledHeader from '../UI/Wrappers/WrapperSection/WrapperSectionStyledHeader';
import './OtherAdvicesBoxLinks.scss';

const OtherAdvicesBoxLinks = (props) => {
  return (
    <WrapperSectionStyledHeader sectionName='Inne poradniki'>
      <div className='other-advices-links-boxes'>
        {props.otherAdvicePages.map((a, idx) => {
          return (
            <ImageLinkBox
              key={idx}
              pageName={a.pageName}
              urlToPage={a.urlToPage}
              pagePhoto={a.pagePhoto}
            />
          );
        })}
      </div>
    </WrapperSectionStyledHeader>
  );
};

export default OtherAdvicesBoxLinks;
