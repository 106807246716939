import React from 'react';
import PageWrapperImageHead from '../component/UI/Wrappers/PageWrapper/PageWrapperImageHead';
import './AboutUs.scss';
import { Icon } from '@iconify/react';
import imgHero from '../assets/images/about-us/img_hero_about_us.jpg';
// import aboutImage from "../assets/images/about-us/trip.jpg";
// import aboutImageSecond from "../assets/images/about-us/imgHero.jpg";
// import aboutImageThird from "../assets/images/about-us/person.jpg";
const AboutUs = () => {
  return (
    <PageWrapperImageHead photo_brand={imgHero} pageTitle='O nas'>
      <section>
        <div className='split stretch'>
          <div className='about-us-info__brand'></div>
          <div className='about-us-info__description'>
            <h3>Ikifriki</h3>
            <p>
              to projekt założony z serca i pasji. Wszystko co robimy, robimy
              dla Ciebie. Naszym celem jest zapewnienie Ci wyjątkowych
              doświadczeń, które będą wyłącznie Twoje. <br />
              Jeśli chcesz spróbować jakiegoś sportu - podpowiemy, jak to
              zorganizować. <br />
              Chętnie pomożemy Ci zaplanować lekcje surfingu, kitesurfingu,
              paddlingu, narciarstwa, snowboardu. Znamy fajne miejsca - te
              popularne i te tajemnicze. Sami to wszystko robimy, znamy miejsca
              i ludzi, więc chętnie podzielimy się z Tobą naszym światem.
              <br />
              <br />
              Z otwartymi ramionami witamy weteranów turystyki niezależnej. Z
              zainteresowaniem wysłuchamy rad i uwag, które mogą wzbogacić naszą
              ofertę.
              <br />
              <br />
              Czekamy również na każdego, kto chce spróbować swoich sił w
              karawaningu po raz pierwszy i ruszyć własną drogą. Pomożemy
              rozwiać wątpliwości, czy to forma wypoczynku dla Ciebie.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div className='split stretch'>
          <div className='about-us-what-makes-us-unique__info'>
            <div className='flex-center-h'>
              <h3>Co nas wyróżnia?</h3>
            </div>
            <p>
              Dbamy o Twój komfort - to dla nas priorytet. Jesteś dla nas
              niezwykle ważny, dlatego oferujemy Ci:
            </p>
            <ul>
              <li>
                <strong>Jakość</strong> - nasze nowoczesne i w pełni wyposażone
                auta są regularnie serwisowane, zapewnią Ci pewność, że podróż
                będzie bezproblemowa.
              </li>
              <li>
                <strong>Prostota</strong> - przeprowadzimy Cię sprawnie i
                zrozumiale przez instruktaż obsługi samochodu. Pokażemy, że
                korzystanie z kampera to naprawdę łatwe i przyjemne
                doświadczenie.
              </li>
              <li>
                <strong>Wolność</strong> - nie ma u nas limitu kilometrów. Jedź
                dokąd chcesz (tylko do nas wróć :)).
              </li>
              <li>
                <strong>Wygoda</strong> - za dodatkową opłatą możemy dostarczyć
                kampera i odebrać go z dowolnego miejsca. Po prostu daj znać, co
                Cię interesuje, a na pewno się dogadamy.
              </li>
              <li>
                <strong>Spokój</strong> – nasze auta są odpowiednio
                ubezpieczone, więc nie musisz się martwić o nieplanowane
                sytuacje.
              </li>
              <li>
                <strong>Bezpieczeństwo</strong> – jeśli podróżujesz z dziećmi,
                możemy udostępnić Ci foteliki, dbając o komfort i bezpieczeństwo
                Twoich pociech.
              </li>
              <li>
                <strong>Inspiracja</strong> - chętnie pomożemy Ci w
                przygotowaniu podróży, udzielając porad na temat ciekawych
                miejsc, noclegów i atrakcji w okolicy.
              </li>
              <li>
                <strong> Aktywność</strong> - w naszej ofercie znajdziesz
                również sprzęt sportowy i kempingowy, abyś mógł aktywnie spędzać
                czas na świeżym powietrzu.
              </li>
              <li>
                <strong>Bez granic</strong> – nasze auta pozwalają na
                podróżowanie po całej Unii Europejskiej, Strefie Schengen,
                Bałkanach (z wyjątkiem Serbii), Skandynawii, a nawet Maroku.
                Jesteś tam ubezpieczony, co daje Ci większą swobodę
                podróżowania. Należy pamiętać, że naszymi autami nie wolno
                wjeżdżać do krajów objętych konfliktem oraz do Rosji i
                Białorusi.
              </li>
              <li>
                <strong>Jeden za jeden</strong> - jeśli przyjedziesz do nas
                własnym autem, masz możliwość pozostawienia go na naszym
                monitorowanym parkingu.
              </li>
              <li>
                <strong>Dostępność</strong> - jesteśmy łatwo dostępni pod
                telefonem i na czacie. Oferujemy również unikalną usługę
                videoczatu Callme 360 Live, abyś mógł skontaktować się z nami
                bez zbędnego zachodu.
                <span className='callme360live-button-show'>
                  <a className='m-btn2 widget-text-link' title='otwórz widget'>
                    {' '}
                    Sprawdź jakie to proste.
                  </a>
                </span>
              </li>
            </ul>
          </div>
          <div className='about-us-what-makes-us-unique__photos'>
            <div className='about-us-what-makes-us-unique__photo first-photo'></div>
            <div className='about-us-what-makes-us-unique__photo second-photo'></div>
          </div>
        </div>
      </section>

      <div className='about-us-place-and-time container even-columns'>
        <div className='about-us-place-and-time__info'>
          <div className='about-us-block'>
            <h4>Gdzie jesteśmy?</h4>
            <div className='about-us-place-and-time__info-block'>
              <div>
                <Icon
                  icon='mdi:map-marker-outline'
                  color='#e07000'
                  width='48'
                  height='48'
                />
              </div>
              <div className='list-section'>
                <h5>
                  <address>
                    ul. Prosta 36
                    <br />
                    53-508 Wrocław
                  </address>
                </h5>
              </div>
            </div>
          </div>
          <div className='about-us-block'>
            <h4>Godziny otwarcia:</h4>
            <div className='about-us-place-and-time__info-block'>
              <div>
                <Icon
                  icon='mdi:calendar-clock-outline'
                  color='#e07000'
                  width='48'
                  height='48'
                />
              </div>

              <div className='list-section'>
                <div>
                  <h5>poniedziałek - piątek</h5>
                  <ul>
                    <li>9:00 - 17:00 </li>
                  </ul>
                  <h5>sobota - niedziela</h5>
                  <ul>
                    <li>
                      <a
                        href='mailto:kontakt@ikifriki.com'
                        className='widget-text-link'
                      >
                        napisz do nas
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='about-us-place-and-time__map'>
          <iframe
            title='our location'
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2505.285089362101!2d17.020896199999996!3d51.1032!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470fc20d60ae0f57%3A0xdd470f0e421b10fb!2sProsta%2036%2C%2053-505%20Wroc%C5%82aw!5e0!3m2!1spl!2spl!4v1691753896883!5m2!1spl!2spl'
            // width="600"
            // height="450"
            style={{ border: '0', borderRadius: '20px' }}
            // allowFullScreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
          ></iframe>
        </div>
      </div>
    </PageWrapperImageHead>
  );
};

export default AboutUs;
