import React, { useState, useRef } from 'react';
import './ImageSlider.scss';
import { Icon } from '@iconify/react';
import ImageSliderDots from './ImageSliderDots';

type ImageSliderProps = {
  images: { urlImg: string; alt: string }[];
};

const ImageSlider = ({ images }: ImageSliderProps) => {
  const [imageIndex, setImageIndex] = useState(0);
  const [touchStartX, setTouchStartX] = useState<number | null>(null);
  const [lastMoveTime, setLastMoveTime] = useState<number | null>(null);

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    const currentTime = Date.now();
    if (lastMoveTime && currentTime - lastMoveTime < 500) return;
    setLastMoveTime(currentTime);

    const touchEndX = e.changedTouches[0].clientX;
    const touchDistance = touchStartX! - touchEndX;

    if (touchDistance > 100) {
      showNextImage();
    } else if (touchDistance < -100) {
      showPrevImage();
    }
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setTouchStartX(e.clientX);
  };

  const handleMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
    const touchEndX = e.clientX;
    const touchDistance = touchStartX! - touchEndX;

    if (touchDistance > 100) {
      showNextImage();
    } else if (touchDistance < -100) {
      showPrevImage();
    }
  };

  const showPrevImage = () => {
    setImageIndex((index) => {
      if (index === 0) return images.length - 1;
      return index - 1;
    });
  };

  const showNextImage = () => {
    setImageIndex((index) => {
      if (index === images.length - 1) return 0;
      return index + 1;
    });
  };

  return (
    <div
      aria-label='Image Slider'
      style={{
        width: '100%',
        aspectRatio: '10/6',
        maxHeight: '600px',
      }}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        {images.map(({ urlImg, alt }, index) => (
          <img
            key={urlImg}
            src={urlImg}
            alt={alt}
            aria-hidden={imageIndex !== index}
            className='image-slider-img'
            style={{ translate: `${-100 * imageIndex}%` }}
            draggable='false'
            onDragStart={(e) => e.preventDefault()}
          />
        ))}
        <button
          onClick={showPrevImage}
          className='img-slider-btn'
          style={{ left: 0 }}
          aria-label='View Previous Image'
        >
          <Icon
            icon='majesticons:chevron-left-line'
            color='#e07000'
            width='40'
            height='40'
            aria-hidden='true'
          />
        </button>
        <button
          className='img-slider-btn'
          onClick={showNextImage}
          style={{ right: 0 }}
          aria-label='View Next Image'
        >
          <Icon
            icon='majesticons:chevron-right'
            color='#e07000'
            width='40'
            height='40'
            aria-hidden='true'
          />
        </button>
      </div>

      <ImageSliderDots
        images={images}
        imageIndex={imageIndex}
        setImageIndex={setImageIndex}
      />
    </div>
  );
};

export default ImageSlider;
