import React from 'react';
import { Icon } from '@iconify/react';

interface IconIifyIconProps {
  name: string;
  clr: string;
  w: number;
  h: number;
  rot?: number;
}

const IconIifyIcon: React.FC<IconIifyIconProps> = ({
  name,
  clr,
  w,
  h,
  rot,
}) => {
  return <Icon icon={name} color={clr} width={w} height={h} rotate={rot} />;
};

export default IconIifyIcon;
