import React, { useState } from 'react';
import AccorditionSwitchTypeItem from './AccordionSwitchTypeItem';
import '../Accordion.scss';

interface AccordionSwitchTypeListProps {
  faqs: any[]; // Replace 'any' with the actual type of your 'faqs' prop
}

const AccordionSwitchTypeList: React.FC<AccordionSwitchTypeListProps> = (
  props
) => {
  const [active, setActive] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  return (
    <article className='acorditions-list'>
      {props.faqs.map((accordionItem, index) => {
        return (
          <AccorditionSwitchTypeItem
            key={index}
            active={active}
            handleToggle={handleToggle}
            accordionItem={accordionItem}
          />
        );
      })}
    </article>
  );
};

export default AccordionSwitchTypeList;
