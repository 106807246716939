import logoIkifriki from '../assets/ikifriki-logo.svg';
import heroImage from '../assets/images/guides/guide-first-time-hero.jpg';
import guideChildrenPhoto from '../assets/images/guides/guide_children_photo.jpg';
import guideSafetyPhoto from '../assets/images/guides//guide_safety_photo.jpg';
import guidePackPhoto from '../assets/images/guides/guide_pack_guide_photo.jpg';
import accessoriesIconSunbed from '../assets/icons/accessories/accessories-icon-sun-bed.png';
import accessoriesIconSunUmbera from '../assets/icons/accessories/accessories-icon-sun-umbrella.png';
import accessoriesIconScreen from '../assets/icons/accessories/accessories-icon-screen.png';
import accessoriesHammock from '../assets/icons/accessories/accessories-icon-hammock.png';
import accessoriesIconSup from '../assets/icons/accessories/accessories-icon-sup.png';
import accessoriesIconLongboard from '../assets/icons/accessories/accessories-icon-longboard.png';
import accessoriesIconElectricScoorer from '../assets/icons/accessories/accessories-icon-electric-scooter.png';
import accessoriesIconBabySeat from '../assets/icons/accessories/accessories-icon-babyseat.png';
import accessoriesIconSeat from '../assets/icons/accessories/accessories-icon-seat.png';
import accessoriesIconSpeakerJBL from '../assets/icons/accessories/accessories-icon-speaker.png';
import accessoriesIconTowel from '../assets/icons/accessories/accessories-icon-towel.png';
import accessoriesIconParking from '../assets/icons/accessories/accessories-icon-parking.png';
import accessoriesIconBedding from '../assets/icons/accessories/accessories-icon-bedding.png';
import accessoriesIconBackpack from '../assets/icons/accessories/accessories-icon-backpack.png';
//formstep
import { IContactFormList, IIconsList } from '../types/types';
// icons for steps booking
import iconBookingFormStep1 from '../assets/icons/booking-form/booking-form-step-01.png';
import iconBookingFormStep2 from '../assets/icons/booking-form/booking-form-step-02.png';
import iconBookingFormStep3 from '../assets/icons/booking-form/booking-form-step-03.png';
import iconBookingFormStep4 from '../assets/icons/booking-form/booking-form-step-04.png';
import iconBookingFormStep1Active from '../assets/icons/booking-form/booking-form-step-01-active.png';
import iconBookingFormStep2Active from '../assets/icons/booking-form/booking-form-step-02-active.png';
import iconBookingFormStep3Active from '../assets/icons/booking-form/booking-form-step-03-active.png';
import iconBookingFormStep4Active from '../assets/icons/booking-form/booking-form-step-04-active.png';
// contact icons for faqs bottom section
import iconContactFormCall from '../assets/icons/contact-form/svg-contact-form-call.svg';
import iconContactFormVideoChat from '../assets/icons/contact-form/svg-contact-form-video-chat.svg';
import iconContactFormChat from '../assets/icons/contact-form/svg-contact-form-chat.svg';
import iconContactFormMail from '../assets/icons/contact-form/svg-contact-form-mail.svg';
// docs
import pdfTermsRent from '../assets/docs/terms-camper-rent.pdf';
import pdfTermsService from '../assets/docs/terms-service-regulations.pdf';
import pdfTermsPrivacyPolicy from '../assets/docs/terms-privacy-policy.pdf';
import pdfPricing from '../assets/docs/pricing.pdf';

export const logo = logoIkifriki;
export const docsPdfTerms = [
  pdfTermsService,
  pdfTermsRent,
  pdfPricing,
  pdfTermsPrivacyPolicy,
];
// faqs contact forms bottom list
export const iconsContactFormFaqs: IContactFormList[] = [
  { id: '0', icon: iconContactFormVideoChat, name: 'videorozmowa' },
  { id: '1', icon: iconContactFormChat, name: 'chat\ntekstowy' },
  { id: '2', icon: iconContactFormMail, name: 'e-mail' },
  { id: '3', icon: iconContactFormCall, name: 'rozmowa\ntelefoniczna' },
];

// icons for steps booking
export const iconsBookingFormSteps: IIconsList[] = [
  {
    id: '0',
    icon: iconBookingFormStep1,
    activeIcon: iconBookingFormStep1Active,
    title: 'Gdzie i kiedy? ',
    subTitle: 'Wybierz miejsce/date',
  },
  {
    id: '1',
    icon: iconBookingFormStep2,
    activeIcon: iconBookingFormStep2Active,
    title: 'Pojazd',
    subTitle: 'Wybierz kamper',
  },
  {
    id: '2',
    icon: iconBookingFormStep3,
    activeIcon: iconBookingFormStep3Active,
    title: 'Pozostałe',
    subTitle: 'Usługi dodatkowe',
  },
  {
    id: '3',
    icon: iconBookingFormStep4,
    activeIcon: iconBookingFormStep4Active,
    title: 'Dane kontaktowe',
    subTitle: 'Uzupełnij',
  },
];
//
export const heroImg = heroImage;
export const adviceList = [
  {
    adviceNumber: 1,
    adviceName: 'Zaangażuj swoje dzieci w planowanie',
    adviceInfo:
      'Szukasz sposobu na uniknięcie ciągłego pytania "ile jeszcze!!!!????" Zaangażuj swoje dzieci w planowanie wycieczki Kamperem. Pozwól im mieć wpływ na to, dokąd się udacie i jakie aktywności podejmiecie. Jeśli twoje dzieci są trochę starsze, daj im wolność i odpowiedzialność, pozwalając im zaplanować całą wycieczkę. Im bardziej twoje dzieci są zaangażowane w to, co robisz, tym bardziej to pokochają. Szczególnie prawdziwe jest to, jeśli masz nastolatka, który jest niechętny do podróży z rodziną.',
    adviceImage: heroImage,
  },
];
export const otherAdvicePagesList = [
  {
    pageName: 'Z dziećmi',
    urlToPage: 'poradniki/z-dziecmi',
    pagePhoto: guideChildrenPhoto,
  },
  {
    pageName: 'Bezpieczeństwo',
    urlToPage: 'poradniki/bezpieczenstwo',
    pagePhoto: guideSafetyPhoto,
  },
  {
    pageName: 'Co spakować?',
    urlToPage: 'poradniki/co-spakowac',
    pagePhoto: guidePackPhoto,
  },
];

export const accesoriesList = [
  {
    accesoriesID: 0,
    accesoriesName: 'Parking',
    description:
      'Darmowy monitorowany parking to wygodna opcja, która umożliwia Ci bezpieczne pozostawienie samochodu na czas podróży kamperem. Nie martw się o opłaty parkingowe i ciesz się spokojem w trakcie swojej wyprawy.',
    nameAccesoriesInput: 'accessoriesParking',
    accesoriessIcon: accessoriesIconParking,
    pricePerItem: 0,
    accesoriesOptions: [
      { id: 0, amount: 0 },
      { id: 1, amount: 1 },
    ],
  },
  {
    accesoriesID: 1,
    accesoriesName: 'Hamak',
    description:
      'Hamak to idealna opcja dla miłośników relaksu i natury. Zawieś go między drzewami lub specjalnymi podporami, by cieszyć się błogim wypoczynkiem na świeżym powietrzu, kiedy tylko chcesz.',
    nameAccesoriesInput: 'accessoriesHammock',
    accesoriessIcon: accessoriesHammock,
    pricePerItem: 6.99,
    accesoriesOptions: [
      { id: 0, amount: 0 },
      { id: 1, amount: 1 },
      { id: 2, amount: 2 },
      { id: 3, amount: 3 },
    ],
  },
  {
    accesoriesID: 2,
    accesoriesName: 'Leżak - komplet (2 sztuki)',
    description:
      'Ciesz się słońcem i świeżym powietrzem w komfortowym stylu. Czytaj książki, słuchaj muzyki, opalaj się lub zdrzemnij, gdy tylko przyjdzie ochota. Leżaki są łatwe do złożenia i przenoszenia, idealne do odpoczynku na plaży, przy jeziorze czy w górach.',
    nameAccesoriesInput: 'accessoriesSunbed',
    accesoriessIcon: accessoriesIconSunbed,
    pricePerItem: 14.99,
    accesoriesOptions: [
      { id: 0, amount: 0 },
      { id: 1, amount: 1 },
      { id: 2, amount: 2 },
      { id: 3, amount: 3 },
    ],
  },
  {
    accesoriesID: 3,
    accesoriesName: 'Parasol',
    description:
      'Zabezpiecz się przed palącym słońcem, wypożyczając parasol przeciwsłoneczny. Ciesz się chłodnym cieniem i komfortem podczas odpoczynku na świeżym powietrzu, niezależnie od miejsca, w którym się zatrzymasz. Składany i łatwy do przenoszenia, idealny dla Twojej kamperowej przygody!',
    nameAccesoriesInput: 'accessoriesSunUmbera',
    accesoriessIcon: accessoriesIconSunUmbera,
    pricePerItem: 6.99,
    accesoriesOptions: [
      { id: 0, amount: 0 },
      { id: 1, amount: 1 },
      { id: 2, amount: 2 },
      { id: 3, amount: 3 },
    ],
  },
  {
    accesoriesID: 4,
    accesoriesName: 'Parawan',
    description:
      'Stwórz swój prywatny kącik na plaży wybierając parawan. Ochroni Cię przed wiatrem i ciekawskimi spojrzeniami, tworząc wyjątkową przestrzeń do odpoczynku i zabawy. Składany i łatwy do przenoszenia, doskonały towarzysz dla całej rodziny.',
    nameAccesoriesInput: 'accessoriesScreen',
    accesoriessIcon: accessoriesIconScreen,
    pricePerItem: 6.99,
    accesoriesOptions: [
      { id: 0, amount: 0 },
      { id: 1, amount: 1 },
      { id: 2, amount: 2 },
      { id: 3, amount: 3 },
    ],
  },
  {
    accesoriesID: 5,
    accesoriesName: 'Hulajnoga elektryczna',
    description:
      'Wypróbuj nowoczesny sposób zwiedzania okolicy, wypożyczając naszą hulajnogę elektryczną. Szybko przemieszczaj się po mieście czy okolicznych atrakcjach, unikając korków i oszczędzając czas. Łatwa w obsłudze i ekologiczna opcja dla każdego aktywnego podróżnika.',
    nameAccesoriesInput: 'accessoriesEletricScooter',
    accesoriessIcon: accessoriesIconElectricScoorer,
    pricePerItem: 29.99,
    accesoriesOptions: [
      { id: 0, amount: 0 },
      { id: 1, amount: 1 },
      { id: 2, amount: 2 },
      { id: 3, amount: 3 },
    ],
  },
  {
    accesoriesID: 6,
    accesoriesName: 'Longboard',
    description:
      'Idealna opcja dla miłośników desek. Longboard pozwoli na surfowanie po miejskich promenadach, ścieżkach rowerowych i innych płaskich obszarach. Dodaj aktywną rozrywkę dzięki temu stylowemu i ekscytującemu środkowi transportu.',
    nameAccesoriesInput: 'accessoriesLongboard',
    accesoriessIcon: accessoriesIconLongboard,
    pricePerItem: 14.99,
    accesoriesOptions: [
      { id: 0, amount: 0 },
      { id: 1, amount: 1 },
      { id: 2, amount: 2 },
      { id: 3, amount: 3 },
    ],
  },

  {
    accesoriesID: 7,
    accesoriesName: 'Sup',
    description:
      'Odkryj nowy wymiar aktywności na wodzie, wypożyczając SUP. Stań na desce i wiosłuj po jeziorach, rzekach czy morzu, ciesząc się widokiem z perspektywy wody. Doskonały sposób na trening, relaks i kontakt z naturą. Na co czekasz? Siup na SUP!',
    nameAccesoriesInput: 'accessoriesSup',
    accesoriessIcon: accessoriesIconSup,
    pricePerItem: 39.99,
    accesoriesOptions: [
      { id: 0, amount: 0 },
      { id: 1, amount: 1 },
      { id: 2, amount: 2 },
      { id: 3, amount: 3 },
    ],
  },

  {
    accesoriesID: 8,
    accesoriesName: 'Plecak kempingowy',
    description:
      'Wygodny i funkcjonalny, pomieści niezbędny sprzęt i zapasy na długie wędrówki lub obozowanie. Wiele kieszeni i regulacji zapewni Ci wygodę noszenia, podczas gdy wzmocnienia termo-ochronne zabezpieczą Twój ekwipunek.',
    nameAccesoriesInput: 'accessoriesBacpack',
    accesoriessIcon: accessoriesIconBackpack,
    pricePerItem: 7.99,
    accesoriesOptions: [
      { id: 0, amount: 0 },
      { id: 1, amount: 1 },
      { id: 2, amount: 2 },
      { id: 3, amount: 3 },
    ],
  },
  {
    accesoriesID: 9,
    accesoriesName: 'Fotelik',
    description:
      'Bezpieczeństwo i wygoda Twojego małego podróżnika to priorytet. Nasz fotelik dla dziecka zapewni niezawodną ochronę podczas podróży kamperem. Zapewnij maluchowi wygodne miejsce do siedzenia i ciesz się spokojem w drodze.',
    nameAccesoriesInput: 'accessoriesBabySeat',
    accesoriessIcon: accessoriesIconBabySeat,
    pricePerItem: 29.99,
    accesoriesOptions: [
      { id: 0, amount: 0 },
      { id: 1, amount: 1 },
      { id: 2, amount: 2 },
      { id: 3, amount: 3 },
    ],
  },
  {
    accesoriesID: 10,
    accesoriesName: 'Siedzisko dziecięce',
    description:
      'Daj swojemu dziecku wygodę i bezpieczeństwo w podróży, wypożyczając podstawkę samochodową. Odpowiednia dla starszych dzieci - zapewni prawidłową pozycję podczas jazdy. Niezastąpione rozwiązanie, gdy dzieci dorastają i przechodzą z fotelika na tradycyjne siedzenie w samochodzie.',
    nameAccesoriesInput: 'accessoriesSeat',
    accesoriessIcon: accessoriesIconSeat,
    pricePerItem: 3.99,
    accesoriesOptions: [
      { id: 0, amount: 0 },
      { id: 1, amount: 1 },
      { id: 2, amount: 2 },
      { id: 3, amount: 3 },
    ],
  },
  {
    accesoriesID: 11,
    accesoriesName: 'Głośnik JBL Charge 4',
    description:
      'Ulubiona muzyka podczas plażowania, czy wspólnego wieczoru jest niezbędna! Nasz głośnik JBL oferuje doskonały dźwięk w kompaktowym wydaniu. Łatwo łączy się z urządzeniami mobilnymi i pozwala cieszyć się ulubioną muzyką w każdym zakątku podczas podróży.',
    nameAccesoriesInput: 'accessoriesSpeakerJBL',
    accesoriessIcon: accessoriesIconSpeakerJBL,
    pricePerItem: 13.99,
    accesoriesOptions: [
      { id: 0, amount: 0 },
      { id: 1, amount: 1 },
    ],
  },
  {
    accesoriesID: 12,
    accesoriesName: 'Pościel - komplet',
    description:
      'Nie lubisz na wakacjach zaprzątać sobie głowy obowiązkami? Zrobimy to za ciebie.',
    nameAccesoriesInput: 'accessoriesBedding',
    accesoriessIcon: accessoriesIconBedding,
    pricePerItem: 29.99,
    accesoriesOptions: [
      { id: 0, amount: 0 },
      { id: 1, amount: 1 },
      { id: 2, amount: 2 },
      { id: 3, amount: 3 },
      { id: 4, amount: 4 },
      { id: 5, amount: 5 },
    ],
  },
  {
    accesoriesID: 13,
    accesoriesName: 'Ręcznik - komplet (mały i duży)',
    description: 'Boisz się, że o nich zapomnisz? Pozwól nam o to zadbać.',
    nameAccesoriesInput: 'accessoriesTowel',
    accesoriessIcon: accessoriesIconTowel,
    pricePerItem: 14.99,
    accesoriesOptions: [
      { id: 0, amount: 0 },
      { id: 1, amount: 1 },
      { id: 2, amount: 2 },
      { id: 3, amount: 3 },
      { id: 4, amount: 4 },
      { id: 5, amount: 5 },
    ],
  },
];
