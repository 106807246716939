import React from 'react';
import { Link } from 'react-router-dom';
import './CamperCard.scss';
import { Icon } from '@iconify/react';
interface Props {
  camperModel: string;
  camperAmountPerson: string;
  camperAmountBeds: string;
  camperMainImage: string;
  camperDescription: string;
  camperUrlShowMoreInfo: string;
  camperHirePrice: Array<number>;
}
const CamperCard = ({
  camperMainImage,
  camperDescription,
  camperModel,
  camperAmountPerson,
  camperHirePrice,
  camperAmountBeds,
  camperUrlShowMoreInfo,
}: Props) => {
  const urlToPage = '/' + camperUrlShowMoreInfo;
  return (
    <Link to={urlToPage} className='btn-unset'>
      <div className='camper-card'>
        <div
          className='camper-card__img'
          style={{
            backgroundImage: `url(${camperMainImage})`,
          }}
        ></div>
        <div className='camper-card__content'>
          <div className='camper-card__amounts'>
            <Icon
              icon='tabler:bed-filled'
              color='#e07000'
              width='24'
              height='24'
            />
            <span>{camperAmountBeds}</span>
            <Icon
              icon='fluent:people-community-20-filled'
              color='#e07000'
              width='20'
              height='20'
            />
            <span>{camperAmountPerson}</span>
          </div>
          <h3>{camperModel}</h3>
          <div className='flex-center-h pad-left-300 padding-block-100'>
            <Icon
              icon='material-symbols:joystick'
              color='#e07000'
              width='24'
              height='24'
            />
            <span>
              {`${
                camperDescription.charAt(0).toUpperCase() +
                camperDescription.slice(1)
              }  skrzynia biegów`}
            </span>
          </div>
          <div className='camper-card__price'>
            <h4>
              Cena od
              <span className='text-orange'> {camperHirePrice[0]} </span>
              <span className='text-small'> zł / doba</span>
            </h4>
          </div>

          <Icon
            className='move-to-icon'
            icon='majesticons:arrow-right'
            color='#e07000'
            width='32'
            height='32'
          />
        </div>
      </div>
    </Link>
  );
};

export default CamperCard;
