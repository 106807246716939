import React from 'react';
import './BenefitCamper.scss';

const BenefitCamper = (props) => {
  return (
    <div className='camper-benefit'>
      <div className='camper-benefit__icon'>
        <img src={props.benefitIcon} alt={props.benefitName} />
      </div>
      <div className='camper-benefit__name'>
        <h3>{props.benefitName}</h3>
      </div>
    </div>
  );
};

export default BenefitCamper;
