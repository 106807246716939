import React from 'react';
import './CamperSeasonPrice.scss';

const CamperSeasonPrice = (props) => {
  return (
    <div
      className={`camper-season-price ${props.actualSeason && 'shadow-orange'}`}
    >
      {props.actualSeason && (
        <small className='actual-season-label'>bieżący sezon</small>
      )}
      <div
        className='camper-season-price__icon'
        style={{
          backgroundImage: `url(${props.seasonIcon})`,
        }}
      ></div>
      <div className='camper-season-price__description'>
        <h4>Sezon {props.seasonName}</h4>
        <h5>
          Stawka: <span>{props.priceForCamperPerSeason}</span> PLN
        </h5>

        <p>Sezon obowiązuje:</p>
        <ul className='list-justify'>
          {props.seasonRange.map((r, idx) => {
            return <li key={idx}>{r}</li>;
          })}
        </ul>
        <p>
          Minimalny okres wynajmu
          {<span className='text-orange'> {props.minimalRentRange}</span>} dni.
        </p>
      </div>
    </div>
  );
};

export default CamperSeasonPrice;
