import {
  HashRouter as Router,
  Route,
  Routes,
  // useLocation,
} from 'react-router-dom';
import ScrollToTop from './hoc/ScrollToTop';
import Navbar from './component/UI/Navbar/Navbar';
import Footer from './component/UI/Footer/Footer';
import Landing from './pages/Landing';
import AboutUs from './pages/AboutUs';
import OurCars from './pages/OurCars';
import CarCardFordRoller266TL from './pages/our-cars/CarCardFordRoller266TL';
import CarCardFordRoller298 from './pages/our-cars/CarCardFordRoller298';
import CarCardHoronOvercab71M from './pages/our-cars/CarCardHoronOvercab71M';
import Faq from './pages/Faq';
import Guides from './pages/Guides';
import GuideWithChildren from './pages/guides/GuideWithChildren';
import GuideFirstTime from './pages/guides/GuideFirstTime';
import GuideSafety from './pages/guides/GuideSafety';
import GuidePacking from './pages/guides/GuidePacking';
import PageRateUs from './pages/PageRateUs';
// import Login from './pages/Login';
// import SocialMediaAsidePanel from './component/SocialMediaAsidePanel/SocialMediaAsidePanel';
import BookingPage from './pages/BookingPage';
import './App.scss';
import './styles/buttons.scss';
import './styles/inputs.scss';
import './styles/utilities.scss';
import './styles/iconsBg.scss';
import './styles/icons.scss';
import ButtonBackToTop from './component/UI/Buttons/ButtonBackToTop';
import PageContactUs from './pages/PageContactUs';
import PageTerms from './pages/PageTerms';
import BottomNavigation from './component/UI/Navbar/BottomNavigation/BottomNavigation';

function App() {
  return (
    <Router>
      <div className='App'>
        <Navbar />
        <ScrollToTop />
        {/* <SocialMediaAsidePanel /> */}
        <div className='main-containers'>
          <Routes>
            <Route path='/' element={<Landing />} />
            <Route path='/o-nas' element={<AboutUs />} />
            <Route path='/kampery' element={<OurCars />} />
            <Route
              path='/kampery/ford-roller-266tl'
              element={<CarCardFordRoller266TL />}
            />
            <Route
              path='/kampery/ford-roller-298'
              element={<CarCardFordRoller298 />}
            />
            <Route
              path='/kampery/horon-overcab-71m'
              element={<CarCardHoronOvercab71M />}
            />
            <Route path='/faq' element={<Faq />}></Route>
            <Route path='/poradniki/' element={<Guides />} />
            <Route
              path='/poradniki/z-dziecmi'
              element={<GuideWithChildren />}
            />
            <Route
              path='/poradniki/pierwszy-raz'
              element={<GuideFirstTime />}
            />
            <Route path='/kontakt' element={<PageContactUs />} />
            <Route path='/poradniki/bezpieczenstwo' element={<GuideSafety />} />
            <Route path='/poradniki/co-spakowac' element={<GuidePacking />} />
            <Route path='/rezerwuj' element={<BookingPage />} />
            <Route path='/ocen-nas' element={<PageRateUs />} />
            <Route path='/regulaminy' element={<PageTerms />} />
          </Routes>
        </div>
        <ButtonBackToTop />
        <BottomNavigation />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
