import roundNumber, { calculateSeasonDays } from '../helpers/helpers';
import { IForm } from '../types/types';
import { RootState } from './store';
import { createSlice } from '@reduxjs/toolkit';

// INITIAL STATE
export const initialState = {
  data: {
    step1: {
      pickupPlaceAdress: 'Prosta 36, 53-508 Wrocław',
      returnPlaceAdress: 'Prosta 36, 53-508 Wrocław',
      pickupDate: '',
      returnDate: '',
      pickupTime: '',
      returnTime: '',
      personAmount: 1,
    },
    step2: {
      name: '',
      price: [],
      selectedVehicleIndex: null,
    },
    step3: {
      addedAccesories: [],
    },
    step4: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      selectedCar: '',
    },
    constData: {
      depositPrice: 5000,
      bookingFee: 350,
    },
    seasonDates: {
      lowSeason: 0,
      mediumSeason: 0,
      highSeason: 0,
      totalDays: 0,
    },
    finalPrice: {
      accesoriesFinalPrice: 0,
      carLowSeasonPrice: 0,
      carMediumSeasonPrice: 0,
      carHighSeasonPrice: 0,
      totalPrice: 0,
    },
  } as IForm,
  isPending: false,
};

// SLICE
export const formSlice = createSlice({
  initialState,
  name: 'form',
  reducers: {
    setStep1FormData: (state, action) => {
      state.data.step1 = action.payload;
    },
    setStep2AccesoriesData: (state, action) => {
      const checkIfExist = state.data.step3.addedAccesories.find(
        (x) => x.accesoriesID === action.payload.accesoriesID
      );
      if (checkIfExist) {
        state.data.step3.addedAccesories = state.data.step3.addedAccesories.map(
          (x) =>
            x.accesoriesID === action.payload.accesoriesID
              ? { ...action.payload }
              : x
        );
      } else {
        state.data.step3.addedAccesories = [
          ...state.data.step3.addedAccesories,
          action.payload,
        ];
      }
    },
    setVehicleName: (state, action) => {
      state.data.step2.name = action.payload;
    },
    setVehiclePrice: (state, action) => {
      state.data.step2.price = action.payload;
    },
    setVehicleIndex: (state, action) => {
      state.data.step2.selectedVehicleIndex = action.payload;
    },
    //step1
    setPickupAdress: (state, action) => {
      state.data.step1.pickupPlaceAdress = action.payload;
    },
    setPickupTime: (state, action) => {
      state.data.step1.pickupTime = action.payload;
    },
    setPickupData: (state, action) => {
      state.data.step1.pickupDate = action.payload;
    },
    setReturnAdress: (state, action) => {
      state.data.step1.returnPlaceAdress = action.payload;
    },
    setReturnTime: (state, action) => {
      state.data.step1.returnTime = action.payload;
    },
    setReturnData: (state, action) => {
      state.data.step1.returnDate = action.payload;
    },
    setPersonAmount: (state, action) => {
      state.data.step1.personAmount = action.payload;
    },
    setFirstName: (state, action) => {
      state.data.step4.firstName = action.payload;
    },
    setLastName: (state, action) => {
      state.data.step4.lastName = action.payload;
    },
    setPhoneNumber: (state, action) => {
      state.data.step4.phoneNumber = action.payload;
    },
    setEmail: (state, action) => {
      state.data.step4.email = action.payload;
    },
    setSeasonDays: (state) => {
      state.data.seasonDates = calculateSeasonDays(
        state.data.step1.pickupDate,
        state.data.step1.returnDate
      );

      // calculate car price in low season
      state.data.finalPrice.carLowSeasonPrice =
        calculateSeasonDays(
          state.data.step1.pickupDate,
          state.data.step1.returnDate
        ).lowSeason * state.data.step2.price[0];

      // calculate car price in medium season
      state.data.finalPrice.carMediumSeasonPrice =
        calculateSeasonDays(
          state.data.step1.pickupDate,
          state.data.step1.returnDate
        ).mediumSeason * state.data.step2.price[1];

      // calculate car price in high season
      state.data.finalPrice.carHighSeasonPrice =
        calculateSeasonDays(
          state.data.step1.pickupDate,
          state.data.step1.returnDate
        ).highSeason * state.data.step2.price[2];
      // first calculate total
      state.data.finalPrice.totalPrice =
        state.data.finalPrice.carLowSeasonPrice +
        state.data.finalPrice.carMediumSeasonPrice +
        state.data.finalPrice.carHighSeasonPrice +
        state.data.constData.bookingFee +
        state.data.constData.depositPrice;
    },

    calculateAccesoriesPrice: (state) => {
      state.data.finalPrice.accesoriesFinalPrice =
        state.data.step3.addedAccesories.reduce(
          (acc, current) => acc + current.finalPrice,
          0
        );

      // final price
      state.data.finalPrice.totalPrice = roundNumber(
        state.data.step3.addedAccesories.reduce(
          (acc, current) => acc + current.finalPrice,
          0
        ) +
          state.data.finalPrice.carLowSeasonPrice +
          state.data.finalPrice.carMediumSeasonPrice +
          state.data.finalPrice.carHighSeasonPrice +
          state.data.constData.bookingFee +
          state.data.constData.depositPrice,
        2
      );
    },
  },
});

// ACTIONS
export const {
  setStep1FormData,
  setStep2AccesoriesData,
  setVehicleName,
  setVehiclePrice,
  setVehicleIndex,
  //step2
  setPickupAdress,
  setPickupTime,
  setPickupData,
  setReturnAdress,
  setReturnData,
  setReturnTime,
  setFirstName,
  setLastName,
  setPhoneNumber,
  setPersonAmount,
  setEmail,
  setSeasonDays,
  calculateAccesoriesPrice,
} = formSlice.actions;

// SELECTORS - do przesyłania wartości do komponentów
export const selectFormStep1Data = (state: RootState) => state.form.data.step1;
export const selectFormStep2Data = (state: RootState) => state.form.data.step2;
export const selectFormStep3Data = (state: RootState) => state.form.data.step3;

export const personAmount = (state: RootState) =>
  state.form.data.step1.personAmount;
export const selectAddedAccesories = (state: RootState) =>
  state.form.data.step3.addedAccesories;
export const selectVehicleIndex = (state: RootState) =>
  state.form.data.step2.selectedVehicleIndex;
//step3
export const selectPickupAdress = (state: RootState) =>
  state.form.data.step1.pickupPlaceAdress;
export const selectPickupDate = (state: RootState) =>
  state.form.data.step1.pickupDate;
export const selectPickupTime = (state: RootState) =>
  state.form.data.step1.pickupTime;
export const selectReturnAdress = (state: RootState) =>
  state.form.data.step1.returnPlaceAdress;
export const selectReturnDate = (state: RootState) =>
  state.form.data.step1.returnDate;
export const selectReturnTime = (state: RootState) =>
  state.form.data.step1.returnTime;
export const selectFirstName = (state: RootState) =>
  state.form.data.step4.firstName;
export const selectLastName = (state: RootState) =>
  state.form.data.step4.lastName;
export const selectPhoneNumber = (state: RootState) =>
  state.form.data.step4.phoneNumber;
export const selectEmail = (state: RootState) => state.form.data.step4.email;
export const selectFormData = (state: RootState) => state.form.data;
export const selectFormFinalPrice = (state: RootState) =>
  state.form.data.finalPrice;

export const totalDays = (state: RootState) =>
  state.form.data.seasonDates.totalDays;
